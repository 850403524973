import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import Loader from "pages/seperate/Loader"

const EditStaff = () => {
  const params = useParams()
  const location = useLocation()
  const [isLoading, setLoading] = useState(false)

  const [emp_id, setEmply] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [role_id, setRoleId] = useState("")
  const [designation, setDesignation] = useState("")
  const [role_name, setRoleName] = useState(location.state.role)
  const [password, setPassword] = useState("")
  const [confirm_password, setConfirmPassword] = useState("")
  const [state, setstate] = useState("")
  const [state_name, setstateName] = useState(location.state.state)
  const [city, setCity] = useState({ label: location.state.city_id })
  const [city_name, setCityName] = useState({ label: location.state.city })
  const [zone, setZone] = useState("")
  const [zone_name, setZoneName] = useState(location.state.zone)
  const [manager, setManager] = useState(location.state.staff_id)
  const [options, setOption] = useState([])
  const [options1, setOption1] = useState([])
  const [options2, setOption2] = useState([])
  const [options3, setOption3] = useState([])
  const selectInputRef1 = useRef()
  const selectInputRef2 = useRef()
  const selectInputRef3 = useRef()
  const navigate = useNavigate()
  const [errors, setErrors] = useState({});


  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `https://api.orthotrack.in/api/staff/${params.id}`
        )
        const allData = data.data.map((item, index) => {
          setEmply(item.emp_id)
          setName(item.name)
          setEmail(item.email)
          setMobile(item.mobile)
          setRoleName(item.role_name)
          // setManager(item.manager_name)
          setZoneName(item.zone_name)
          setZone(item.zone)
          setstateName(item.state_name)
          setCityName(item.city)
          setPassword(item.string_data)
          setstate(item.state)
          setRoleId(item.roleid)
          setConfirmPassword(item.string_data)
          setDesignation(item.designation)
          setCity(item.city_id)

        })
        // console.log(data.data)

        const response = await axios({
          method: "get",
          url: "https://api.orthotrack.in/api/roles",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const options = response.data.data.map(item => ({
            value: item.id,
            label: item.role_name,
          }))

          setOption(options)
        })

        const response1 = await axios({
          method: "get",
          url: "https://api.orthotrack.in/api/zones",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const options = response.data.data.map(item => ({
            value: item.id,
            label: item.zone_name,
          }))

          setOption1(options)
        })

        setLoading(false)
      } catch (err) {
        console.error(err)
      }
    }
    fetch()
  }, [])

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const formData = new FormData()
      formData.append("zone_id", location.state.zone_id)
      try {
        const response = await axios({
          method: "post",
          url: "https://api.orthotrack.in/api/zone_state_staff",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const options2 = response.data.data.users.map(item => ({
            value: item.id,
            label: item.name + " - " + item.role_name,
          }))
          setUsers(options2)
          setLoading(false)
        })
      } catch (err) {
        swal({
          icon: "error",
          text: "SomeThing Went Wrong",
        })
        setLoading(false)
      }
    }
    fetch()
  }, [])

  const [users, setUsers] = useState([])

  const onChangeZone = async e => {
    selectInputRef1.current.select.clearValue()
    selectInputRef2.current.select.clearValue()
    // selectInputRef3.current.select.clearValue()
    if (e != null) {
      setZone(e.value)
    }

    setLoading(true)
    const formData = new FormData()
    formData.append("zone_id", e.value)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/zone_state_staff",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.states.map(item => ({
          value: item.id,
          label: item.state_name,
        }))
        const options2 = response.data.data.users.map(item => ({
          value: item.id,
          label: item.name + " - " + item.role_name,
        }))
        setUsers(options2)
        setOption3(options)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const onChangeState = async e => {
    selectInputRef2.current.select.clearValue()
    if (e != null) {
      setstate(e.value)
    }
    setLoading(true)
    const formData = new FormData()
    formData.append("state_id", e.value)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/state_city",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.district_name,
        }))
        setOption2(options)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }


  const handleValidation = () => {
    const newErrors = {};
    if (!name) {
      newErrors.name = 'Staff Name is required';
    }
    if (!emp_id) {
      newErrors.emp_id = 'Employee id is required';
    }

    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = 'Invalid email format';
    }

    // if (!mobile) {
    //   newErrors.mobile = 'Phone number is required';
    // } else if (mobile.length > 10 || mobile.length < 10) {
    //   newErrors.mobile = 'Phone number must be of 10 digits';
    // }

    if (!role_id) {
      newErrors.role_id = 'Role is required';
    }

    if (!designation) {
      newErrors.designation = 'Designation is required';
    }

    if (!zone) {
      newErrors.zone = 'Zone is required';
    }

    if (!password) {
      newErrors.password = 'Password is required';
    }

    if (!confirm_password) {
      newErrors.confirm_password = 'Confirm Password is required';
    } else if (password !== confirm_password) {
      newErrors.confirm_password = 'Confirm Password not matched!';
    }

    if (!state) {
      newErrors.state = 'State is required';
    }

    if (!city) {
      newErrors.city = 'City is required';
    }

    if (!manager) {
      newErrors.manager = 'Reporting Manager is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const onEditStaff = async e => {
    e.preventDefault()
    if (handleValidation()) {
      if (confirm_password === password) {
        setLoading(true)
        const formData = new FormData()
        formData.append("user_id", params.id)
        formData.append("added_by", 0)
        formData.append("emp_id", emp_id)
        formData.append("name", name)
        formData.append("email", email)
        formData.append("mobile", mobile)
        formData.append("roleid", role_id)
        formData.append("designation", designation)
        formData.append("password", password)
        formData.append("state", state)
        formData.append("city", city)
        formData.append("manager", manager)
        formData.append("zone", zone)
        formData.append("auth_id", localStorage.getItem("authid"))

        try {
          const response = await axios({
            method: "post",
            url: "https://api.orthotrack.in/api/updateStaff",
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            swal({
              icon: "success",
              text: "Staff Edited Successfully",
            }).then(data => {
              navigate("/staff")
            })
            setLoading(false)
          })
        } catch (err) {
          swal({
            icon: "error",
            text: "SomeThing Went Wrong",
          })
          setLoading(false)
        }
      } else {
        swal({
          icon: "error",
          text: "Please Enter Correct Password",
        })
      }
    }
  }



  return (
    <div>
      <React.Fragment>
        {isLoading && <Loader />}
        <div className="page-content">
          <Container fluid className="custom-container">
            <Breadcrumb
              title={"Dashboard"}
              breadcrumbItem={"Edit Staff Form"}
            />
            <div>
              <div>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Staff Details</CardTitle>

                        <form onSubmit={onEditStaff}>
                          <Row>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Employee Id
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Enter Employee Id"
                                  defaultValue={emp_id}
                                  onChange={e => setEmply(e.target.value)}
                                />
                                {errors.emp_id && <span className="text-danger">{errors.emp_id}</span>}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Staff Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Enter Staff Name"
                                  defaultValue={name}
                                  onChange={e => setName(e.target.value)}
                                />
                                {errors.name && <span className="text-danger">{errors.name}</span>}
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Contact Number
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Enter Staff Mobile Number"
                                  defaultValue={mobile}
                                  onChange={e => setMobile(e.target.value)}
                                />
                                {errors.mobile && <span className="text-danger">{errors.mobile}</span>}
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Staff Email
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Enter Staff Email"
                                  value={email}
                                  onChange={e => setEmail(e.target.value)}
                                />
                                {errors.email && <span className="text-danger">{errors.email}</span>}
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">Roles</Label>
                                <Select
                                  options={options}
                                  className="select2-selection"
                                  defaultValue={{ label: role_name }}
                                  onChange={e => setRoleId(e.value)}
                                />
                                {errors.role_id && <span className="text-danger">{errors.role_id}</span>}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Designation
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Enter Designation"
                                  defaultValue={designation}
                                  onChange={e => setDesignation(e.target.value)}
                                />
                                {errors.designation && <span className="text-danger">{errors.designation}</span>}
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3 ">
                                <Label htmlFor="formrow-firstname-Input">
                                  Zone
                                </Label>
                                <Select
                                  options={options1}
                                  className="select2-selection"
                                  defaultValue={{ label: zone_name }}
                                  onChange={onChangeZone}
                                />
                                {errors.zone && <span className="text-danger">{errors.zone}</span>}
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3 ">
                                <Label htmlFor="formrow-firstname-Input">
                                  State
                                </Label>
                                <Select
                                  options={options3}
                                  defaultValue={{ label: state_name }}
                                  className="select2-selection"
                                  onChange={onChangeState}
                                  ref={selectInputRef1}
                                />
                                {errors.state && <span className="text-danger">{errors.state}</span>}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3 ">
                                <Label htmlFor="formrow-firstname-Input">
                                  City
                                </Label>
                                <Select
                                  options={options2}
                                  defaultValue={city_name}
                                  className="select2-selection"
                                  ref={selectInputRef2}
                                  onChange={e => {
                                    if (e != null) {
                                      setCity(e.value)
                                    }
                                  }}
                                />
                                {errors.city && <span className="text-danger">{errors.city}</span>}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Reporting Manager
                                </Label>
                                <Select
                                  options={users}
                                  className="select2-selection"
                                  defaultValue={{ label: location.state.staff_name }}
                                  // ref={selectInputRef3}
                                  onChange={e => {
                                    if (e != null) {
                                      setManager(e.value)
                                    }
                                  }}
                                />
                                {errors.manager && <span className="text-danger">{errors.manager}</span>}
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3 ">
                                <Label htmlFor="formrow-firstname-Input">
                                  Enter Password
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Enter Password"
                                  defaultValue={password}
                                  onChange={e => setPassword(e.target.value)}
                                />
                                {errors.password && <span className="text-danger">{errors.password}</span>}
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3 ">
                                <Label htmlFor="formrow-firstname-Input">
                                  Confirm Password
                                </Label>
                                <Input
                                  type="password"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Enter Confirm Password"
                                  defaultValue={password}
                                  onChange={e =>
                                    setConfirmPassword(e.target.value)
                                  }
                                />
                                {errors.confirm_password && <span className="text-danger">{errors.confirm_password}</span>}
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-50">
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default EditStaff
