import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useMemo, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Table,
  Modal,
  Label,
  Input,
} from "reactstrap"
import GlobalFilter from "pages/seperate/GlobalFilter"
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import Loader from "pages/seperate/Loader"
import moment from "moment"

const index = () => {
  const [editBrands, setEditBrands] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [track_id, setTrackId] = useState("")
  const [data, setdatas] = useState([])
  const [onShowProduct, setProductShow] = useState(false)

  const [all_data, setAllData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("authid"))
    formData.append("role_id", localStorage.getItem("authrole_id"))
    formData.append("records", localStorage.getItem("record"))

    try {
      const response = axios({
        method: "post",
        url: "https://api.orthotrack.in/api/surgery_filter",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setdatas(response.data.data)
        // console.log(response.data.data)
        setAllData(response.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  const [product_detail, setProductDetails] = useState([])

  const [sugery_id, setSurgeryId] = useState("")
  const [product_type, setProductType] = useState("")
  const [allProducts, setAllPoducts] = useState([])

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
      },
      {
        Header: "Surgery Date",
        accessor: "surgery_date",
        filterable: true,
        className: "bd-show-extra-field ",
        Cell: cellProps => {
          const date = moment(cellProps.row.original.surgery_date).format(
            "DD-MM-YYYY"
          )
          return <div>{date}</div>
        },
      },
      {
        Header: "Zone",
        accessor: "zone_name",
        filterable: true,
        className: "bd-show-extra-field Nav-dropdown-resp",
      },
      {
        Header: "State",
        accessor: "state_name",
        filterable: true,
        className: "bd-show-extra-field Nav-dropdown-resp",
      },
      {
        Header: "City ",
        accessor: "city_name",
        filterable: true,
        className: "Nav-dropdown-resp",
      },
      {
        Header: "Business Type",
        accessor: "btype",
        filterable: true,
        className: "bd-show-extra-field",
      },
      {
        Header: "Channel Partner",
        accessor: "channel_name",
        filterable: true,
        className: "bd-show-extra-field",
      },
      {
        Header: "Surgeon",
        accessor: "doctor_name",
        filterable: true,
      },
      {
        Header: "Hospital ",
        accessor: "hospital_name",
        filterable: true,
      },
      {
        Header: "Patient",
        accessor: "patient",
        filterable: true,
      },
      {
        Header: "CSR/ASC/TL",
        accessor: "csr_name",
        filterable: true,
        className: "bd-show-extra-field ",
      },
      {
        Header: "Product Type",
        accessor: "ptype",
        filterable: true,
        className: "bd-show-extra-field Nav-dropdown-resp",
      },
      {
        Header: "Action",
        filterable: true,
        className: "border-botttom-black",
        Cell: cellProps => {
          const clickCallback = id => {
            navigate(`/edit-surgery-track/${cellProps.row.values.id}`, {
              state: {
                zone: cellProps.row.values.zone_name,
                zone_ids: cellProps.row.original.zone,
                csr_name: cellProps.row.original.csr_name,
                state: cellProps.row.values.state_name,
                state_ids: cellProps.row.original.state,
                city: cellProps.row.values.city_name,
                city_ids: cellProps.row.original.city,
                type: cellProps.row.original.btype,
                partner: cellProps.row.original.channel_name,
                surgery: cellProps.row.original.doctor_name,
                hospital: cellProps.row.original.hospital_name,
                ptype: cellProps.row.original.ptype,
                ptypeId: cellProps.row.original.product_type,
              },
            })
          }

          const [roles, setRoles] = useState([])
          useEffect(() => {
            const id = localStorage.getItem("authrole_id")
            const fetch = async () => {
              // setLoading(true)
              try {
                const { data } = await axios.get(
                  `https://api.orthotrack.in/api/roles/${id}`
                )
                setRoles(data.data.permission)
                // setLoading(false)
              } catch (err) {
                console.error(err)
                // setLoading(false)
              }
            }
            fetch()
          }, [])

          const authID = localStorage.getItem("authrole_id")
          const nextDate = new Date(
            new Date(cellProps.row.original.created_at).getTime() +
            24 * 60 * 60 * 1000
          )
          const currentDate = new Date()
          let Edit
          let Delete
          const allRoles = roles.map(item => {
            if (item.module_id == 1 && item.edit == 1) {
              if (authID == 5 || authID == 4) {
                if (nextDate >= currentDate) {
                  Edit = (
                    <div className="col-3 bd-edit-resposnive-btn">
                      <a
                        // to="/edit-surgery-track"
                        className="btn btn-outline-secondary btn-sm edit"
                        onClick={clickCallback}
                        title="Edit"
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </a>
                    </div>
                  )
                }
              } else {
                Edit = (
                  <div className="col-3 bd-edit-resposnive-btn">
                    <a
                      // to="/edit-surgery-track"
                      className="btn btn-outline-secondary btn-sm edit"
                      onClick={clickCallback}
                      title="Edit"
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </a>
                  </div>
                )
              }
            }

            if (item.module_id == 1 && item.destroy == 1) {
              if (authID == 5 || authID == 4) {
                if (nextDate >= currentDate) {
                  Delete = (
                    <div className="col-3">
                      <a
                        className="btn btn-danger btn-sm edit"
                        onClick={async e => {
                          swal({
                            title: "Are you sure?",
                            text: "You want to Delete this Surgery Track !",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then(async willDelete => {
                            if (willDelete) {
                              setLoading(true)
                              e.preventDefault()
                              const formData = new FormData()
                              formData.append(
                                "surgery_id",
                                cellProps.row.values.id
                              )
                              formData.append(
                                "user_id",
                                localStorage.getItem("authid")
                              )
                              formData.append(
                                "role_id",
                                localStorage.getItem("authrole_id")
                              )
                              try {
                                const response = await axios({
                                  method: "post",
                                  url: "https://api.orthotrack.in/api/deleteSurgery",
                                  data: formData,
                                  headers: {
                                    Accept: "application/json",
                                    "Content-Type": "multipart/form-data",
                                  },
                                }).then(response => {
                                  setLoading(false)
                                  swal({
                                    icon: "success",
                                    text: "Surgery Deleted Successfully",
                                  }).then(data => {
                                    setdatas(response.data.data)
                                  })
                                })
                              } catch (err) {
                                swal({
                                  icon: "error",
                                  text: "SomeThing Went Wrong",
                                })
                                setLoading(false)
                              }
                            }
                          })
                        }}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </a>
                    </div>
                  )
                }
              } else {
                Delete = (
                  <div className="col-3">
                    <a
                      className="btn btn-danger btn-sm edit"
                      onClick={async e => {
                        swal({
                          title: "Are you sure?",
                          text: "You want to Delete this Surgery Track !",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async willDelete => {
                          if (willDelete) {
                            setLoading(true)
                            e.preventDefault()
                            const formData = new FormData()
                            formData.append(
                              "surgery_id",
                              cellProps.row.values.id
                            )
                            formData.append(
                              "user_id",
                              localStorage.getItem("authid")
                            )
                            formData.append(
                              "role_id",
                              localStorage.getItem("authrole_id")
                            )
                            try {
                              const response = await axios({
                                method: "post",
                                url: "https://api.orthotrack.in/api/deleteSurgery",
                                data: formData,
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "multipart/form-data",
                                },
                              }).then(response => {
                                setLoading(false)
                                swal({
                                  icon: "success",
                                  text: "Surgery Deleted Successfully",
                                }).then(data => {
                                  setdatas(response.data.data)
                                })
                              })
                            } catch (err) {
                              setLoading(false)
                              swal({
                                icon: "error",
                                text: "SomeThing Went Wrong",
                              })
                            }
                          }
                        })
                      }}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </a>
                  </div>
                )
              }
            }
          })

          const onViewProduts = async () => {
            setSurgeryId(cellProps.row.original.id)
            setProductType(cellProps.row.original.ptype)
            setLoading(true)
            try {
              const response = await axios({
                method: "get",
                url: `https://api.orthotrack.in/api/surgery_details/${cellProps.row.values.id}`,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(response => {
                setAllPoducts(response.data)
                setProductShow(true)
                setLoading(false)
              })
            } catch (err) {
              setLoading(false)
              swal({
                icon: "error",
                text: "SomeThing Went Wrong",
              })
            }
          }

          return (
            <>
              <div className="d-flex ">
                {Edit}
                {Delete}
                <div
                  className="col-3 ml-10  bd-edit-resposnive-btn"
                  style={{ paddingLeft: "0px" }}
                >
                  <a
                    className="btn btn-outline-secondary btn-sm edit"
                    title="Edit"
                    onClick={onViewProduts}
                  >
                    <i className="fas fa-eye"></i>
                  </a>
                </div>

                <div className="col-2 bd-responsive-filter2">
                  <a
                    className="btn btn-outline-secondary btn-sm edit"
                    onClick={async e => {
                      setEditBrands(true)
                      setTrackId(cellProps.row.values.id)
                      setLoading(true)
                      try {
                        const { data } = await axios.get(
                          `https://api.orthotrack.in/api/surgery/${cellProps.row.values.id}`
                        )
                        setLoading(false)
                        setProductDetails(data.data.product_details)
                      } catch (err) {
                        console.log(err)
                        setLoading(false)
                      }
                    }}
                  >
                    <i className="fas fa-eye"></i>
                  </a>
                </div>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const TableView = allProducts.map((item, index) => {
    return (
      <tr key={index}>
        <td>{item.brand_name}</td>
        <td>{item.component_name}</td>
        <td>{item.size_name}</td>
      </tr>
    )
  })

  const params = localStorage.getItem("authid")

  const [zone_name, setZoneName] = useState("")
  const [zone_id, setZoneId] = useState("")
  useEffect(() => {
    const fetch = async () => {
      // setLoading(true)
      try {
        const { data } = await axios.get(
          `https://api.orthotrack.in/api/staff/${params}`
        )
        // setLoading(false)
        const allData = data.data.map(item => {
          setZoneName(item.zone_name)
          setZoneId(item.zone)
        })
      } catch (err) {
        console.log(err)
        // setLoading(false)
      }
    }
    fetch()
  }, [])

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const [open, setOpen] = useState(false)

  const toggleLeftCanvas = () => {
    setOpen(!open)
  }

  const [roles, setRoles] = useState([])
  useEffect(() => {
    const id = localStorage.getItem("authrole_id")
    const fetch = async () => {
      // setLoading(true)
      try {
        const { data } = await axios.get(
          `https://api.orthotrack.in/api/roles/${id}`
        )
        setRoles(data.data.permission)

        // setLoading(false)
      } catch (err) {
        console.error(err)
        // setLoading(false)
      }
    }
    fetch()
  }, [])

  let Add
  const allRole = roles.map(item => {
    if (item.module_id == 1 && item.inserts == 1) {
      const clickCallback2 = id => {
        navigate("/add-surgery-track")
      }

      Add = (
        <Col md="6" className="text-right  full-width">
          {/* <L to={"/add-surgery-track"}> */}
          <Button onClick={clickCallback2} className="btn-theme-color">
            + Add Surgery Track
          </Button>
          {/* </L> */}
        </Col>
      )
    }
  })

  const [from_date, setFromDate] = useState("")
  const [to_date, setToDate] = useState("")

  // const onFilterData = async e => {
  //   setLoading(true)
  //   const formData = new FormData()
  //   formData.append("user_id", localStorage.getItem("authid"))
  //   formData.append("role_id", localStorage.getItem("authrole_id"))
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: "https://api.orthotrack.in/api/surgery",
  //       data: formData,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }).then(response => {
  //       setOpen(false)
  //       setLoading(false)
  //       const filters = {
  //         from: from_date,
  //         to: to_date,
  //       }
  //       const fromDate = new Date(filters.from)
  //       const toDate = filters.to ? new Date(filters.to) : undefined

  //       const filteredArray = response.data.data.filter(item => {
  //         const surgeryDate = new Date(item.surgery_date)

  //         // Check if the filter criteria are defined and match
  //         const dateMatch =
  //           (!fromDate || surgeryDate >= fromDate) &&
  //           (!toDate || surgeryDate <= toDate)
  //         // Return true if all criteria match
  //         return dateMatch
  //       })
  //       setdatas(filteredArray)

  //       setLoading(false)
  //     })
  //   } catch (error) {
  //     console.log(error)
  //     setLoading(false)
  //   }
  // }

  const onFilterData = async e => {
    if (from_date != "" && to_date != "") {
      setLoading(true)
      const formData = new FormData()
      formData.append("from", from_date)
      formData.append("to", to_date)
      formData.append("zone", "")
      formData.append("state", "")
      formData.append("csr", "")
      formData.append("product_type", "")
      formData.append("records", localStorage.getItem("record"))
      formData.append("user_id", localStorage.getItem("authid"))
      try {
        const response = await axios({
          method: "post",
          url: "https://api.orthotrack.in/api/surgery_filter_data",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          setdatas(response.data.data)
          setLoading(false)
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      swal("", "From & To Date are mandatory Fields!", "warning")
    }
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid className="custom-container">
          <Breadcrumb title={"Dashboard"} breadcrumbItem={"Surgery Track"} />
          <div>
            <div>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="bd-responsive-filter">
                        <div className="d-flex justify-space-between ">
                          <div>
                            <CardTitle className="h4 text-black ">
                              Surgery Tracking
                            </CardTitle>
                            <p className="card-title-desc">
                              Listing View of Surgery Tracking
                            </p>
                          </div>
                          {Add}
                        </div>
                        <div className="">
                          <Row className="mb-2 align-item-center justify-space-between">
                            {/* <Col md="2">
                              <select
                                className="form-select"
                                value={pageSize}
                                onChange={onChangeInSelect}
                              >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                  <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                  </option>
                                ))}
                              </select>
                            </Col> */}
                            <GlobalFilter
                              filter={globalFilter}
                              setFilter={setGlobalFilter}
                            />
                            <div className="col-8">
                              <div className="mb-30 mt-20">
                                <div className="align-items-end justify-content-right d-flex">
                                  <div className="mr-15">
                                    <Label htmlFor="formrow-firstname-Input">
                                      From
                                    </Label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      required
                                      value={from_date}
                                      onChange={e =>
                                        setFromDate(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="mr-15">
                                    <Label htmlFor="formrow-firstname-Input">
                                      To
                                    </Label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      value={to_date}
                                      onChange={e => setToDate(e.target.value)}
                                    />
                                  </div>
                                  <div className=" text-right  full-width mr-15">
                                    <Button
                                      className="btn-theme-color "
                                      onClick={onFilterData}
                                    >
                                      <i
                                        className="fas fa-search pr-10"
                                        style={{ fontSize: "10px" }}
                                      ></i>
                                      Search
                                    </Button>
                                  </div>
                                  <div className=" text-right  full-width">
                                    <Button
                                      className="btn-theme-color "
                                      onClick={() => {
                                        setFromDate("")
                                        setToDate("")
                                        setdatas(all_data)
                                      }}
                                    >
                                      <i
                                        className="dripicons-cross pr-10"
                                        style={{ fontSize: "10px" }}
                                      ></i>
                                      Clear
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </div>

                      {/* Responsive Top section */}

                      <div className="bd-responsive-filter2">
                        <div>
                          <div className="display-flex align-item-center">
                            {Add}
                            <div className="col-2 text-right">
                              <a
                                className="btn btn-outline-secondary bd-custom-sidebar"
                                onClick={toggleLeftCanvas}
                              >
                                <i className="fas fa-filter"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        {open && (
                          <div className="filter-sidebar" id="mySidenav">
                            <div className=" display-flex">
                              <div className="col-9">Apply Filter</div>
                              <div className="col-3 text-right">
                                <a className="" onClick={toggleLeftCanvas}>
                                  <i className="fas fa-times"></i>
                                </a>
                              </div>
                            </div>
                            <div className="pt-20">
                              <Row className="mb-2 align-item-center">
                                <Col md="2">
                                  <select
                                    className="form-select"
                                    value={pageSize}
                                    onChange={onChangeInSelect}
                                  >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                      <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                      </option>
                                    ))}
                                  </select>
                                </Col>
                                <GlobalFilter
                                  filter={globalFilter}
                                  setFilter={setGlobalFilter}
                                />
                              </Row>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="">
                        <div
                          className="table-responsive react-table"
                          style={{ overflowX: "hidden" }}
                        >
                          <Table
                            {...getTableProps()}
                            className="table-striped responsive-main-table"
                          >
                            <thead className="">
                              {headerGroups.map(headerGroup => (
                                <tr
                                  key={headerGroup.id}
                                  {...headerGroup.getHeaderGroupProps()}
                                >
                                  {headerGroup.headers.map(column => (
                                    <th
                                      key={column.id}
                                      className={column.className}
                                    >
                                      <div {...column.getSortByToggleProps()}>
                                        {column.render("Header")}
                                        {generateSortingIndicator(column)}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                              {page.map(row => {
                                prepareRow(row)
                                return (
                                  <tr key={row.id} {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                      <td
                                        key={cell.id}
                                        {...cell.getCellProps()}
                                        data-column={cell.column.Header}
                                        className={cell.column.className}
                                      >
                                        {cell.render("Cell")}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                          {/* </div> */}
                        </div>
                        <div>
                          <Row className="justify-content-md-end justify-content-center align-items-center">
                            <Col className="col-md-auto">
                              <div className="d-flex gap-1">
                                <Button
                                  color="primary"
                                  onClick={() => gotoPage(0)}
                                  disabled={!canPreviousPage}
                                >
                                  {"<<"}
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={previousPage}
                                  disabled={!canPreviousPage}
                                >
                                  {"<"}
                                </Button>
                              </div>
                            </Col>
                            <Col className="col-md-auto d-md-block">
                              <span className="d-mobile-none"> Page </span>
                              <strong>
                                {pageIndex + 1} of {pageOptions.length}
                              </strong>
                            </Col>

                            <Col className="col-md-auto">
                              <div className="d-flex gap-1">
                                <Button
                                  color="primary"
                                  onClick={nextPage}
                                  disabled={!canNextPage}
                                >
                                  {">"}
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => gotoPage(pageCount - 1)}
                                  disabled={!canNextPage}
                                >
                                  {">>"}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>

        <Modal isOpen={editBrands} size="sm" centered>
          <div className="modal-header">
            <h5 className="modal-title mt-0">View Surgery Track</h5>
            <a
              type="button"
              onClick={() => {
                setEditBrands(false)
              }}
              className="btn-danger "
              data-dismiss="modal"
              aria-label="Close"
              style={{ fontSize: "25px", color: "#f46a6a" }}
            >
              &times;
            </a>
          </div>
          <div className="modal-body view-modal-height">
            <div>
              <div>
                <div className="">
                  <h5 style={{ paddingLeft: "0px", color: "black" }}>
                    Surgery Details
                  </h5>
                </div>
                <div className="table-responsive react-table">
                  <Table
                    {...getTableProps()}
                    className="table-striped responsive-main-table"
                  >
                    <thead className="">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id}>
                              <div {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {page.map(row => {
                        if (row.original.id == track_id) {
                          return (
                            <tr key={row.id} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                if (cell.value != undefined) {
                                  return (
                                    <td
                                      key={cell.id}
                                      {...cell.getCellProps()}
                                      data-column={cell.column.Header}
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  )
                                }
                              })}
                            </tr>
                          )
                          // }
                        }
                      })}
                      <div className="mt-20 mb-20">
                        <h5 style={{ paddingLeft: "0px", color: "black" }}>
                          Product Details
                        </h5>
                      </div>
                      {product_detail.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className={
                              product_detail.length - 1 == index
                                ? ""
                                : "border-bottom-color"
                            }
                          >
                            <td data-column="Brand">{item.brand_name}</td>
                            <td data-column="Component">
                              {" "}
                              {item.component_name}
                            </td>
                            <td data-column="Size">{item.size_name}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={onShowProduct}
          size="sm"
          className="bd-show-product"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Product Details</h5>
            <button
              type="button"
              onClick={() => {
                setProductShow(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <div className="mb-3 col-md-4">
                <Label htmlFor="formrow-firstname-Input">Surgeon ID</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Name"
                  value={sugery_id}
                  disabled
                />
              </div>
              <div className="mb-3 col-md-4">
                <Label htmlFor="formrow-firstname-Input">Product Type</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Name"
                  value={product_type}
                  disabled
                />
              </div>
            </Row>

            <div className="mt-20">
              <div className="mb-15">
                <h5>Added Product Details</h5>
              </div>
              <div className="table-responsive fixed-table-header">
                <table className="table mb-0 table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th style={{ width: "17%" }}>Product Name</th>
                      <th>Component</th>
                      <th>Size</th>
                    </tr>
                  </thead>
                  <tbody>{TableView}</tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default index
