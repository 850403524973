import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import avatar1 from "../../assets/images/users/user-icon-png.jpeg"
import profileImg from "../../assets/images/profile-img.png"
import axios from "axios"

const WelcomeComp = () => {

  const [isLoading, setLoading] = useState(false)
  const [City, setCityName] = useState(false)
  const [state, setstate] = useState(false)
  const [role_name, setRole] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `https://api.orthotrack.in/api/staff/${localStorage.getItem("authid")}`
        )

        const allData = data.data.map((item, index) => {
          setCityName(item.city)
          setstate(item.state_name)
          setRole(item.role_name)
        })


        setLoading(false)
      } catch (err) {
        console.error(err)
      }
    }
    fetch()
  }, [])

  const nameLogo = localStorage.getItem("authName").split(" ")

  const firstLetter = nameLogo.toString().slice(0, 1)

  let secondLetter;
  if (nameLogo.length > 1) {
    secondLetter = nameLogo.slice(-1).toString().slice(0, 1)
  }



  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="8">
              <div className="text-primary p-3" >
                <h5 className="text-primary">Welcome Back !</h5>
                <p >Surgery Management </p>
              </div>
            </Col>
            <Col xs="4" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0"
         style={{ paddingBottom: "0px" }}
         >
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-15">
                <span className="bd-text-logo">{firstLetter}{secondLetter}</span>
              </div>
            </Col>

            <Col sm="8"
            // style={{ marginTop: "-15px" }}
            >
              <div className="mb-20 mt-10">
                <Row>
                  <h5 className="font-size-15 text-truncate">{localStorage.getItem("authName")} - <span className="text-muted mb-0 text-truncate" style={{ fontSize: "12px" }}>{role_name}</span></h5>
                  <p className="text-muted mb-0 text-truncate">{state}, {City}</p>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
