import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import axios from "axios"
import Loader from "pages/seperate/Loader"

const EditRoles = () => {
  const params = useParams()
  const [isLoading, setLoading] = useState(false)
  const [roles, setAllRoles] = useState([])
  const [role_name, setRoleName] = useState("")
  const [record, setRecord] = useState("")

  const navigate = useNavigate()
  const onEditRoles = async e => {
    e.preventDefault()
    var x1 = document.querySelectorAll("#one")
    var x2 = document.querySelectorAll("#second")
    var x3 = document.querySelectorAll("#third")
    var x4 = document.querySelectorAll("#fourth")
    var role = document.querySelectorAll("#roleId")

    var id = []
    for (var i = 0; i < role.length; i++) {
      id.push(role[i].value)
    }

    var one = 0
    for (var i = 0; i < x1.length; i++) {
      if (x1[i].checked == true) {
        one = one + "1"
      } else {
        one = one + "0"
      }
    }
    var second = 0
    for (var i = 0; i < x2.length; i++) {
      if (x2[i].checked == true) {
        second = second + "1"
      } else {
        second = second + "0"
      }
    }

    var third = 0
    for (var i = 0; i < x3.length; i++) {
      if (x3[i].checked == true) {
        third = third + "1"
      } else {
        third = third + "0"
      }
    }

    var fourth = 0
    for (var i = 0; i < x4.length; i++) {
      if (x4[i].checked == true) {
        fourth = fourth + "1"
      } else {
        fourth = fourth + "0"
      }
    }

    var view = one.substring(1).split("").map(Number)
    var add = second.substring(1).split("").map(Number)
    var edit = third.substring(1).split("").map(Number)
    var delte = fourth.substring(1).split("").map(Number)

    add.push(0)
    edit.push(0)
    delte.push(0)

    setLoading(true)
    const module_id = [1, 2, 3, 4, 5, 6, 7]
    const formData = new FormData()
    formData.append("role_name", role_name)
    formData.append("role_id", params.id)
    formData.append("module_id", module_id)
    formData.append("records", record)
    formData.append("view", view)
    formData.append("add", add)
    formData.append("edit", edit)
    formData.append("delete", delte)
    formData.append("id", id)
    formData.append("user_id", localStorage.getItem("authid"))

    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/updateRoles",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Role Edited Successfully",
        }).then(data => {
          navigate("/roles-and-permissions")
        })
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const rolesNames = [
    {
      name: "Surgery Track",
      module_id: 1,
      allModules: true
    },
    {
      name: "Master",
      module_id: 2,
      allModules: true
    },
    {
      name: "Staff",
      module_id: 3,
      allModules: true
    },
    {
      name: "Report",
      module_id: 4,
      allModules: true
    },
    {
      name: "Roles and Permissions",
      module_id: 5,
      allModules: true
    },
    {
      name: "Targets",
      module_id: 6,
      allModules: true
    },
    {
      name: "Activity Logs",
      module_id: 7,
      allModules: false,
    },
  ]

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `https://api.orthotrack.in/api/roles/${params.id}`
        )

        // console.log("data", data)

        console.log(rolesNames)

        setAllRoles(
          rolesNames.map((role, i) => {

            const module = data.data.permission.filter((mod) => mod.module_id == role.module_id)
            let moduleData;

            if (module.length == 0) {
              moduleData = {
                destroy: 0,
                edit: 0,
                id: 10,
                inserts: 0,
                module_id: role.module_id,
                roleid: 2,
                view: 0
              }
            } else {
              moduleData = module[0]
            }

            return ({
              ...role,
              ...moduleData
            })
          })
        )

        const RoleName = data.data.roles.map(item => {
          setRoleName(item.role_name)
          setRecord(item.records)
        })
        setLoading(false)
      } catch (err) {
        console.error(err)
        setLoading(false)
      }
    }
    fetch()
  }, [])

  return (
    <div>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid className="custom-container">
          <Breadcrumb title={"Dashboard"} breadcrumbItem={"Staff Form"} />
          <div>
            <div>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 pl-15">
                        Edit Roles and Permissions
                      </CardTitle>
                      <form onSubmit={onEditRoles}>
                        <Row className="">
                          <Col lg={4} className="pl-25">
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Add Roles
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"

                                placeholder="Enter Role Name"
                                defaultValue={role_name}
                                onChange={e => setRoleName(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={4} className="pl-25">
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Display Records
                              </Label>
                              <select
                                className="form-select"
                                required
                                onChange={e => setRecord(e.target.value)}
                                value={record}
                              >
                                <option value="">-- Please Select --</option>
                                <option value="1"> All Records</option>
                                <option value="2"> Records Added by Me and Staff - ZSM </option>
                                <option value="3"> Records Added by Me and Staff -RSM </option>
                                <option value="4"> Records Added by Me and Staff - ASM </option>
                                <option value="5"> Records Added by Me</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <thead>
                              <tr>
                                <th>Modules</th>
                                <th>View</th>
                                <th>Add </th>
                                <th> Edit </th>
                                <th> Delete </th>
                              </tr>
                            </thead>
                            <tbody>
                              {roles.map((item, index) => {

                                return (
                                  <tr key={index}>
                                    <th scope="row" key={index}>
                                      <input
                                        id="roleId"
                                        value={item.id}
                                        className="display-none"
                                      />
                                      {item.name}
                                    </th>

                                    <td>
                                      <div className="form-check form-switch form-switch-md">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="one"
                                          defaultChecked={item.view == 1}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      {
                                        item.allModules ?
                                          <div className="form-check form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="second"
                                              defaultChecked={item.inserts == 1}
                                            />
                                          </div> : <span className="ps-2">--</span>
                                      }
                                    </td>
                                    <td>
                                      {
                                        item.allModules ?
                                          <div className="form-check form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="third"
                                              defaultChecked={item.edit == 1}
                                            />
                                          </div> : <span className="ps-2">--</span>
                                      }
                                    </td>
                                    <td>
                                      {
                                        item.allModules ?
                                          <div className="form-check form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="fourth"
                                              defaultChecked={item.destroy == 1}
                                            />
                                          </div> : <span className="ps-2">--</span>
                                      }
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                        <div className="mt-50">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default EditRoles
