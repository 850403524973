import axios from "axios"
import Loader from "pages/seperate/Loader"
import React, { useEffect, useMemo, useState } from "react"
import ReactApexChart from "react-apexcharts"
import Select from "react-select"
import { Button, Card, CardBody } from "reactstrap"
import swal from "sweetalert"

const SurgeryDoneChart = ({ zone_options }) => {
  const [isLoading, setLoading] = useState(false)

  const [optionState, setOptionState] = useState([])

  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")
  const [zones, setZones] = useState([])
  const [states, setStates] = useState([])

  const [topTenSurgeons, setTopTenSurgeons] = useState([])

  const [product_option, setOptionProduct] = useState([])

  useEffect(() => {
    fetch("https://api.orthotrack.in/api/productType")
      .then(res => res.json())
      .then(data => {
        const options = data.data.map(item => (item.product_type))
        // console.log(options)
        setOptionProduct(options)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const onZoneChange = async e => {
    if (e === null) {
      setOptionState([])
      setZones([])
      return
    } else {
      setZones(e)
    }

    const formData = new FormData()
    let zones_ids;
    if (e?.some(z => z.value == 0))
      zones_ids = zone_options?.map(z => z.value).toString()
    else
      zones_ids = e?.slice(-1)[0].value

    // if (e?.length != 0) {
    formData.append("zone_id", zones_ids)
    // }
    try {
      const response = await axios.post(
        "https://api.orthotrack.in/api/zone_state",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      const options = response.data.data.map(item => ({
        value: item.id,
        label: item.state_name,
      }))
      if (e.length != 0) {
        setOptionState([{ label: 'All States', value: 0 }, ...optionState.slice(1), ...options])
      }
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
    }
  }

  const getChartData = async e => {
    e.preventDefault()

    if (!zones || zones?.length === 0) {
      swal("", "Zones Field is Required!", "warning")
      return
    }
    if (!states || states?.length === 0) {
      swal("", "States Field is Required!", "warning")
      return
    }
    try {
      setLoading(true)
      const formData = new FormData()

      formData.append("start_date", start_date)
      formData.append("end_date", end_date)

      let zones_ids;
      if (zones?.some(z => z.value == 0))
        zones_ids = zone_options?.map(z => z.value).toString()
      else
        zones_ids = zones?.map(z => z.value).toString()

      formData.append("zones", zones_ids)

      let all_states_id;
      if (states?.some(z => z.value == 0)) {
        all_states_id = optionState.map(s => s.value).slice(1).toString()
      } else {
        all_states_id = states.map(s => s.value).toString()
      }

      formData.append("states", all_states_id)

      const response = await axios.post(
        "https://api.orthotrack.in/api/surgeons_filter", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
      )
      // console.log("top surgeon", response.data)
      if (response.data.surgeons_data?.length > 0)
        setTopTenSurgeons(response.data.surgeons_data)
      else
        swal("", "No Data Available !", "warning")

      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
    }
  }


  const surgery_data = useMemo(
    () => ({
      series: [
        {
          name: product_option[0],
          data: topTenSurgeons.map(item => item.knee),
        },
        {
          name: product_option[1],
          data: topTenSurgeons.map(item => item.hip),
        },
        {
          name: product_option[2],
          data: topTenSurgeons.map(item => item.arthroscopy),
        },
        {
          name: product_option[3],
          data: topTenSurgeons.map(item => item.bipolar),
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "50%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: topTenSurgeons.map(item => item.name),
        },
        yaxis: {
          title: {
            text: "Surgeries",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " Surgeries"
            },
          },
        },
      },
    }
    ),
    [topTenSurgeons]
  )

  return (
    <div>
      {isLoading && <Loader />}
      <Card>
        <CardBody>
          <div>
            <form onSubmit={getChartData}>
              <div className="d-flex justify-space-between">
                <h4 className="card-title mb-5">
                  Surgeries Conducted
                </h4>
                <div className="text-end mb-3">
                  <Button type="submit" className="btn-theme-color">
                    <i
                      className="fas fa-filter pr-10"
                      style={{ fontSize: "10px" }}
                    ></i>
                    Filter Now
                  </Button>

                  <Button
                    className="btn-theme-color bg-danger ms-3 text-white"
                    type="button"
                    onClick={() => {
                      setTopTenSurgeons([])
                      setStartDate("")
                      setEndDate("")
                      setStates([])
                      setZones([])
                      setOptionState([])
                    }}
                  >
                    <i
                      className="fas fa-times pr-10"
                      style={{ fontSize: "10px" }}
                    ></i>
                    Clear
                  </Button>
                </div>
              </div>
              <div>
                <div className="row mb-4">
                  <div className="col-md-2">
                    <label htmlFor="">From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={start_date}
                      onChange={e => setStartDate(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="">To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={end_date}
                      onChange={e => setEndDate(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="">Zone</label>
                    <Select
                      placeholder="Select Zone"
                      isMulti
                      options={[{ label: 'All Zones', value: 0 }, ...zone_options]}
                      value={zones}
                      onChange={onZoneChange}
                    />
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="">State</label>
                    <Select
                      placeholder="Select State"
                      isMulti
                      options={optionState}
                      value={states}
                      onChange={e => setStates(e)}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </CardBody>
      </Card>
      {topTenSurgeons.length > 0 && (
        <>
          <Card>
            <CardBody>
              <div id="chart">
                <h5 className="fw-bold text-center">Surgeries Conducted</h5>
                <ReactApexChart
                  options={surgery_data?.options}
                  series={surgery_data?.series}
                  type="bar"
                  height={350}
                />
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  )
}

export default SurgeryDoneChart
