import Breadcrumb from "components/Common/Breadcrumb"
import ProductTable from "pages/Master/ProductTable"
import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Table,
} from "reactstrap"

const index = () => {
  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid className="custom-container">
            <Breadcrumb title={"Dashboard"} breadcrumbItem={"Products"} />
            <div>
              <div>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div>
                          <ProductTable />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default index
