import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  Label,
} from "reactstrap"

import { Link, Navigate, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// import images
import logo from "assets/images/logo.svg"

//Import config
import axios from "axios"
import Loader from "pages/seperate/Loader"
import CompanyLogo from "../../assets/images/Company-logo-new.png"

const Login = props => {
  const [error2, setError2] = useState(false)

  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setLoading] = useState(false)

  // https://ztpl.net/admin/aapi/
  // https://api.orthotrack.in/aapi/

  document.title = "Login | Surgery Management System"

  const onHandleLogin = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("email", email)
    formData.append("password", password)
    try {
      const response = await axios.post("https://api.orthotrack.in/api/login", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })

      // console.log(response)

      if (response.data.success == true) {
        localStorage.setItem("authid", response.data.data[0].id)
        localStorage.setItem("authrole_id", response.data.data[0].roleid)
        localStorage.setItem("authName", response.data.data[0].name)
        localStorage.setItem("auth_z", response.data.data[0].zone)
        localStorage.setItem("record", response.data.data[0].records)
        navigate("/dashboard")
      } else {
        setError2(true)
      }
      setLoading(false)

    } catch (err) {
      setError2(true)
      console.log(err)
      setLoading(false)
    }
  }

  const [passwordType, setPasswordType] = useState("password")

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return
    }
    setPasswordType("password")
  }

  if (!localStorage.getItem("authid") && !localStorage.getItem("authrole_id")) {
    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <div className="bd-overlay-img">
          <div className="account-pages pt-50 pt-sm-5 main-login-page">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row className="align-item-center">
                        <Col xs={8}>
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Welcome Back !</h5>
                            <p>Sign in to continue to Surgery Management.</p>
                          </div>
                        </Col>
                        <Col className="col-4 text-center">
                          <img
                            src={CompanyLogo}
                            alt=""
                            className="img-fluid"
                            style={{ width: "60%" }}
                          />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div>
                        <Link to="/" className="logo-light-element">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={onHandleLogin}
                        >
                          {error2 ? (
                            <Alert color="danger">
                              Username and password are invalid. Please enter
                              correct username and password
                            </Alert>
                          ) : null}

                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              required
                              onChange={e => {
                                setEmail(e.target.value)
                                setError2(false)
                              }}
                            />
                          </div>

                          <div className="mb-3 position-relative">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type={passwordType}
                              placeholder="Enter Password"
                              required
                              onChange={e => {
                                setPassword(e.target.value)
                                setError2(false)
                              }}
                            />
                            <a
                              className="bd-pass-icon"
                              onClick={togglePassword}
                            >
                              {passwordType === "password" ? (
                                <i className="fas fa-eye-slash "></i>
                              ) : (
                                <i className="fas fa-eye "></i>
                              )}
                            </a>
                          </div>

                          <div className="form-check">
                            <Row>
                              <div className="col-md-12 text-right">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock me-1" />
                                  Forgot your password?
                                </Link>
                              </div>
                            </Row>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <Navigate
        to={{ pathname: "/dashboard", state: { from: props.location } }}
      />
    )
  }
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
