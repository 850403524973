import React, { useEffect, useMemo, useState } from "react"
import {
    useTable,
    useGlobalFilter,
    usePagination,
    useSortBy,
} from "react-table"
import {
    Table,
    Row,
    Col,
    Button,
    CardTitle,
    Modal
} from "reactstrap"
import GlobalFilter from "pages/seperate/GlobalFilter"
import axios from "axios"
import Loader from "pages/seperate/Loader"
import swal from "sweetalert"

const MasterActivity = ({ heading, activities }) => {

    const [viewActivityModal, setViewActivityModal] = useState(false)

    // ---------------- API  Integration -------------------------//

    // All Zone data

    const [isLoading, setLoading] = useState(false)
    const [modal_center, setmodal_center] = useState(false)


    const columns = useMemo(
        () => [
            {
                Header: "Sr No.",
                accessor: "sr_no",
                className: "col-1 text-center",
                filterable: false,
                Cell: cellProps => <span>{cellProps.row.index + 1}</span>
            },
            {
                Header: "Staff Name",
                accessor: "name",
                filterable: true,
                className: "col-2"
            },
            {
                Header: "Activity Log",
                accessor: "log_data",
                filterable: true,
            },
            {
                Header: "Date & Time",
                accessor: "created_at",
                filterable: true,
                className: "col-2 text-center"
            },
            
            // {
            //     Header: "Action",
            //     filterable: false,
            //     className: "col-1 text-center",
            //     Cell: cellProps => {
                    
            //         return (
            //             cellProps.row.original.link ?
            //                 <button onClick={() => setViewActivityModal(true)} className="btn btn-sm btn-outline-secondary">
            //                     <i className="fas fa-eye"></i>
            //                 </button> : "--"
            //         )
            //     }
            // },
        ],
        []
    )

    const data = useMemo(() => activities, [activities])

    const {
        getTableBodyProps,
        getTableProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        canPreviousPage,
        gotoPage,
        pageCount,
        canNextPage,
        setPageSize,
        previousPage,
        pageOptions,
        state,
        setGlobalFilter,
        nextPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            data,
            columns,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                pageSize: 10,
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const { globalFilter } = state

    const generateSortingIndicator = column => {
        return column.isSorted ? (
            column.isSortedDesc ? (
                <>
                    <i
                        className="fas fa-long-arrow-alt-down"
                        style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
                    ></i>
                    <i
                        className="fas fa-long-arrow-alt-up "
                        style={{ fontSize: "11px" }}
                    ></i>
                </>
            ) : (
                <>
                    <i
                        className="fas fa-long-arrow-alt-down"
                        style={{ paddingLeft: "5px", fontSize: "11px" }}
                    ></i>
                    <i
                        className="fas fa-long-arrow-alt-up "
                        style={{ fontSize: "11px", color: "#00000040" }}
                    ></i>
                </>
            )
        ) : (
            <>
                <i
                    className="fas fa-long-arrow-alt-down"
                    style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
                ></i>
                <i
                    className="fas fa-long-arrow-alt-up "
                    style={{ fontSize: "11px", color: "#00000040" }}
                ></i>
            </>
        )
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    const [open, setOpen] = useState(false)

    const toggleLeftCanvas = () => {
        setOpen(!open)
    }


    return (
        <div>
            {isLoading && <Loader />}
            <div className="bd-side-header">
                <CardTitle className="h4 text-black">{heading}</CardTitle>
                <p className="card-title-desc">Activity Logs of {heading}</p>
            </div>

            <div className="table-responsive react-table">
                <Table {...getTableProps()} className="table-striped">
                    <thead className="">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        key={column.id}
                                        {...column.getSortByToggleProps()}
                                        className={column.className}
                                    >
                                        {column.render("Header")}
                                        {column.filterable && generateSortingIndicator(column)}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr key={row.id} {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <td
                                            key={cell.id}
                                            {...cell.getCellProps()}
                                            data-column={cell.column.Header}
                                            className={cell.column.className}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>

            <div>
                <Row className="justify-content-md-end justify-content-center align-items-center">
                    <Col className="col-md-auto">
                        <div className="d-flex gap-1">
                            <Button
                                color="primary"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {"<<"}
                            </Button>
                            <Button
                                color="primary"
                                onClick={previousPage}
                                disabled={!canPreviousPage}
                            >
                                {"<"}
                            </Button>
                        </div>
                    </Col>
                    <Col className="col-md-auto  d-md-block">
                        <span className="d-mobile-none"> Page </span>
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </Col>

                    <Col className="col-md-auto">
                        <div className="d-flex gap-1">
                            <Button
                                color="primary"
                                onClick={nextPage}
                                disabled={!canNextPage}
                            >
                                {">"}
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {">>"}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>

            <Modal
                isOpen={viewActivityModal}
                size="lg"
                // className="bd-import-target"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">View Activity</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setViewActivityModal(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>
                        <Table className="table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Before Change</th>
                                    <th>After Change</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Before Change Data</td>
                                    <td>After Change Data</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Before Change Data</td>
                                    <td>After Change Data</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="text-end mt-5">
                        <button className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default MasterActivity
