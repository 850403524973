import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useMemo, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Table,
  Modal,
} from "reactstrap"
import GlobalFilter from "pages/seperate/GlobalFilter"
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import Loader from "pages/seperate/Loader"
import swal from "sweetalert"

const index = () => {
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    setLoading(true)
    try {
      const response = axios({
        method: "get",
        url: "https://api.orthotrack.in/api/roles",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        className: "id-width",
      },
      {
        Header: "Roles",
        accessor: "role_name",
        filterable: true,
      },
      {
        Header: "Action",
        filterable: true,
        className: "action-width-role ",
        Cell: cellProps => {
          const onCallBack = () => {
            navigate(`/edit-roles/${cellProps.row.values.id}`)
          }

          const [roles, setRoles] = useState([])
          useEffect(() => {
            const id = localStorage.getItem("authrole_id")
            const fetch = async () => {
              setLoading(true)
              try {
                const { data } = await axios.get(
                  `https://api.orthotrack.in/api/roles/${id}`
                )
                setRoles(data.data.permission)
                setLoading(false)
              } catch (err) {
                console.error(err)
                setLoading(false)
              }
            }
            fetch()
          }, [])

          const onDeleteRole = () => {}

          let Edit
          let Delete
          const allRoles = roles.map(item => {
            if (item.module_id == 5 && item.edit == 1) {
              Edit = (
                <div className="col-2 bd-edit-resposnive-btn resp-mr-10">
                  <a
                    className="btn btn-outline-secondary btn-sm edit"
                    title="Edit"
                    onClick={onCallBack}
                  >
                    <i className="fas fa-pencil-alt"></i>
                  </a>
                </div>
              )
            }

            if (item.module_id == 5 && item.destroy == 1) {
              Delete = (
                <div className="col-3">
                  <a
                    className="btn btn-danger btn-sm edit"
                    onClick={async e => {
                      swal({
                        title: "Are you sure?",
                        text: "You want to Delete this Role!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then(async willDelete => {
                        if (willDelete) {
                          e.preventDefault()
                          setLoading(true)
                          const formData = new FormData()
                          formData.append("role_id", cellProps.row.values.id)
                          formData.append("user_id", localStorage.getItem("authid"))
                          
                          try {
                            const response = await axios({
                              method: "post",
                              url: "https://api.orthotrack.in/api/deleteRoles",
                              data: formData,
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                              },
                            }).then(response => {
                              swal({
                                icon: "success",
                                text: "Role Deleted Successfully",
                              }).then(data => {
                                setDatas(response.data.data)
                              })
                              setLoading(false)
                            })
                          } catch (err) {
                            swal({
                              icon: "error",
                              text: "SomeThing Went Wrong",
                            })
                            setLoading(false)
                          }
                        }
                      })
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </a>
                </div>
              )
            }
          })

          return (
            <>
              <div className="row ">
                {Edit}
                {Delete}
                <div className="col-2 bd-responsive-filter2">
                  <a className="btn btn-outline-secondary btn-sm edit">
                    <i className="fas fa-eye"></i>
                  </a>
                </div>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const [open, setOpen] = useState(false)

  const toggleLeftCanvas = () => {
    setOpen(!open)
  }

  const [roles, setRoles] = useState([])
  useEffect(() => {
    const id = localStorage.getItem("authrole_id")
    const fetch = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `https://api.orthotrack.in/api/roles/${id}`
        )
        setRoles(data.data.permission)
        setLoading(false)
      } catch (err) {
        console.error(err)
        setLoading(false)
      }
    }
    fetch()
  }, [])

  let Add
  const allRole = roles.map(item => {
    if (item.module_id == 5 && item.inserts == 1) {
      Add = (
        <Col md="2" className="w-50 text-right full-width">
          <Link to={"/add-roles"}>
            <button className="btn-theme-color border-radius-btn">
              + Add Roles
            </button>
          </Link>
        </Col>
      )
    }
  })

  return (
    <div>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid className="custom-container">
          <Breadcrumb
            title={"Dashboard"}
            breadcrumbItem={"Roles & Permissions"}
          />
          <div>
            <div>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="bd-responsive-filter">
                        <div>
                          <CardTitle className="h4 text-black ">
                            Roles And Permissions
                          </CardTitle>
                          <p className="card-title-desc">
                            Listing View of Roles And Permissions
                          </p>
                        </div>
                        <div>
                          <Row className="mb-2 align-item-center">
                            <Col md="2">
                              <select
                                className="form-select"
                                value={pageSize}
                                onChange={onChangeInSelect}
                              >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                  <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <GlobalFilter
                              filter={globalFilter}
                              setFilter={setGlobalFilter}
                            />
                            {/* <Col md="2" className="w-50 text-right full-width">
                              <Link to={"/add-roles"}>
                                <button className="btn-theme-color border-radius-btn">
                                  + Add Roles
                                </button>
                              </Link>
                            </Col> */}
                            {Add}
                          </Row>
                        </div>
                      </div>

                      {/* Responsive Top section */}

                      <div className="bd-responsive-filter2">
                        <div>
                          <div className="display-flex align-item-center">
                            {/* <div className="col-10">
                              <Link to={"/add-surgery-track"}>
                                <Button className="btn-theme-color">
                                  + Add Surgery Track
                                </Button>
                              </Link>
                            </div> */}
                            {Add}
                            <div className="col-2 text-right">
                              <a
                                className="btn btn-outline-secondary bd-custom-sidebar"
                                onClick={toggleLeftCanvas}
                              >
                                <i className="fas fa-filter"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        {open && (
                          <div className="filter-sidebar" id="mySidenav">
                            <div className=" display-flex">
                              <div className="col-9">Apply Filter</div>
                              <div className="col-3 text-right">
                                <a className="" onClick={toggleLeftCanvas}>
                                  <i className="fas fa-times"></i>
                                </a>
                              </div>
                            </div>
                            <div className="pt-20">
                              <Row className="mb-2 align-item-center">
                                <Col md="2">
                                  <select
                                    className="form-select"
                                    value={pageSize}
                                    onChange={onChangeInSelect}
                                  >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                      <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                      </option>
                                    ))}
                                  </select>
                                </Col>
                                <GlobalFilter
                                  filter={globalFilter}
                                  setFilter={setGlobalFilter}
                                />
                              </Row>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="">
                        <div className="table-responsive react-table">
                          <Table
                            {...getTableProps()}
                            className="table-striped responsive-main-table"
                          >
                            <thead className="">
                              {headerGroups.map(headerGroup => (
                                <tr
                                  key={headerGroup.id}
                                  {...headerGroup.getHeaderGroupProps()}
                                >
                                  {headerGroup.headers.map(column => (
                                    <th
                                      key={column.id}
                                      className={column.className}
                                    >
                                      <div {...column.getSortByToggleProps()}>
                                        {column.render("Header")}
                                        {generateSortingIndicator(column)}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                              {page.map(row => {
                                prepareRow(row)
                                return (
                                  <tr key={row.id} {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                      <td
                                        key={cell.id}
                                        {...cell.getCellProps()}
                                        data-column={cell.column.Header}
                                        className={cell.column.className}
                                      >
                                        {cell.render("Cell")}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                        <div>
                          <Row className="justify-content-md-end justify-content-center align-items-center">
                            <Col className="col-md-auto">
                              <div className="d-flex gap-1">
                                <Button
                                  color="primary"
                                  onClick={() => gotoPage(0)}
                                  disabled={!canPreviousPage}
                                >
                                  {"<<"}
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={previousPage}
                                  disabled={!canPreviousPage}
                                >
                                  {"<"}
                                </Button>
                              </div>
                            </Col>
                            <Col className="col-md-auto d-md-block">
                              <span className="d-mobile-none"> Page </span>
                              <strong>
                                {pageIndex + 1} of {pageOptions.length}
                              </strong>
                            </Col>

                            <Col className="col-md-auto">
                              <div className="d-flex gap-1">
                                <Button
                                  color="primary"
                                  onClick={nextPage}
                                  disabled={!canNextPage}
                                >
                                  {">"}
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => gotoPage(pageCount - 1)}
                                  disabled={!canNextPage}
                                >
                                  {">>"}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default index
