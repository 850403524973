import React, { useMemo, useState, useEffect } from "react"
import axios from "axios"
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import Select from "react-select"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Form,
  Label,
  CardTitle,
} from "reactstrap"
import GlobalFilter from "pages/seperate/GlobalFilter"
import Loader from "pages/seperate/Loader"
import ExportExcel from "pages/Reports/Export"

const HospitalTable = ({ edit_role, add_role, delete_role }) => {
  const [isLoading, setLoading] = useState(false)
  const [options, setOptions] = useState([])

  const [district_id, setDistrictId] = useState("")
  const [hospital_name, setHospitalName] = useState("")
  const [hospital_id, setHospitalId] = useState("")
  const [district_name, setDistrictName] = useState("")
  const [reg_no, setReg_no] = useState("")
  const [mobile, setMobile] = useState("")
  const [city, setCity] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [contact_no, setContactNo] = useState("")
  const [pincode, setPinCode] = useState("")
  const [data, setdatas] = useState([])
  const [poc_name_one, setPocName1] = useState("")
  const [poc_contact_one, setPocContact1] = useState("")
  const [poc_name_two, setPocName2] = useState("")
  const [poc_contact_two, setPocContact2] = useState("")

  const [exportData, setExportData] = useState([])

  // get Exporting Data
  useEffect(() => {
    try {
      const response = axios({
        method: "get",
        url: "https://api.orthotrack.in/api/hospital_export",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        if (response.data.length != 0) {
          setExportData(response.data)
        }
      })
    } catch (error) { console.log(error) }
  }, [])

  useEffect(() => {
    setLoading(true)
    const url1 = "https://api.orthotrack.in/api/districts"
    const url2 = "https://api.orthotrack.in/api/hospitals"
    try {
      const response = axios({
        method: "get",
        url: url1,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.district_name + " - " + item.state_name,
        }))
        setOptions(options)
        setLoading(false)
      })

      const response1 = axios({
        method: "get",
        url: url2,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response1 => {
        setdatas(response1.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }, [])

  const onAddHospital = async e => {
    e.preventDefault()
    if (district_id?.length === 0) {
      swal('', 'City is Required!', 'warning')
      return;
    }
    setLoading(true)
    const formData = new FormData()
    formData.append("district_id", district_id)
    formData.append("hospital_name", hospital_name)
    formData.append("poc_name_one", poc_name_one)
    formData.append("poc_contact_one", poc_contact_one)
    formData.append("poc_name_two", poc_name_two)
    formData.append("poc_contact_two", poc_contact_two)
    formData.append("address", address)
    formData.append("email", email)
    formData.append("user_id", localStorage.getItem("authid"))
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/storeHospitals",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Hospital Added Successfully",
        }).then(data => {
          setmodal_center(false)
        })
        setdatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  // Edit Form

  const onEditHospital = async e => {
    e.preventDefault()
    if (district_id?.length === 0) {
      swal('', 'City is Required!', 'warning')
      return;
    }
    setLoading(true)
    const formData = new FormData()
    formData.append("hospital_id", hospital_id)
    formData.append("district_id", district_id)
    formData.append("hospital_name", hospital_name)
    formData.append("poc_name_one", poc_name_one)
    formData.append("poc_contact_one", poc_contact_one)
    formData.append("poc_name_two", poc_name_two)
    formData.append("poc_contact_two", poc_contact_two)
    formData.append("address", address)
    formData.append("email", email)
    formData.append("user_id", localStorage.getItem("authid"))
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/updateHospitals",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Hospital Added Successfully",
        }).then(data => {
          setEditState(false)
        })
        setdatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
      },
      {
        Header: "Hospital Name",
        accessor: "hospital_name",
        filterable: true,
      },
      {
        Header: "Contact No.",
        accessor: "contact_no",
        filterable: true,
        className: "bd-show-extra-field display-none",
      },
      {
        Header: "City ",
        accessor: "district_name",
        filterable: true,
      },
      {
        Header: "Address",
        accessor: "address",
        filterable: true,
        className: "",
      },
      {
        Header: "Email-ID ",
        accessor: "email",
        filterable: true,
        className: "bd-show-extra-field",
      },
      {
        Header: "Name Of POC - 1 ",
        accessor: "poc_name_one",
        filterable: true,
        className: "display-none",
      },
      {
        Header: "Contact No. Of POC - 1",
        accessor: "poc_contact_one",
        filterable: true,
        className: "display-none",
      },
      {
        Header: "Name Of POC - 2",
        accessor: "poc_name_two",
        filterable: true,
        className: "display-none",
      },
      {
        Header: "Contact Bo. Of POC - 2",
        accessor: "poc_contact_two",
        filterable: true,
        className: "display-none",
      },
      {
        Header: "Action",
        filterable: true,
        className: "border-botttom-black",
        Cell: cellProps => {
          let Edit
          let Delete
          if (edit_role == "1") {
            Edit = (
              <div className="bio-edit-btn">
                <a
                  className="btn btn-outline-secondary btn-sm edit "
                  title="Edit"
                  onClick={() => {
                    setEditState(true)
                    setHospitalId(cellProps.row.original.id)
                    setHospitalName(cellProps.row.original.hospital_name)
                    setPocName1(cellProps.row.original.poc_name_one)
                    setPocName2(cellProps.row.original.poc_name_two)
                    setPocContact1(cellProps.row.original.poc_contact_one)
                    setPocContact2(cellProps.row.original.poc_contact_two)
                    setCity(cellProps.row.original.city)
                    setAddress(cellProps.row.original.address)
                    setEmail(cellProps.row.original.email)
                    setDistrictName(cellProps.row.original.district_name)
                  }}
                >
                  <i className="fas fa-pencil-alt"></i>
                </a>
              </div>
            )
          }

          if (delete_role == "1") {
            Delete = (
              <div className="bio-edit-btn">
                <a
                  className="btn btn-danger btn-sm edit"
                  onClick={async e => {
                    swal({
                      title: "Are you sure?",
                      text: "You want to Delete this Hospital!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async willDelete => {
                      if (willDelete) {
                        e.preventDefault()
                        setLoading(true)
                        const formData = new FormData()
                        formData.append("hospital_id", cellProps.row.values.id)
                        formData.append("user_id", localStorage.getItem("authid"))

                        try {
                          const response = await axios({
                            method: "post",
                            url: "https://api.orthotrack.in/api/deleteHospitals",
                            data: formData,
                            headers: {
                              Accept: "application/json",
                              "Content-Type": "multipart/form-data",
                            },
                          }).then(response => {
                            swal({
                              icon: "success",
                              text: "Hospital Deleted Successfully",
                            }).then(data => {
                              setmodal_center(false)
                              setdatas(response.data.data)
                            })
                            setLoading(false)
                          })
                        } catch (err) {
                          swal({
                            icon: "error",
                            text: "SomeThing Went Wrong",
                          })
                          setLoading(false)
                        }
                      }
                    })
                  }}
                >
                  <i className="fas fa-trash-alt"></i>
                </a>
              </div>
            )
          }

          return (
            <>
              <div className="row ">
                {Edit}
                {Delete}
                <div className="col-2 bd-responsive-filter2">
                  <a
                    className="btn btn-outline-secondary btn-sm edit"
                    onClick={() => {
                      setViewHospital(true)
                      setTrackId(cellProps.row.values.id)
                    }}
                  >
                    <i className="fas fa-eye"></i>
                  </a>
                </div>
              </div>
            </>
          )
        },
      },
    ],
    [edit_role, delete_role]
  )

  const [modal_center, setmodal_center] = useState(false)
  const [editState, setEditState] = useState(false)
  const [viewHospital, setViewHospital] = useState(false)
  const [track_id, setTrackId] = useState("")

  function tog_center() {
    setmodal_center(!modal_center)
  }

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px", color: "#00000040" }}
          ></i>
        </>
      )
    ) : (
      <>
        <i
          className="fas fa-long-arrow-alt-down"
          style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
        ></i>
        <i
          className="fas fa-long-arrow-alt-up "
          style={{ fontSize: "11px", color: "#00000040" }}
        ></i>
      </>
    )
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [open, setOpen] = useState(false)

  const toggleLeftCanvas = () => {
    setOpen(!open)
  }

  const role_id = localStorage.getItem("authrole_id")
  let SubmitButton
  if (role_id == 4) {
    SubmitButton = <Button className="btn btn-primary">Send For Request</Button>
  } else {
    SubmitButton = <Button className="btn btn-primary">Submit</Button>
  }

  // Logic for exporting table data
  const transformedArray = []
  exportData.forEach((item, i) => {
    transformedArray.push({
      ["ID"]: item.id,
      ["City"]: item.city_name,
      ["Hospital"]: item.hospital_name
    })
  })

  let Add1
  let Add2
  if (add_role == "1") {
    Add1 = (
      <Col md="2" className="w-50 d-flex justify-content-end bd-responsive-btn text-right">
        <Button
          className="btn-theme-color"
          onClick={() => {
            tog_center()
          }}
        >
          + Add Hospital
        </Button>
        <ExportExcel
          data={transformedArray}
          marginTop={"mt-08"}
          name={"Hospital.xlsx"}
        />
      </Col>
    )
    Add2 = (
      <div className="col-10">
        <Button
          className="btn-theme-color"
          onClick={() => {
            tog_center()
          }}
        >
          + Add Hospital
        </Button>
      </div>
    )
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="bd-side-header">
        <CardTitle className="h4 text-black">Hospital</CardTitle>
        <p className="card-title-desc">Listing View of Hospitals</p>
      </div>

      <div className="bd-responsive-filter">
        <Row className="mb-2 align-item-center">
          <Col md="2">
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {Add1}
        </Row>
      </div>

      {/* Responsive Top section */}

      <div className="bd-responsive-filter2 pb-20">
        <div>
          <div className="display-flex align-item-center">
            {Add2}
            <div className="col-2 text-right">
              <a
                className="btn btn-outline-secondary bd-custom-sidebar"
                onClick={toggleLeftCanvas}
              >
                <i className="fas fa-filter "></i>
              </a>
            </div>
          </div>
        </div>
        {open && (
          <div className="filter-sidebar" id="mySidenav">
            <div className=" display-flex">
              <div className="col-9">Apply Filter</div>
              <div className="col-3 text-right">
                <a className="" onClick={toggleLeftCanvas}>
                  <i className="fas fa-times"></i>
                </a>
              </div>
            </div>
            <div className="pt-20">
              <Row className="mb-2 align-item-center">
                <Col md="2">
                  <select
                    className="form-select"
                    value={pageSize}
                    onChange={onChangeInSelect}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </Row>
            </div>
          </div>
        )}
      </div>

      <div className="table-responsive react-table">
        <Table {...getTableProps()} className="table-striped">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.className}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                      data-column={cell.column.Header}
                      className={cell.column.className}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      <div>
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto  d-md-block">
            <span className="d-mobile-none"> Page </span>
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {/* Add State Modal */}
      <Modal
        isOpen={modal_center}
        size="sm"
        className="bd-custom-md-2"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Hostpital Master</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form className="row" onSubmit={onAddHospital}>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Hostpital Name</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Hostpital Name"
                onChange={e => setHospitalName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Name Of POC - 1</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Name Of POC - 1"
                onChange={e => setPocName1(e.target.value)}
                
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">
                Contact Number Of POC - 1
              </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Contact Number Of POC - 1"
                onChange={e => setPocContact1(e.target.value)}
                
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Name Of POC - 2</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Name Of POC - 2"
                onChange={e => setPocName2(e.target.value)}
                
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">
                Contact Number Of POC - 2
              </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Contact Number Of POC - 2"
                onChange={e => setPocContact2(e.target.value)}
                
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">City</Label>
              <Select
                onChange={e => setDistrictId(e.value)}
                options={options}
                className="select2-selection"
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Address </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Address"
                onChange={e => setAddress(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Email Id </Label>
              <Input
                type="email"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Email Id"
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            {/* <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Point of Contact </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Point of Contact"
                onChange={e => setContactNo(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input"> Pin Code </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Pin Code"
                onChange={e => setPinCode(e.target.value)}
              />
            </div> */}
            <div className="pt-30">{SubmitButton}</div>
          </form>
        </div>
      </Modal>

      {/* Edit State Modal */}

      <Modal isOpen={editState} size="sm" className="bd-custom-md-2" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Hostpital Master</h5>
          <button
            type="button"
            onClick={() => {
              setEditState(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form className="row" onSubmit={onEditHospital}>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Hostpital Name</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Hostpital Name"
                defaultValue={hospital_name}
                onChange={e => setHospitalName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Name Of POC - 1</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Name Of POC - 1"
                defaultValue={poc_name_one}
                onChange={e => setPocName1(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">
                Contact Number Of POC - 1
              </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Contact Number Of POC - 1"
                defaultValue={poc_contact_one}
                onChange={e => setPocContact1(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Name Of POC - 2</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Name Of POC - 2"
                defaultValue={poc_name_two}
                onChange={e => setPocName2(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">
                Contact Number Of POC - 2
              </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Contact Number Of POC - 2"
                defaultValue={poc_contact_two}
                onChange={e => setPocContact2(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">City</Label>
              <Select
                onChange={e => setDistrictId(e.value)}
                options={options}
                defaultValue={{ label: district_name }}
                className="select2-selection"
              />
            </div>
            {/* <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">City </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter city"
                defaultValue={city}
                onChange={e => setCity(e.target.value)}
              />
            </div> */}
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Address </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Address"
                defaultValue={address}
                onChange={e => setAddress(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Email Id </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Email Id"
                defaultValue={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            {/* <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Point of Contact </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Point of Contact"
                defaultValue={contact_no}
                onChange={e => setContactNo(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input"> Pin Code </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Pin Code"
                defaultValue={pincode}
                onChange={e => setPinCode(e.target.value)}
              />
            </div> */}
            <div className="pt-30">
              <Button className="btn btn-primary">Submit</Button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal isOpen={viewHospital} size="sm" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Hospitals Details</h5>
          <button
            type="button"
            onClick={() => {
              setViewHospital(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body view-modal-height">
          <div>
            <div>
              <div className="table-responsive react-table">
                <Table
                  {...getTableProps()}
                  className="table-striped responsive-main-table"
                >
                  <thead className="">
                    {headerGroups.map(headerGroup => (
                      <tr
                        key={headerGroup.id}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map(column => (
                          <th key={column.id}>
                            <div {...column.getSortByToggleProps()}>
                              {column.render("Header")}
                              {generateSortingIndicator(column)}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                      if (row.original.id == track_id) {
                        return (
                          <tr key={row.id} {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              if (cell.value != undefined) {
                                return (
                                  <td
                                    key={cell.id}
                                    {...cell.getCellProps()}
                                    data-column={cell.column.Header}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                )
                              }
                            })}
                          </tr>
                        )
                      }
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default HospitalTable
