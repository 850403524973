import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useMemo, useState } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Table,
    Modal,
    Label,
} from "reactstrap"
import {
    useTable,
    useGlobalFilter,
    usePagination,
    useSortBy,
} from "react-table"
import axios from "axios"
import Loader from "pages/seperate/Loader"
import ExportExcel from "./Export"
import swal from "sweetalert"

const index = () => {
    const [isLoading, setLoading] = useState(false)
    const [from_month, setFromMonth] = useState(new Date().getMonth() + 1)
    const [to_year, setToYear] = useState(new Date().getFullYear())

    const [allsurgeonReport, setAllSurgeonReport] = useState([])

    const allMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const all_years = [
        2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014,
        2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
        2027, 2028, 2029, 2030, 2031, 2032, 2034, 2035, 2036, 2037, 2038, 2039,
        2040, 2041, 2042, 2043
    ]

    const fetchData = async () => {
        if (from_month != "" && to_year != "") {
            setLoading(true)
            try {
                const formData = new FormData()
                formData.append("month", from_month)
                formData.append("year", to_year)

                const response = await axios.post("https://api.orthotrack.in/api/surgeon_surgery", formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                })

                const sortedData = response.data.sort((a, b) => (b.knee + b.hip + b.arthoscopy + b.bipolar) - (a.knee + a.hip + a.arthoscopy + a.bipolar))

                setAllSurgeonReport(sortedData.map((item) => ({
                    ...item,
                    month_year: allMonths[from_month - 1] + " - " + to_year,
                    total: item.knee + item.hip + item.arthoscopy + item.bipolar
                })))

                setLoading(false)

            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        } else {
            swal("", "Both Fields (Month and Year) are mandatory!", "warning")
        }
    }

    useEffect(() => {
        // fetching surgeon wise all records
        fetchData()
    }, [])

    const columns = useMemo(
        () => [
            {
                Header: "Sr. No",
                accessor: "sr_no",
                filterable: true,
                className: "text-center",
                Cell: cellProps => <span>{cellProps.row.index + 1}</span>,
            },
            {
                Header: "Surgeon Name",
                accessor: "doctor_name",
                filterable: true,
            },
            {
                Header: "Month & Year",
                accessor: "month_year",
                filterable: true,
                className: "text-center",
            },
            {
                Header: "Knee",
                accessor: "knee",
                filterable: true,
                className: "text-center",
            },
            {
                Header: "Hip",
                accessor: "hip",
                filterable: true,
                className: "text-center",
            },
            {
                Header: "Arthroscopy",
                accessor: "arthoscopy",
                filterable: true,
                className: "text-center",
            },
            {
                Header: "Bipolar",
                accessor: "bipolar",
                filterable: true,
                className: "text-center",
            },
            {
                Header: "Total",
                accessor: "total",
                filterable: true,
                className: "text-center fw-bold",
            }
        ],
        []
    )
    // storing table data getting from api
    const data = useMemo(() => allsurgeonReport, [allsurgeonReport])

    const {
        getTableBodyProps,
        getTableProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        canPreviousPage,
        gotoPage,
        pageCount,
        canNextPage,
        setPageSize,
        previousPage,
        pageOptions,
        state,
        setGlobalFilter,
        nextPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            data,
            columns,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                pageSize: 10,
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const { globalFilter } = state

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    const transformedArray = []
    // Logic for exporting table data
    data.forEach((item, i) => {
        transformedArray.push({
            ["Sr. No"]: i + 1,
            ["Name of Surgeon"]: item.doctor_name,
            ["Month & Year"]: item.month_year,
            ["Knee"]: item.knee,
            ["Hip"]: item.hip,
            ["Arthroscopy"]: item.arthoscopy,
            ["Bipolar"]: item.bipolar
        })
    })


    return (
        <div>
            {isLoading && <Loader />}
            <div className="page-content">
                <Container fluid className="custom-container">
                    <Breadcrumb title={"Dashboard"} breadcrumbItem={"Surgeon Wise Report"} />
                    <div>
                        <div>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <div className="bd-responsive-filter">
                                                <div>
                                                    <CardTitle className="h4 text-black ">
                                                        Surgeon Wise Report
                                                    </CardTitle>
                                                    <p className="card-title-desc">
                                                        Listing View of Filtered Surgeon Wise Report
                                                    </p>
                                                </div>
                                                <div className="mb-30 mt-20">
                                                    <Row className="align-items-end">
                                                        <Col lg={3}>
                                                            <Label htmlFor="formrow-firstname-Input">
                                                                Month
                                                            </Label>

                                                            <select value={from_month}
                                                                onChange={(e) => setFromMonth(e.target.value)}
                                                                className="form-select">

                                                                <option value="">--Select Month--</option>
                                                                {
                                                                    allMonths.map((month, i) => <option key={i} value={i + 1}>{month}</option>)
                                                                }
                                                            </select>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Label htmlFor="formrow-firstname-Input">
                                                                Year
                                                            </Label>
                                                            <select
                                                                className="form-select"
                                                                value={to_year}
                                                                onChange={(e) => setToYear(e.target.value)}
                                                            >
                                                                <option value="">--Select Year--</option>
                                                                {all_years.map((obj, index) => {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            value={obj}
                                                                        >
                                                                            {obj}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            className="d-flex text-right justify-content-end  full-width"
                                                        >
                                                            <Button
                                                                className="btn-theme-color "
                                                                onClick={fetchData}
                                                            >
                                                                <i
                                                                    className="fas fa-search pr-10"
                                                                    style={{ fontSize: "10px" }}
                                                                ></i>
                                                                Search
                                                            </Button>

                                                            <ExportExcel
                                                                data={transformedArray}
                                                                marginTop={"mt-08"}
                                                                name={"Surgeon_wise_Report.xlsx"}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="table-responsive react-table ">
                                                    <Table
                                                        {...getTableProps()}
                                                        className="table-striped responsive-main-table"
                                                    >
                                                        <thead className="">
                                                            {headerGroups.map(headerGroup => (
                                                                <tr
                                                                    key={headerGroup.id}
                                                                    {...headerGroup.getHeaderGroupProps()}
                                                                >
                                                                    {headerGroup.headers.map(column => (
                                                                        <th
                                                                            key={column.id}
                                                                            className={column.className}
                                                                        >
                                                                            <div {...column.getSortByToggleProps()}>
                                                                                {column.render("Header")}
                                                                                {generateSortingIndicator(column)}
                                                                            </div>
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </thead>

                                                        <tbody {...getTableBodyProps()}>
                                                            {page.map(row => {
                                                                prepareRow(row)
                                                                return (
                                                                    <tr key={row.id} {...row.getRowProps()}>
                                                                        {row.cells.map(cell => (
                                                                            <td
                                                                                key={cell.id}
                                                                                {...cell.getCellProps()}
                                                                                data-column={cell.column.Header}
                                                                                className={cell.column.className}
                                                                            >
                                                                                {cell.render("Cell")}
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                )
                                                            })}
                                                            {data?.length == 0 ? (
                                                                <td colSpan="9" className="p-2 text-center">
                                                                    No data Available
                                                                </td>
                                                            ) : null}
                                                        </tbody>
                                                    </Table>
                                                    {/* </div> */}
                                                </div>
                                                <div>
                                                    <Row className="justify-content-md-end justify-content-center align-items-center">
                                                        <Col className="col-md-auto">
                                                            <div className="d-flex gap-1">
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() => gotoPage(0)}
                                                                    disabled={!canPreviousPage}
                                                                >
                                                                    {"<<"}
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={previousPage}
                                                                    disabled={!canPreviousPage}
                                                                >
                                                                    {"<"}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-md-auto d-md-block">
                                                            <span className="d-mobile-none"> Page </span>
                                                            <strong>
                                                                {pageIndex + 1} of {pageOptions.length}
                                                            </strong>
                                                        </Col>

                                                        <Col className="col-md-auto">
                                                            <div className="d-flex gap-1">
                                                                <Button
                                                                    color="primary"
                                                                    onClick={nextPage}
                                                                    disabled={!canNextPage}
                                                                >
                                                                    {">"}
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() => gotoPage(pageCount - 1)}
                                                                    disabled={!canNextPage}
                                                                >
                                                                    {">>"}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default index
