import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody } from "reactstrap"
import MergeColumnChart from "./MergerColumnChart"
//i18n
import { withTranslation } from "react-i18next"
import WelcomeComp from "./WelcomeComp"
import axios from "axios"
import Loader from "pages/seperate/Loader"
import SurgeryDoneChart from "./AllCharts/SurgeryDoneChart"
import TargetAchieveChart from "./AllCharts/TargetAchieveChart"
import ProductMix from "./AllCharts/ProductMix"
import SurgeonAddedDropped from "./AllCharts/SurgeonAddedDropped"
import DealersPerformance from "./AllCharts/DealersPerformance"
import EmployeeWiseData from "./AllCharts/EmployeeWiseData"

const Dashboard = props => {
  //meta title
  document.title = "Dashboard | Surgery Management System"

  const [isLoading, setLoading] = useState(false)
  const [optionZone, setOptionZone] = useState([])

  useEffect(() => {
    async function getZones() {
      try {
        const res = await axios.get("https://api.orthotrack.in/api/zones", {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })

        const options = res.data.data.map(item => ({
          value: item.id,
          label: item.zone_name,
        }))

        if (localStorage.getItem("record") == 1) {
          setOptionZone(options)
        } else {
          setOptionZone(
            options.filter(obj => obj.value == localStorage.getItem("auth_z"))
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
    getZones()
  }, [])

  useEffect(() => {
    tragetCount()
  }, [])

  const [target_data, setTargetData] = useState([])
  const tragetCount = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("authid"))
    formData.append("record", localStorage.getItem("record"))
    try {
      setLoading(true)
      const response = axios({
        method: "post",
        data: formData,
        url: "https://api.orthotrack.in/api/target_dashboard",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setTargetData(response.data)
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const currentMonth = new Date().getMonth() + 1

  const Knee = target_data?.filter(
    obj => obj.category == 4 && obj.month == currentMonth
  )
  const Hip = target_data?.filter(
    obj => obj.category == 5 && obj.month == currentMonth
  )
  const Arthroscopy = target_data?.filter(
    obj => obj.category == 8 && obj.month == currentMonth
  )
  const Bipolar = target_data?.filter(
    obj => obj.category == 9 && obj.month == currentMonth
  )

  const KneePercentage = parseFloat(
    (parseFloat(Knee[0]?.surgery_count) / parseFloat(Knee[0]?.total_count)) *
    100
  ).toFixed(2)

  const HipPercentage = parseFloat(
    (parseFloat(Hip[0]?.surgery_count) / parseFloat(Hip[0]?.total_count)) * 100
  ).toFixed(2)

  const ArthoPercentage = parseFloat(
    (parseFloat(Arthroscopy[0]?.surgery_count) /
      parseFloat(Arthroscopy[0]?.total_count)) *
    100
  ).toFixed(2)

  const BipolarPercentage = parseFloat(
    (parseFloat(Bipolar[0]?.surgery_count) /
      parseFloat(Bipolar[0]?.total_count)) *
    100
  ).toFixed(2)

  const [category, setCatgegory] = useState("knee")
  const [TargetData, setTargetDatas] = useState([])
  const [AchieveData, setAchieveDatas] = useState([])

  useEffect(() => {
    const kneeTragetData = target_data
      .filter(obj => obj.category == 4)
      ?.map(item => {
        return item.total_count
      })
    const HipTragetData = target_data
      .filter(obj => obj.category == 5)
      ?.map(item => {
        return item.total_count
      })
    const ArthTragetData = target_data
      .filter(obj => obj.category == 8)
      ?.map(item => {
        return item.total_count
      })
    const BipolarTragetData = target_data
      .filter(obj => obj.category == 9)
      ?.map(item => {
        return item.total_count
      })

    const kneeAchieveData = target_data
      .filter(obj => obj.category == 4)
      ?.map(item => {
        return item.surgery_count
      })
    const HipAchieveData = target_data
      .filter(obj => obj.category == 5)
      ?.map(item => {
        return item.surgery_count
      })
    const ArthAchieveData = target_data
      .filter(obj => obj.category == 8)
      ?.map(item => {
        return item.surgery_count
      })
    const BipolarAchieveData = target_data
      .filter(obj => obj.category == 9)
      ?.map(item => {
        return item.surgery_count
      })

    if (category == "knee") {
      setTargetDatas(kneeTragetData)
      setAchieveDatas(kneeAchieveData)
    } else if (category == "hip") {
      setTargetDatas(HipTragetData)
      setAchieveDatas(HipAchieveData)
    } else if (category == "arthroscopy") {
      setTargetDatas(ArthTragetData)
      setAchieveDatas(ArthAchieveData)
    } else if (category == "bipolar") {
      setTargetDatas(BipolarTragetData)
      setAchieveDatas(BipolarAchieveData)
    }
  }, [target_data, category])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                <Col md="12">
                  <div className="mini-stats-wid mb-20">
                    <Card className="card-body">
                      <div className="mb-15">
                        <h4>Surgeries</h4>
                      </div>
                      <div className="d-flex justify-content-center ">
                        <div
                          className="flex-grow-12 bd-resp-num bd-resp-back"
                          style={{ marginLeft: "0px" }}
                        >
                          <p
                            className=" fw-medium mb-12 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Knee
                          </p>
                          <h4 className="mb-0 bd-resp-num text-center">
                            {Knee != undefined
                              ? Knee[0]?.surgery_count +
                              "/" +
                              Knee[0]?.total_count
                              : 0}
                            <br />
                            <span
                              className="text-danger"
                              style={{ fontSize: "13px" }}
                            >
                              (
                              {KneePercentage != "NaN"
                                ? KneePercentage
                                : "0.00"}
                              %)
                            </span>
                          </h4>
                        </div>
                        <div className="flex-grow-12 bd-resp-back2">
                          <p
                            className="text-muted fw-medium mb-12 bd-resp-num text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Hip
                          </p>
                          <h4 className="mb-0 bd-resp-num text-center">
                            {Hip != undefined
                              ? Hip[0]?.surgery_count +
                              "/" +
                              Hip[0]?.total_count
                              : 0}
                            <br />
                            <span
                              className="text-danger"
                              style={{ fontSize: "13px" }}
                            >
                              ({HipPercentage != "NaN" ? HipPercentage : "0.00"}
                              %)
                            </span>
                          </h4>
                        </div>
                        <div
                          className="flex-grow-12 bd-resp-back3"
                          style={{ padding: "13px 0px" }}
                        >
                          <p
                            className="text-muted fw-medium mb-12 bd-resp-num text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Arthroscopy
                          </p>
                          <h4 className="mb-0 bd-resp-num text-center">
                            {Arthroscopy != undefined
                              ? Arthroscopy[0]?.surgery_count +
                              "/" +
                              Arthroscopy[0]?.total_count
                              : 0}
                            <br />
                            <span
                              className="text-danger"
                              style={{ fontSize: "13px" }}
                            >
                              (
                              {ArthoPercentage != "NaN"
                                ? ArthoPercentage
                                : "0.00"}
                              %)
                            </span>
                          </h4>
                        </div>
                        <div
                          className="flex-grow-12 bd-resp-back3"
                          style={{ padding: "13px 0px" }}
                        >
                          <p
                            className="text-muted fw-medium mb-12 bd-resp-num text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Bipolar
                          </p>
                          <h4 className="mb-0 bd-resp-num text-center">
                            {Bipolar != undefined
                              ? Bipolar[0]?.surgery_count +
                              "/" +
                              Bipolar[0]?.total_count
                              : 0}{" "}
                            <br />
                            <span
                              className="text-danger"
                              style={{ fontSize: "13px" }}
                            >
                              (
                              {BipolarPercentage != "NaN"
                                ? BipolarPercentage
                                : 0.0}
                              %)
                            </span>
                          </h4>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap justify-space-between">
                    <h4 className="card-title mb-4">Targets & Achievements</h4>
                    <div className="d-flex">
                      <div>
                        <a
                          className={
                            category == "knee"
                              ? "bd-categories active"
                              : "bd-categories"
                          }
                          onClick={() => setCatgegory("knee")}
                        >
                          Knee
                        </a>
                      </div>
                      <div>
                        <a
                          className={
                            category == "hip"
                              ? "bd-categories active"
                              : "bd-categories"
                          }
                          onClick={() => setCatgegory("hip")}
                        >
                          Hip
                        </a>
                      </div>
                      <div>
                        <a
                          className={
                            category == "arthroscopy"
                              ? "bd-categories active"
                              : "bd-categories"
                          }
                          onClick={() => setCatgegory("arthroscopy")}
                        >
                          Arthroscopy
                        </a>
                      </div>
                      <div>
                        <a
                          className={
                            category == "bipolar"
                              ? "bd-categories active"
                              : "bd-categories"
                          }
                          onClick={() => setCatgegory("bipolar")}
                        >
                          Bipolar
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <MergeColumnChart
                    outputs={TargetData}
                    output={AchieveData}
                    dataColors='["--bs-primary","--bs-success", "--bs-success"]'
                  />
                </CardBody>
              </Card>
            </div>

            <div className="col-12">
              <div className="col-12">
                <SurgeryDoneChart zone_options={optionZone} />
                <ProductMix zone_options={optionZone} />
                <TargetAchieveChart zone_options={optionZone} />
                <SurgeonAddedDropped zone_options={optionZone} />
                <DealersPerformance zone_options={optionZone} />
                <EmployeeWiseData zone_options={optionZone} />
                {/* </div> */}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
