import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const navigate = useNavigate();
  const onHandleLogout = e => {
    e.preventDefault()
    localStorage.clear();
    navigate("/login")
  }


  const nameLogo = localStorage.getItem("authName").split(" ")
  const firstword = nameLogo.slice(0,1).toString()
  const firstLetter = nameLogo.slice(0,1).toString().slice(0, 1)
  let secondLetter;
  if (nameLogo.length > 1) {
    secondLetter = nameLogo.slice(-1).toString().slice(0, 1)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="bd-header-logo-img">{firstLetter}{secondLetter}</span>
          <span className=" d-xl-inline-block ms-2 me-1">{firstword}</span>
          <i className="mdi mdi-chevron-down  d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" >
            <Link to="/profile">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}
            </Link>
          </DropdownItem>
          <div className="dropdown-divider" />
          <a className="dropdown-item" onClick={onHandleLogout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
