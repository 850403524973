import axios from "axios"
import Loader from "pages/seperate/Loader"
import React, { useEffect, useMemo, useState } from "react"
import ReactApexChart from "react-apexcharts"
import Select from "react-select"
import { Button, Card, CardBody } from "reactstrap"
import swal from "sweetalert"

const TargetAchieveChart = ({ zone_options }) => {
  const [isLoading, setLoading] = useState(false)

  const [optionState, setOptionState] = useState([])
  const [product_option, setOptionProduct] = useState()

  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")
  const [zones, setZones] = useState([])
  const [states, setStates] = useState([])

  const [targetData, setTargetData] = useState([])

  useEffect(() => {
    fetch("https://api.orthotrack.in/api/productType")
      .then(res => res.json())
      .then(data => {
        const options = data.data.map(item => ({
          value: item.id,
          label: item.product_type,
        }))
        setOptionProduct(options)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const onZoneChange = async e => {
    if (e === null) {
      setOptionState([])
      setZones([])
      return
    } else {
      setZones(e)
    }

    const formData = new FormData()
    let zones_ids;
    if (e?.some(z => z.value == 0))
      zones_ids = zone_options?.map(z => z.value).toString()
    else
      zones_ids = e?.slice(-1)[0].value

    // if (e?.length != 0) {
    formData.append("zone_id", zones_ids)
    // }
    try {
      const response = await axios.post(
        "https://api.orthotrack.in/api/zone_state",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      const options = response.data.data.map(item => ({
        value: item.id,
        label: item.state_name,
      }))
      if (e.length != 0) {
        setOptionState([{ label: 'All States', value: 0 }, ...optionState.slice(1), ...options])
      }
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
    }
  }

  const getChartData = async e => {
    e.preventDefault()

    if (!zones || zones?.length === 0) {
      swal("", "Zones Field is Required!", "warning")
      return
    }
    if (!states || states?.length === 0) {
      swal("", "States Field is Required!", "warning")
      return
    }

    try {
      setLoading(true)
      const formData = new FormData()

      formData.append("start_date", start_date)
      formData.append("end_date", end_date)
      let zones_ids;
      if (zones?.some(z => z.value == 0))
        zones_ids = zone_options?.map(z => z.value).toString()
      else
        zones_ids = zones?.map(z => z.value).toString()

      formData.append("zones", zones_ids)

      let all_states_id;
      if (states?.some(z => z.value == 0)) {
        all_states_id = optionState.map(s => s.value).slice(1).toString()
      } else {
        all_states_id = states.map(s => s.value).toString()
      }

      formData.append("states", all_states_id)
      // formData.append("product_type", products.map((s) => s.value).toString())

      const response = await axios.post(
        "https://api.orthotrack.in/api/target_filter",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      // console.log(response.data.data)
      setTargetData(response.data.data)

      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
    }
  }

  // Chart Datas ======================
  const target_data = useMemo(
    () => ({
      series: [
        {
          name: "Target",
          data: targetData?.map(tar => tar.target_data),
        },
        {
          name: "Achievements",
          data: targetData?.map(tar => tar.achived_data),
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "45%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: targetData?.map(tar => tar.category_name),
        },
        yaxis: {
          title: {
            // text: '$ (thousands)'
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
          },
        },
        colors: [
          function ({ value, seriesIndex, dataPointIndex, w }) {
            if (seriesIndex == 1) {
              return "#e97132"
            } else {
              return "#156082"
            }
          },
        ],
      },
    }),
    [targetData]
  )

  // // Graph Second =====================
  // const add_dropped_data = useMemo(
  //   () => ({
  //     series: [
  //       {
  //         name: "Surgeons Added",
  //         data: targetData?.map(tar => tar.achived_data),
  //       },
  //       {
  //         name: "Surgeons Dropped",
  //         data: targetData?.map(tar => tar.achived_data - tar.target_data),
  //       },
  //     ],
  //     options: {
  //       chart: {
  //         type: "bar",
  //         height: 350,
  //       },
  //       plotOptions: {
  //         bar: {
  //           horizontal: false,
  //           columnWidth: "45%",
  //           endingShape: "rounded",
  //         },
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         show: true,
  //         width: 2,
  //         // colors: ['transparent']
  //       },
  //       xaxis: {
  //         categories: ["Knee", "Hip", "Bipolar", "Arthroscopy"],
  //       },
  //       yaxis: {
  //         title: {
  //           // text: '$ (thousands)'
  //         },
  //       },
  //       fill: {
  //         opacity: 1,
  //       },
  //       tooltip: {
  //         y: {
  //           formatter: function (val) {
  //             return val
  //           },
  //         },
  //       },
  //       colors: [
  //         function ({ value, seriesIndex, dataPointIndex, w }) {
  //           if (seriesIndex == 1) {
  //             return "#e97132"
  //           } else {
  //             return "#156082"
  //           }
  //         },
  //       ],
  //     },
  //   }),
  //   [targetData]
  // )

  // // Graph Third ====================
  // function DynamicPropertiesKeys(array, keyName) {
  //   return array?.length !== 0
  //     ? Object?.keys(array[0])?.filter(key => key !== keyName)
  //     : []
  // }

  // const productScoredArray = product_wise.map(item => ({
  //   ...item,
  //   totalScore: DynamicPropertiesKeys(product_wise, "partner_name").reduce(
  //     (acc, key) => acc + item[key],
  //     0
  //   ),
  // }))
  // // Sort the array based on the total score in descending order
  // productScoredArray.sort((a, b) => b.totalScore - a.totalScore)
  // // Get the top 3 elements
  // const top10 = productScoredArray.slice(0, 10)
  // const product_output = DynamicPropertiesKeys(
  //   product_wise,
  //   "partner_name"
  // ).map(name => {
  //   const values = top10.map(item => item[name])
  //   return { name, data: values }
  // })

  // const productmix_data = useMemo(
  //   () => ({
  //     series: product_output,
  //     options: {
  //       chart: {
  //         type: "bar",
  //         height: 350,
  //       },
  //       plotOptions: {
  //         bar: {
  //           horizontal: false,
  //           columnWidth: "50%",
  //           endingShape: "rounded",
  //         },
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         show: true,
  //         width: 2,
  //         colors: ["transparent"],
  //       },
  //       xaxis: {
  //         categories: top10?.map(obj => obj.partner_name),
  //       },
  //       yaxis: {
  //         title: {
  //           text: "Surgeries",
  //         },
  //       },
  //       fill: {
  //         opacity: 1,
  //       },
  //       tooltip: {
  //         y: {
  //           formatter: function (val) {
  //             return val + " Surgeries"
  //           },
  //         },
  //       },
  //     },
  //   }),
  //   [product_wise]
  // )

  // // Graph Fourth =====================
  // // Calculate the total score for each object
  // const scoredArray = emp_wise.map(item => ({
  //   ...item,
  //   totalScore: DynamicPropertiesKeys(emp_wise, "name").reduce(
  //     (acc, key) => acc + item[key],
  //     0
  //   ),
  // }))
  // // Sort the array based on the total score in descending order
  // scoredArray.sort((a, b) => b.totalScore - a.totalScore)
  // // Get the top 3 elements
  // const top5 = scoredArray.slice(0, 5)
  // const output = DynamicPropertiesKeys(emp_wise, "name").map(name => {
  //   const values = top5.map(item => item[name])
  //   return { name, data: values }
  // })

  // const emp_data = useMemo(
  //   () => ({
  //     series: output,
  //     options: {
  //       chart: {
  //         type: "bar",
  //         height: 350,
  //       },
  //       plotOptions: {
  //         bar: {
  //           horizontal: false,
  //           columnWidth: "50%",
  //           endingShape: "rounded",
  //         },
  //       },
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       stroke: {
  //         show: true,
  //         width: 2,
  //         colors: ["transparent"],
  //       },
  //       xaxis: {
  //         categories: top5?.map(obj => obj.name),
  //       },
  //       yaxis: {
  //         title: {
  //           text: "Surgerires",
  //         },
  //       },
  //       fill: {
  //         opacity: 1,
  //       },
  //       tooltip: {
  //         y: {
  //           formatter: function (val) {
  //             return val + " Surgerires"
  //           },
  //         },
  //       },
  //     },
  //   }),
  //   [emp_wise]
  // )


  return (
    <div>
      {isLoading && <Loader />}
      <Card>
        <CardBody>
          <div>
            <form onSubmit={getChartData}>
              <div className="d-flex justify-space-between">
                <h4 className="card-title mb-5">
                  Targets & Achievements
                </h4>
                <div className="text-end ">
                  <Button className="btn-theme-color" type="submit">
                    <i
                      className="fas fa-filter pr-10"
                      style={{ fontSize: "10px" }}
                    ></i>
                    Filter Now
                  </Button>

                  <Button
                    className="btn-theme-color bg-danger ms-3 text-white"
                    type="button"
                    onClick={() => {
                      setTargetData([])
                      setStartDate("")
                      setEndDate("")
                      setStates([])
                      setZones([])
                      setOptionState([])
                    }}
                  >
                    <i
                      className="fas fa-times pr-10"
                      style={{ fontSize: "10px" }}
                    ></i>
                    Clear
                  </Button>
                </div>
              </div>
              <div>
                <div className="row align-items-end mb-4 mt-2">
                  <div className="col-md-2 mb-3">
                    <label htmlFor="">From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={start_date}
                      onChange={e => setStartDate(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="">To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={end_date}
                      onChange={e => setEndDate(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="">Zone</label>
                    <Select
                      placeholder="Select Zone"
                      isMulti
                      options={[{ label: 'All Zones', value: 0 }, ...zone_options]}
                      value={zones}
                      onChange={onZoneChange}
                    />
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="">State</label>
                    <Select
                      placeholder="Select State"
                      isMulti
                      options={optionState}
                      value={states}
                      onChange={e => setStates(e)}
                    />
                  </div>

                  {/* <div className="col-md-4">
                                <label htmlFor="">Product Type</label>
                                <Select
                                    placeholder="Select Product"
                                    isMulti
                                    options={product_option}
                                    value={products}
                                    onChange={(e) => setProducts(e)}
                                />
                            </div> */}
                </div>
              </div>
            </form>
          </div>
        </CardBody>
      </Card>
      {targetData.length > 0 && (
        <>
          <Card>
            <CardBody>
              <div id="chart">
                <h5 className="fw-bold text-center ">
                  Targets & Achievements
                </h5>
                <ReactApexChart
                  options={target_data.options}
                  series={target_data.series}
                  type="bar"
                  height={350}
                />
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  )
}

export default TargetAchieveChart
