import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { Link, useNavigate } from "react-router-dom"

// Formik Validation

// action

// import images
import profile2 from "assets/images/small/login-doctor.png"
import mainLogo from "../../assets/images/company-logo-new (2).png"
import axios from "axios"
import swal from "sweetalert"
import Loader from "pages/seperate/Loader"

const Reset = () => {
  const [error2, setError2] = useState(false)
  const [password, setPassword] = useState("")
  const [Confirm_pass, setConfirmPass] = useState("")
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()

  const onHandleForget = async e => {
    e.preventDefault()
    if (Confirm_pass == password) {
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("user_id"))
      formData.append("password", password)
      try {
        const response = await axios({
          method: "post",
          url: "https://api.orthotrack.in/api/forget_password",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          swal({
            icon: "success",
            text: "Password Changed Succesfully",
          }).then(data => {
            navigate("/login")
          })
          localStorage.removeItem("user_id")
          localStorage.removeItem("otp")
          setLoading(false)
        })
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    } else {
      swal({
        icon: "error",
        text: "Please Enter Correct Confirm Password",
      })
    }
  }

  return (
    <div>
      <React.Fragment>
        {isLoading && (<Loader/>)}
        <div className="bd-overlay-img">
          <div className="account-pages pt-50 pt-sm-5 main-login-page">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row className="justify-space-between">
                        <Col xs={7}>
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Welcome Back !</h5>
                            <p>Reset Password</p>
                          </div>
                        </Col>
                        <Col className="col-3 align-self-end mb-10 text-right mr-30">
                          <img
                            src={profile2}
                            alt=""
                            className="img-fluid"
                            style={{ width: "90%" }}
                          />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div className="col-md-2">
                        <Link to="/">
                          <div className="avatar-md profile-user-wid mb-3">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={mainLogo}
                                alt=""
                                className="rounded-circle"
                                height="68"
                                width="68"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={onHandleForget}
                        >
                          {error2 ? (
                            <Alert color="danger">
                              Username and password are invalid. Please enter
                              correct username and password
                            </Alert>
                          ) : null}
                          <div className="mb-3">
                            <Label className="form-label">Reset Password</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter Reset Password"
                              type="text"
                              onChange={e => setPassword(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              Confirm Password
                            </Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter Confirm Password"
                              type="password"
                              onChange={e => setConfirmPass(e.target.value)}
                            />
                          </div>
                          <Row className="mb-3">
                            <Col className="text-end">
                              <button
                                className="btn btn-primary w-md "
                                type="submit"
                              >
                                Submit
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}

export default Reset
