import Breadcrumb from "components/Common/Breadcrumb"
import React, { useMemo, useState, useEffect } from "react"
import ViewStaffTable from "./viewStaffTable"
import { Container } from "reactstrap"

const index = () => {
  return (
    <div>
      <div className="page-content">
        <Container fluid className="custom-container">
          <Breadcrumb title={"Dashboard"} breadcrumbItem={"Staff"} />
          <div>
            <ViewStaffTable />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default index
