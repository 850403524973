import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import axios from "axios"
import Loader from "pages/seperate/Loader"

const AddRoles = () => {
  const allModule = [
    {
      module: "Surgery Track",
      id: "1",
      allModules: true
    },
    {
      module: "Masters",
      id: "2",
      allModules: true
    },
    {
      module: "Staff",
      id: "3",
      allModules: true
    },
    {
      module: "Reports",
      id: "4",
      allModules: true
    },
    {
      module: "Roles and Permissions",
      id: "5",
      allModules: true
    },
    {
      module: "Targets",
      id: "6",
      allModules: true
    },
    {
      module: "Activity Logs",
      id: "7",
      allModules: false
    }
  ]
  const [role_name, setRoleName] = useState("")
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [record, setRecord] = useState('')

  const onAddRoles = async e => {
    e.preventDefault()
    var x1 = document.querySelectorAll("#one")
    var x2 = document.querySelectorAll("#second")
    var x3 = document.querySelectorAll("#third")
    var x4 = document.querySelectorAll("#fourth")
    var role = document.querySelectorAll("#roleId")

    var module_id = []
    for (var i = 0; i < role.length; i++) {
      module_id.push(role[i].value)
    }

    var one = 0
    for (var i = 0; i < x1.length; i++) {
      if (x1[i].checked == true) {
        one = one + "1"
      } else {
        one = one + "0"
      }
    }
    var second = 0
    for (var i = 0; i < x2.length; i++) {
      if (x2[i].checked == true) {
        second = second + "1"
      } else {
        second = second + "0"
      }
    }

    var third = 0
    for (var i = 0; i < x3.length; i++) {
      if (x3[i].checked == true) {
        third = third + "1"
      } else {
        third = third + "0"
      }
    }

    var fourth = 0
    for (var i = 0; i < x4.length; i++) {
      if (x4[i].checked == true) {
        fourth = fourth + "1"
      } else {
        fourth = fourth + "0"
      }
    }

    var view = one.substring(1).split("").map(Number)
    var add = second.substring(1).split("").map(Number)
    var edit = third.substring(1).split("").map(Number)
    var delte = fourth.substring(1).split("").map(Number)

    add.push(0)
    edit.push(0)
    delte.push(0)

    setLoading(true)
    const formData = new FormData()
    formData.append("role_name", role_name)
    formData.append("module_id", module_id)
    formData.append("records", record)
    formData.append("view", view)
    formData.append("add", add)
    formData.append("edit", edit)
    formData.append("delete", delte)
    formData.append("user_id", localStorage.getItem("authid"))

    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/storeRoles",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Role Added Successfully",
        }).then(data => {
          navigate("/roles-and-permissions")
        })
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid className="custom-container">
          <Breadcrumb title={"Dashboard"} breadcrumbItem={"Staff Form"} />
          <div>
            <div>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 pl-15">
                        Add Roles and Permissions
                      </CardTitle>
                      <form onSubmit={onAddRoles}>
                        <Row className="">
                          <Col lg={4} className="pl-25">
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Add Roles
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Enter Role Name"
                                onChange={e => setRoleName(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg={4} className="pl-25">
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Display Records
                              </Label>
                              <select className="form-control" required onChange={(e) => setRecord(e.target.value)}>
                                <option value="">-- Please Select --</option>
                                <option value="1"> All Records</option>
                                <option value="2"> Records Added by Me and Staff - ZSM </option>
                                <option value="3"> Records Added by Me and Staff - RSM </option>
                                <option value="4"> Records Added by Me and Staff - ASM </option>
                                <option value="5"> Records Added by Me</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <thead>
                              <tr>
                                <th className="bd-text-center">Modules</th>
                                <th className="bd-text-center">View</th>
                                <th className="bd-text-center">Add </th>
                                <th className="bd-text-center"> Edit </th>
                                <th className="bd-text-center"> Delete </th>
                              </tr>
                            </thead>
                            <tbody>
                              {allModule.map((item, index) => {
                                return (
                                  <tr key={index} className=" bd-text-center">
                                    <th scope="row">
                                      <input
                                        id="roleId"
                                        value={item.id}
                                        className="display-none bd-text-center"
                                      />
                                      {item.module}
                                    </th>
                                    <td data-column="View">
                                      <div className="form-check form-switch form-switch-md">
                                        <input
                                          type="checkbox"
                                          className={`form-check-input ${item.id}`}
                                          id="one"
                                        />
                                      </div>
                                    </td>
                                    <td data-column="Add">
                                      {
                                        item.allModules ?
                                          <div className="form-check form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="second"
                                              value={item.id}
                                            // onChange={onChangeSecond}
                                            />
                                          </div> : <span className="ps-2">--</span>
                                      }
                                    </td>
                                    <td data-column="Edit">
                                      {
                                        item.allModules ?
                                          <div className="form-check form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="third"
                                            />
                                          </div> : <span className="ps-2">--</span>
                                      }
                                    </td>
                                    <td data-column="Delete">
                                      {
                                        item.allModules ?
                                          <div className="form-check form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="fourth"
                                            />
                                          </div> : <span className="ps-2">--</span>
                                      }
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                        <div className="mt-50">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default AddRoles
