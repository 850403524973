import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useMemo, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Table,
  Modal,
  Label,
  Input,
} from "reactstrap"
import Select from "react-select"
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import Loader from "pages/seperate/Loader"
import ReactSelect from "react-select"
import moment from "moment"
import ExportExcel from "./Export"
import swal from "sweetalert"

const index = () => {
  const [editBrands, setEditBrands] = useState(false)
  const [onShowProduct, setProductShow] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [track_id, setTrackId] = useState("")
  const [data, setdatas] = useState([])
  const [exportData, setExportData] = useState([])
  const [display_data, setDisplayData] = useState([])
  const [all_staff, setAllStaff] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    fetching()
    AllStaff()
    fetchingCategory()
  }, [])

  const [optionZone, setOptionZone] = useState([])
  const [option_category, setOptionCategory] = useState([])


  const fetching = async e => {
    setLoading(true)
    try {
      const response1 = axios({
        method: "get",
        url: "https://api.orthotrack.in/api/zones",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.zone_name,
        }))
        if (localStorage.getItem("record") == 1) {
          setOptionZone(options)
        } else {
          setOptionZone(
            options.filter(obj => obj.value == localStorage.getItem("auth_z"))
          )
          setAllZone(
            options.filter(obj => obj.value == localStorage.getItem("auth_z"))
          )

          const formData2 = new FormData()
          formData2.append("zone_id", localStorage.getItem("auth_z"))

          const response = await axios({
            method: "post",
            url: "https://api.orthotrack.in/api/zone_state",
            data: formData2,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            const options = response.data.data.map(item => ({
              value: item.id,
              label: item.state_name,
            }))
            setOptionState(options)
            setLoading(false)
          })
        }
        setLoading(false)
      })
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const fetchingCategory = async e => {
    setLoading(true)
    try {
      const response1 = axios({
        method: "get",
        url: "https://api.orthotrack.in/api/productType",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.product_type,
        }))

        setOptionCategory(options)
        setLoading(false)
      })
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const AllStaff = async e => {
    setLoading(true)
    const formData = new FormData()
    formData.append("id", localStorage.getItem("authid"))
    formData.append("record", localStorage.getItem("record"))
    try {
      const response = axios({
        method: "post",
        url: "https://api.orthotrack.in/api/csr_data",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.name,
          state: item.state,
        }))

        setStaff(options)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const [product_detail, setProductDetails] = useState([])
  const [sugery_id, setSurgeryId] = useState("")
  const [product_type, setProductType] = useState("")
  const [allProducts, setAllPoducts] = useState([])

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
      },
      {
        Header: "Surgery Date",
        accessor: "surgery_date",
        filterable: true,
        className: "bd-show-extra-field ",
        Cell: cellProps => {
          const date = moment(cellProps.row.original.surgery_date).format(
            "DD-MM-YYYY"
          )
          return <div>{date}</div>
        },
      },
      {
        Header: "Zone",
        accessor: "zone_name",
        filterable: true,
        className: "bd-show-extra-field Nav-dropdown-resp",
      },
      {
        Header: "State",
        accessor: "state_name",
        filterable: true,
        className: "bd-show-extra-field Nav-dropdown-resp",
      },
      {
        Header: "City ",
        accessor: "city_name",
        filterable: true,
        className: "Nav-dropdown-resp",
      },
      {
        Header: "Business Type",
        accessor: "btype",
        filterable: true,
        className: "bd-show-extra-field",
      },
      {
        Header: "Channel Partner",
        accessor: "channel_name",
        filterable: true,
        className: "bd-show-extra-field",
      },
      {
        Header: "Surgeon",
        accessor: "doctor_name",
        filterable: true,
      },
      {
        Header: "Hospital ",
        accessor: "hospital_name",
        filterable: true,
      },
      {
        Header: "Patient",
        accessor: "patient",
        filterable: true,
      },
      {
        Header: "CSR/ASC/TL",
        accessor: "csr_name",
        filterable: true,
        className: "bd-show-extra-field ",
      },
      {
        Header: "Product Type",
        accessor: "ptype",
        filterable: true,
        className: "bd-show-extra-field Nav-dropdown-resp",
      },
      {
        Header: "Action",
        filterable: true,
        className: "border-botttom-black",
        Cell: cellProps => {
          // const onViewProduts = () => {
          //   setProductShow(true)
          //   setSurgeryId(cellProps.row.original.id)
          //   setProductType(cellProps.row.original.ptype)
          //   setAllPoducts(cellProps.row.original.products)
          // }

          const onViewProduts = async () => {
            setSurgeryId(cellProps.row.original.id)
            setProductType(cellProps.row.original.ptype)
            setLoading(true)
            try {
              const response = await axios({
                method: "get",
                url: `https://api.orthotrack.in/api/surgery_details/${cellProps.row.values.id}`,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(response => {
                setAllPoducts(response.data)
                setProductShow(true)
                setLoading(false)
              })
            } catch (err) {
              setLoading(false)
              swal({
                icon: "error",
                text: "SomeThing Went Wrong",
              })
            }
          }

          return (
            <div className="col-3 bd-edit-resposnive-btn">
              <a
                className="btn btn-outline-secondary btn-sm edit"
                title="View"
                onClick={onViewProduts}
              >
                <i className="fas fa-eye"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  const TableView = allProducts.map((item, index) => {
    return (
      <tr key={index}>
        <td>{item.brand_name}</td>
        <td>{item.component_name}</td>
        <td>{item.size_name}</td>
      </tr>
    )
  })

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const [from_date, setFromDate] = useState("")
  const [to_date, setToDate] = useState("")
  const [show_filter, setShowFilter] = useState(false)

  const [exportLoading, setExportLoading] = useState(false)


  const onFilterData = async (api_url) => {
    if (from_date != "" && to_date != "") {
      setLoading(true)

      const zone = all_Zone?.map(obj => obj.value).join(",")
      const state = all_state?.map(obj => obj.value).join(",")
      const csr_asc = all_csr?.map(obj => obj.value).join(",")
      const type = all_type?.map(obj => obj.value).join(",")

      const formData = new FormData()
      formData.append("from", from_date)
      formData.append("to", to_date)
      formData.append("zone", zone)
      formData.append("state", state)
      formData.append("csr", csr_asc)
      formData.append("product_type", type)
      formData.append("records", localStorage.getItem("record"))
      formData.append("user_id", localStorage.getItem("authid"))
      try {
        const response = await axios({
          method: "post",
          url: "https://api.orthotrack.in/api/surgery_filter_data",
          // url: api_url,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })

        // console.log('filter ',response.data.data)

        setdatas(response.data.data)
        setLoading(false)

        // for export data
        setExportLoading(true)
        const response2 = await axios.post("https://api.orthotrack.in/api/export_surgery_filter_data", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          }
        })
        setExportData(response2.data.data)
        // console.log('export data :', response2.data.data)
        setExportLoading(false)
        if (response2.data.data.length > 0) {
          // swal("", "Export Data is Ready to download", "success")
        }

      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      swal("", "From & To Date are mandatory Fields!", "warning")
    }
  }

  const [transformedArray, setTransformedArray] = useState([])

  useEffect(() => {
    const temp = []
    exportData?.forEach(item => {
      item.products.forEach(product => {
        temp.push({
          ["Surgery Id"]: item.id,
          ["Surgery Month-Year"]: moment(item.surgery_date).format("MMM-YYYY"),
          ["Date of Surgery"]: item.surgery_date,
          ["Business Type"]: item.btype,
          ["Dealer Name"]: item.channel_name,
          ["Name of Surgeon"]: item.doctor_name,
          ["Hospital Name"]: item.hospital_name,
          Category: item.ptype,
          ["Product Name"]: product.brand_name,
          Component: product.component_name,
          Size: product.size_name,
          ["Employee Id"]: item.csr_emp,
          ["CSR/ASC/DSR"]: item.csr_name,
          Zone: item.zone_name,
          State: item.state_name,
          City: item.city_name,
        })
      })
    })
    setTransformedArray(temp)
  }, [exportData])


  const [optionState, setOptionState] = useState([])

  const [all_Zone, setAllZone] = useState([])
  const [all_state, setAllState] = useState([])
  const [all_csr, setAllCsr] = useState([])
  const [all_type, setAllType] = useState([])
  const [all_surgeon, setAllSurgeon] = useState([])

  const onZoneChange = async e => {
    setLoading(true)
    setAllZone(e)
    if (e?.length == 0) {
      setAllState([])
      setOptionState([])
      setStaff([])
      setAllCsr([])
    }
    const formData = new FormData()
    if (e?.length != 0) {
      formData.append("zone_id", e?.slice(-1)[0].value)
    }
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/zone_state",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.state_name,
        }))
        if (e.length != 0) {
          setOptionState([...optionState, ...options])
        }
        setLoading(false)
      })
    } catch (err) {
      setLoading(false)
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
    }
  }

  const [staff, setStaff] = useState([])
  const onStateChange = e => {
    setAllState(e)
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid className="custom-container">
          <Breadcrumb title={"Dashboard"} breadcrumbItem={"Surgery Track"} />
          <div>
            <div>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div className="bd-responsive-filter">
                        <div>
                          <CardTitle className="h4 text-black">
                            Surgery Track Report
                          </CardTitle>
                          <p className="card-title-desc">
                            Listing View of Filtered Surgery Track Report
                          </p>
                        </div>
                        <div className="mb-30 mt-20">
                          <Row className="align-items-end">
                            <Col lg={3}>
                              <Label htmlFor="formrow-firstname-Input">
                                From
                              </Label>
                              <input
                                type="date"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Enter Zone Name"
                                required
                                onChange={e => setFromDate(e.target.value)}
                              />
                            </Col>
                            <Col lg={3}>
                              <Label htmlFor="formrow-firstname-Input">
                                To
                              </Label>
                              <input
                                type="date"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Enter Zone Name"
                                onChange={e => setToDate(e.target.value)}
                              />
                            </Col>
                            <Col
                              lg={6}
                              className="d-flex text-right justify-content-end  full-width"
                            >
                              {show_filter == false ? (
                                <Button
                                  className="btn-theme-color "
                                  onClick={() => onFilterData()}
                                >
                                  <i
                                    className="fas fa-search pr-10"
                                    style={{ fontSize: "10px" }}
                                  ></i>
                                  Search
                                </Button>
                              ) : null}

                              <Button
                                className="btn-theme-color ml-10"
                                onClick={() => {
                                  if (show_filter == false) {
                                    setShowFilter(true)
                                  } else {
                                    setShowFilter(false)
                                  }
                                }}
                              >
                                <i
                                  className="fas fa-filter pr-10"
                                  style={{ fontSize: "10px" }}
                                ></i>
                                Filter {show_filter == false ? "More" : "Less"}
                              </Button>
                              {
                                data?.length > 0 ?

                                  (
                                    !exportLoading ?
                                      <ExportExcel
                                        data={transformedArray}
                                        marginTop={"mt-08"}
                                        name={"Orthotrack Report.xlsx"}
                                      /> :
                                      <a
                                        className={"btn-theme-color  ml-10 "}
                                        style={{ borderRadius: "4px" }}
                                        onClick={() => swal('Please wait! we are getting ready exporting data.')}
                                      >
                                        <i className="fas fa-download bx-tada pr-10" style={{ fontSize: "10px" }}></i>
                                        Downloading...
                                      </a>
                                  )
                                  :
                                  null
                              }
                            </Col>
                          </Row>
                        </div>
                      </div>

                      {show_filter ? (
                        <div className="mb-30">
                          <Row className="align-items-end">
                            <Col lg={12}>
                              <Row>
                                <Col
                                  lg={3}
                                  className={
                                    localStorage.getItem("record") == 1 ||
                                      localStorage.getItem("record") == 2
                                      ? "mb-2"
                                      : "d-none"
                                  }
                                >
                                  <Label htmlFor="formrow-firstname-Input">
                                    Zone
                                  </Label>
                                  <Select
                                    placeholder="Select Zone"
                                    isMulti
                                    options={optionZone}
                                    value={all_Zone}
                                    onChange={onZoneChange}
                                  />
                                </Col>
                                <Col
                                  lg={3}
                                  className={
                                    localStorage.getItem("record") == 1 ||
                                      localStorage.getItem("record") == 2 ||
                                      localStorage.getItem("record") == 3
                                      ? "mb-2"
                                      : "d-none"
                                  }
                                >
                                  <Label htmlFor="formrow-firstname-Input">
                                    State
                                  </Label>
                                  <Select
                                    placeholder="Select State"
                                    isMulti
                                    options={optionState}
                                    value={all_state}
                                    onChange={onStateChange}
                                  />
                                </Col>
                                <Col
                                  lg={3}
                                  className={
                                    localStorage.getItem("record") == 1 ||
                                      localStorage.getItem("record") == 2 ||
                                      localStorage.getItem("record") == 3 ||
                                      localStorage.getItem("record") == 4
                                      ? "mb-2"
                                      : "d-none"
                                  }
                                >
                                  <Label htmlFor="formrow-firstname-Input">
                                    CSR/ASC/TL
                                  </Label>
                                  <Select
                                    type="date"
                                    options={staff}
                                    isMulti
                                    placeholder="Select CSR/ASC/TL"
                                    value={all_csr}
                                    onChange={e => setAllCsr(e)}
                                  />
                                </Col>
                                <Col
                                  lg={3}
                                  className={
                                    localStorage.getItem("record") == 1 ||
                                      localStorage.getItem("record") == 2 ||
                                      localStorage.getItem("record") == 3 ||
                                      localStorage.getItem("record") == 4
                                      ? "mb-2"
                                      : "d-none"
                                  }
                                >
                                  <Label htmlFor="formrow-firstname-Input">
                                    Category
                                  </Label>
                                  <Select
                                    type="date"
                                    options={option_category}
                                    isMulti
                                    placeholder="Select Category"
                                    value={all_type}
                                    onChange={e => setAllType(e)}
                                  />
                                </Col>
                              </Row>
                              {/* <Col
                                lg={4}
                                className={
                                  localStorage.getItem("record") == 1 ||
                                  localStorage.getItem("record") == 2 ||
                                  localStorage.getItem("record") == 3 ||
                                  localStorage.getItem("record") == 4
                                    ? "mb-2"
                                    : "d-none"
                                }
                              >
                                <Label htmlFor="formrow-firstname-Input">
                                  Surgeons
                                </Label>
                                <Select
                                  type="date"
                                  // options={option_category}
                                  isMulti
                                  placeholder="Select Category"
                                  value={all_surgeon}
                                  onChange={e => setAllSurgeon(e)}
                                />
                              </Col> */}
                            </Col>

                            <Col lg={12} className="text-end mt-2" >
                              <Button
                                className="btn-theme-color me-2"
                                onClick={() => onFilterData()}
                              >
                                <i
                                  className="fas fa-search pr-10"
                                  style={{ fontSize: "10px" }}
                                ></i>
                                Search
                              </Button>

                              <Button
                                className="btn-theme-color"
                                onClick={() => {
                                  setAllZone([])
                                  setAllState([])
                                  setAllCsr([])
                                  setAllType([])
                                  setFromDate("")
                                  setToDate("")
                                }}
                              >
                                <i
                                  className="fas fa-times pr-10"
                                  style={{ fontSize: "10px" }}
                                ></i>
                                Clear
                              </Button>
                            </Col>

                          </Row>
                        </div>
                      ) : null}

                      <div className="">
                        <div className="table-responsive react-table ">
                          <Table
                            {...getTableProps()}
                            className="table-striped responsive-main-table"
                          >
                            <thead className="">
                              {headerGroups.map(headerGroup => (
                                <tr
                                  key={headerGroup.id}
                                  {...headerGroup.getHeaderGroupProps()}
                                >
                                  {headerGroup.headers.map(column => (
                                    <th
                                      key={column.id}
                                      className={column.className}
                                    >
                                      <div {...column.getSortByToggleProps()}>
                                        {column.render("Header")}
                                        {generateSortingIndicator(column)}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                              {page.map(row => {
                                prepareRow(row)
                                return (
                                  <tr key={row.id} {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                      <td
                                        key={cell.id}
                                        {...cell.getCellProps()}
                                        data-column={cell.column.Header}
                                        className={cell.column.className}
                                      >
                                        {cell.render("Cell")}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })}
                              {data?.length == 0 ? (
                                <td colSpan="9" className="p-2 text-center">
                                  No data Available
                                </td>
                              ) : null}
                            </tbody>
                          </Table>
                          {/* </div> */}
                        </div>
                        <div>
                          <Row className="justify-content-md-end justify-content-center align-items-center">
                            <Col className="col-md-auto">
                              <div className="d-flex gap-1">
                                <Button
                                  color="primary"
                                  onClick={() => gotoPage(0)}
                                  disabled={!canPreviousPage}
                                >
                                  {"<<"}
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={previousPage}
                                  disabled={!canPreviousPage}
                                >
                                  {"<"}
                                </Button>
                              </div>
                            </Col>
                            <Col className="col-md-auto d-md-block">
                              <span className="d-mobile-none"> Page </span>
                              <strong>
                                {pageIndex + 1} of {pageOptions.length}
                              </strong>
                            </Col>

                            <Col className="col-md-auto">
                              <div className="d-flex gap-1">
                                <Button
                                  color="primary"
                                  onClick={nextPage}
                                  disabled={!canNextPage}
                                >
                                  {">"}
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => gotoPage(pageCount - 1)}
                                  disabled={!canNextPage}
                                >
                                  {">>"}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>

        <Modal
          isOpen={onShowProduct}
          size="sm"
          className="bd-show-product"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Product Details</h5>
            <button
              type="button"
              onClick={() => {
                setProductShow(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <div className="mb-3 col-md-4">
                <Label htmlFor="formrow-firstname-Input">Surgeon ID</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Name"
                  value={sugery_id}
                  disabled
                />
              </div>
              <div className="mb-3 col-md-4">
                <Label htmlFor="formrow-firstname-Input">Product Type</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Name"
                  value={product_type}
                  disabled
                />
              </div>
            </Row>

            <div className="mt-20">
              <div className="mb-15">
                <h5>Added Product Details</h5>
              </div>
              <div className="table-responsive fixed-table-header">
                <table className="table mb-0 table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th style={{ width: "17%" }}>Product Name</th>
                      <th>Component</th>
                      <th>Size</th>
                    </tr>
                  </thead>
                  <tbody>{TableView}</tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default index
