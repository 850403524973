import Breadcrumb from "components/Common/Breadcrumb"
import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  Row,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import axios from "axios"
import classnames from "classnames"
import ZoneTable from "./ZoneTable"
import StateTable from "./StateTable"
import CityName from "./CityName"
import HospitalTable from "./HospitalTable"
import DoctorTable from "./DoctorTable"
import BrandsTable from "./BrandsTable"
import ComponentsTable from "./ComponentTable"
import VariantTable from "./VariantTable"
import SizeTable from "./SizeTable"
import NeeSizeTable from "./NeeSizeTable"
import ProductTypeTable from "./ProductTypeTable"
import BusinessTypeTable from "./BusinessTypeTable"
import ChannelPartnerTable from "./ChannelTable"

const index = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
    document.getElementById("mySidenav-master").style.width = "0"
  }

  const toggleLeftCanvas = () => {
    document.getElementById("mySidenav-master").style.width = "215px"
  }
  const toggleLeftCanvasClose = () => {
    document.getElementById("mySidenav-master").style.width = "0"
  }

  const [edit_role, setRolesEdit] = useState("")
  const [add_role, setRolesAdd] = useState("")
  const [delete_role, setRolesDelete] = useState("")
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const id = localStorage.getItem("authrole_id")
    const fetch = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `https://api.orthotrack.in/api/roles/${id}`
        )
        const allRoles = data.data.permission.map(item => {
          if (item.module_id == 2 && item.inserts == 1) {
            setRolesAdd("1")
          }
          if (item.module_id == 2 && item.edit == 1) {
            setRolesEdit("1")
          }
          if (item.module_id == 2 && item.destroy == 1) {
            setRolesDelete("1")
          }
        })
        setLoading(false)
      } catch (err) {
        console.error(err)
        setLoading(false)
      }
    }
    fetch()
  }, [])

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid className="custom-container">
            <Breadcrumb title={"Dashboard"} breadcrumbItem={"Master"} />
            <div>
              <div>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col md="3" className="bd-custom-side-nav">
                            <Nav pills className="flex-column nav-responsive">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "1",
                                  })}
                                  onClick={() => {
                                    toggleVertical("1")
                                  }}
                                >
                                  Zone
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "2",
                                  })}
                                  onClick={() => {
                                    toggleVertical("2")
                                  }}
                                >
                                  States
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "3",
                                  })}
                                  onClick={() => {
                                    toggleVertical("3")
                                  }}
                                >
                                  City
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "5",
                                  })}
                                  onClick={() => {
                                    toggleVertical("5")
                                  }}
                                >
                                  Surgeon Master
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "4",
                                  })}
                                  onClick={() => {
                                    toggleVertical("4")
                                  }}
                                >
                                  Hospital Master
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "13",
                                  })}
                                  onClick={() => {
                                    toggleVertical("13")
                                  }}
                                >
                                  Business Type Master
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "14",
                                  })}
                                  onClick={() => {
                                    toggleVertical("14")
                                  }}
                                >
                                  Channel Partner
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "12",
                                  })}
                                  onClick={() => {
                                    toggleVertical("12")
                                  }}
                                >
                                  Product Category
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "6",
                                  })}
                                  onClick={() => {
                                    toggleVertical("6")
                                  }}
                                >
                                  Products
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "7",
                                  })}
                                  onClick={() => {
                                    toggleVertical("7")
                                  }}
                                >
                                  Components
                                </NavLink>
                              </NavItem>
                              {/* <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "8",
                                  })}
                                  onClick={() => {
                                    toggleVertical("8")
                                  }}
                                >
                                  Variants
                                </NavLink>
                              </NavItem> */}
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    active: verticalActiveTab === "9",
                                  })}
                                  onClick={() => {
                                    toggleVertical("9")
                                  }}
                                >
                                  Size
                                </NavLink>
                              </NavItem>
                            </Nav>

                            <div className="Nav-dropdown-resp">
                              <div className="text-right bd-side-nav-master">
                                <a onClick={toggleLeftCanvas}>
                                  <i className="fas fa-bars "></i>
                                </a>
                              </div>
                              <div
                                className="sidebar-nav-master"
                                id="mySidenav-master"
                              >
                                <div className="bd-whole-navber">
                                  <div className="col-12 mb-10 text-right ">
                                    <a onClick={toggleLeftCanvasClose}>
                                      <i className="fas fa-times"></i>
                                    </a>
                                  </div>
                                  <div>
                                    <Nav pills className="flex-column ">
                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "1",
                                          })}
                                          onClick={() => {
                                            toggleVertical("1")
                                          }}
                                        >
                                          Zone
                                        </NavLink>
                                      </NavItem>
                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: verticalActiveTab === "2",
                                          })}
                                          onClick={() => {
                                            toggleVertical("2")
                                          }}
                                        >
                                          States
                                        </NavLink>
                                      </NavItem>
                                      <NavItem>
                                        <NavLink
                                          style={{
                                            cursor: "pointer",
                                            margin: "0px",
                                          }}
                                          className={classnames({
                                            active: verticalActiveTab === "3",
                                          })}
                                          onClick={() => {
                                            toggleVertical("3")
                                          }}
                                        >
                                          City
                                        </NavLink>
                                      </NavItem>

                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: verticalActiveTab === "5",
                                          })}
                                          onClick={() => {
                                            toggleVertical("5")
                                          }}
                                        >
                                          Surgeon Master
                                        </NavLink>
                                      </NavItem>

                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: verticalActiveTab === "4",
                                          })}
                                          onClick={() => {
                                            toggleVertical("4")
                                          }}
                                        >
                                          Hospital Master
                                        </NavLink>
                                      </NavItem>

                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: verticalActiveTab === "13",
                                          })}
                                          onClick={() => {
                                            toggleVertical("13")
                                          }}
                                        >
                                          Business Type Master
                                        </NavLink>
                                      </NavItem>

                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: verticalActiveTab === "14",
                                          })}
                                          onClick={() => {
                                            toggleVertical("14")
                                          }}
                                        >
                                          Channel Partner
                                        </NavLink>
                                      </NavItem>

                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: verticalActiveTab === "12",
                                          })}
                                          onClick={() => {
                                            toggleVertical("12")
                                          }}
                                        >
                                          Product Category
                                        </NavLink>
                                      </NavItem>

                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: verticalActiveTab === "6",
                                          })}
                                          onClick={() => {
                                            toggleVertical("6")
                                          }}
                                        >
                                          Products
                                        </NavLink>
                                      </NavItem>
                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: verticalActiveTab === "7",
                                          })}
                                          onClick={() => {
                                            toggleVertical("7")
                                          }}
                                        >
                                          Component Master
                                        </NavLink>
                                      </NavItem>
                                      {/* <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: verticalActiveTab === "8",
                                          })}
                                          onClick={() => {
                                            toggleVertical("8")
                                          }}
                                        >
                                          Variants
                                        </NavLink>
                                      </NavItem> */}
                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: verticalActiveTab === "9",
                                          })}
                                          onClick={() => {
                                            toggleVertical("9")
                                          }}
                                        >
                                          Size Master
                                        </NavLink>
                                      </NavItem>
                                      {/* <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: verticalActiveTab === "10",
                                          })}
                                          onClick={() => {
                                            toggleVertical("10")
                                          }}
                                        >
                                          KNEE SIZE
                                        </NavLink>
                                      </NavItem> */}
                                    </Nav>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col md="9" className="bd-custom-tab-content">
                            <TabContent
                              activeTab={verticalActiveTab}
                              className="text-muted mt-4 mt-md-0"
                            >
                              <TabPane tabId="1">
                                <ZoneTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>
                              <TabPane tabId="2">
                                <StateTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>
                              <TabPane tabId="3">
                                <CityName
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>

                              <TabPane tabId="4">
                                <HospitalTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>

                              <TabPane tabId="5">
                                <DoctorTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>
                              <TabPane tabId="6">
                                <BrandsTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>
                              <TabPane tabId="7">
                                <ComponentsTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>
                              <TabPane tabId="8">
                                <VariantTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>

                              <TabPane tabId="9">
                                <SizeTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>

                              <TabPane tabId="10">
                                <NeeSizeTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>
                              <TabPane tabId="12">
                                <ProductTypeTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>
                              <TabPane tabId="13">
                                <BusinessTypeTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>

                              <TabPane tabId="14">
                                <ChannelPartnerTable
                                  edit_role={edit_role}
                                  add_role={add_role}
                                  delete_role={delete_role}
                                />
                              </TabPane>
                            </TabContent>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default index
