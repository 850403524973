import React, { useMemo, useState, useEffect } from "react"
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import axios from "axios"
import Select from "react-select"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Form,
  Label,
  CardTitle,
} from "reactstrap"
import GlobalFilter from "pages/seperate/GlobalFilter"
import Loader from "pages/seperate/Loader"
import ExportExcel from "pages/Reports/Export"
import swal from "sweetalert"

const BrandsTable = ({ edit_role, add_role, delete_role }) => {
  // Adding Form
  const [brand_name, setBrands] = useState("")
  const [brand_id, setBrandId] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [data, setdatas] = useState([])
  const [product_option, setOptionProduct] = useState([])
  const [type_id, setProductTypeId] = useState("")
  const [product_type, setProductType] = useState("")
  const [exportData, setExportData] = useState([])

  // get Exporting Data
  useEffect(() => {
    try {
      const response = axios({
        method: "get",
        url: "https://api.orthotrack.in/api/product_export",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        if (response.data.length != 0) {
          setExportData(response.data)
        }
      })
    } catch (error) { console.log(error) }
  }, [])

  useEffect(() => {
    setLoading(true)
    try {
      const response = axios({
        method: "get",
        url: "https://api.orthotrack.in/api/brands",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        if (response.data.data.length != 0) {
          setLoading(false)
          setdatas(response.data.data)
        }
      })
    } catch (error) {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetch("https://api.orthotrack.in/api/productType")
      .then(res => res.json())
      .then(data => {
        const options1 = data.data.map(item => ({
          value: item.id,
          label: item.product_type,
        }))
        setOptionProduct(options1)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const onAddBrands = async e => {
    e.preventDefault()
    if (type_id?.length === 0) {
      swal('', 'Product Category is Required!', 'warning')
      return;
    }
    setLoading(true)
    const formData = new FormData()
    formData.append("brand_name", brand_name)
    formData.append("product_type", type_id)
    formData.append("user_id", localStorage.getItem("authid"))

    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/storeBrands",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Product Added Successfully",
        }).then(data => {
          setmodal_center(false)
        })
        setdatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const onEditBrands = async e => {
    e.preventDefault()
    if (type_id?.length === 0) {
      swal('', 'Product Category is Required!', 'warning')
      return;
    }
    setLoading(true)
    const formData = new FormData()
    formData.append("brand_name", brand_name)
    formData.append("product_type", type_id)
    formData.append("brand_id", brand_id)
    formData.append("user_id", localStorage.getItem("authid"))

    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/updateBrands",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Product Edited Successfully",
        }).then(data => {
          setEditBrands(false)
        })
        setdatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        className: "id-width",
      },
      {
        Header: "Product Category",
        accessor: "type",
        filterable: true,
      },
      {
        Header: "Product Name",
        accessor: "brand_name",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          const onStatusChange = async e => {
            let status
            if (e.target.checked == true) {
              status = 1
            } else {
              status = 0
            }
            swal({
              title: "Are you sure?",
              text: "You want to Change the Status",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                e.preventDefault()
                setLoading(true)
                const formData = new FormData()
                formData.append("brand_id", cellProps.row.original.id)
                formData.append("status", status)
                formData.append("user_id", localStorage.getItem("authid"))
                try {
                  const response = await axios({
                    method: "post",
                    url: "https://api.orthotrack.in/api/brand_status",
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Status Changed Successfully",
                    }).then(data => {
                      setdatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              } else {
                const checkbox = document.getElementById(
                  "product" + cellProps.row.original.id
                )
                if (checkbox.checked == false) {
                  checkbox.checked = true
                } else if (checkbox.checked == true) {
                  checkbox.checked = false
                }
              }
            })
          }

          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                id={"product" + cellProps.row.original.id}
                defaultChecked={cellProps.row.original.status == 1}
                onChange={onStatusChange}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        filterable: true,
        className: "action-width border-botttom-black",
        Cell: cellProps => {
          let Edit
          let Delete
          if (edit_role == "1") {
            Edit = (
              <div className="bio-edit-btn">
                <a
                  className="btn btn-outline-secondary btn-sm edit "
                  title="Edit"
                  onClick={() => {
                    setEditBrands(true)
                    setBrandId(cellProps.row.values.id)
                    setBrands(cellProps.row.values.brand_name)
                    setProductType(cellProps.row.values.type)
                    setProductTypeId(cellProps.row.original.product_type)
                  }}
                >
                  <i className="fas fa-pencil-alt"></i>
                </a>
              </div>
            )
          }

          if (delete_role == "1") {
            Delete = (
              <div className="bio-edit-btn">
                <a
                  className="btn btn-danger btn-sm edit"
                  onClick={async e => {
                    swal({
                      title: "Are you sure?",
                      text: "You want to Delete this Product!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async willDelete => {
                      if (willDelete) {
                        e.preventDefault()
                        setLoading(true)
                        const formData = new FormData()
                        formData.append("brand_id", cellProps.row.values.id)
                        formData.append("user_id", localStorage.getItem("authid"))

                        try {
                          const response = await axios({
                            method: "post",
                            url: "https://api.orthotrack.in/api/deleteBrands",
                            data: formData,
                            headers: {
                              Accept: "application/json",
                              "Content-Type": "multipart/form-data",
                            },
                          }).then(response => {
                            swal({
                              icon: "success",
                              text: "Product Deleted Successfully",
                            }).then(data => {
                              setmodal_center(false)
                              setdatas(response.data.data)
                            })
                            setLoading(false)
                          })
                        } catch (err) {
                          swal({
                            icon: "error",
                            text: "SomeThing Went Wrong",
                          })
                          setLoading(false)
                        }
                      }
                    })
                  }}
                >
                  <i className="fas fa-trash-alt"></i>
                </a>
              </div>
            )
          }

          return (
            <>
              <div className="row ">
                {Edit}
                {Delete}
              </div>
            </>
          )
        },
      },
    ],
    [edit_role, delete_role]
  )

  const [modal_center, setmodal_center] = useState(false)
  const [editBrands, setEditBrands] = useState(false)
  function tog_center() {
    setmodal_center(!modal_center)
  }

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px", color: "#00000040" }}
          ></i>
        </>
      )
    ) : (
      <>
        <i
          className="fas fa-long-arrow-alt-down"
          style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
        ></i>
        <i
          className="fas fa-long-arrow-alt-up "
          style={{ fontSize: "11px", color: "#00000040" }}
        ></i>
      </>
    )
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }
  const [open, setOpen] = useState(false)

  const toggleLeftCanvas = () => {
    setOpen(!open)
  }

  // Logic for exporting table data
  const transformedArray = []
  exportData.forEach((item, i) => {
    transformedArray.push({
      ["ID"]: item.id,
      ["Product Category"]: item.product_type,
      ["Product"]: item.brand_name
    })
  })

  let Add1
  let Add2
  if (add_role == "1") {
    Add1 = (
      <Col md="2" className="w-50 d-flex justify-content-end bd-responsive-btn text-right">
        <Button
          className="btn-theme-color"
          onClick={() => {
            tog_center()
          }}
        >
          + Add Product
        </Button>
        <ExportExcel
          data={transformedArray}
          marginTop={"mt-08"}
          name={"Products.xlsx"}
        />
      </Col>
    )
    Add2 = (
      <div className="col-10">
        <Button
          className="btn-theme-color"
          onClick={() => {
            tog_center()
          }}
        >
          + Add Product
        </Button>
      </div>
    )
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="bd-side-header">
        <CardTitle className="h4 text-black">Product</CardTitle>
        <p className="card-title-desc">Listing View of Products</p>
      </div>

      <div className="bd-responsive-filter">
        <Row className="mb-2 align-item-center">
          <Col md="2">
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {Add1}
        </Row>
      </div>

      {/* Responsive Top section */}

      <div className="bd-responsive-filter2 pb-20">
        <div>
          <div className="display-flex align-item-center">
            {Add2}
            <div className="col-2 text-right">
              <a
                className="btn btn-outline-secondary bd-custom-sidebar"
                onClick={toggleLeftCanvas}
              >
                <i className="fas fa-filter "></i>
              </a>
            </div>
          </div>
        </div>
        {open && (
          <div className="filter-sidebar" id="mySidenav">
            <div className=" display-flex">
              <div className="col-9">Apply Filter</div>
              <div className="col-3 text-right">
                <a className="" onClick={toggleLeftCanvas}>
                  <i className="fas fa-times"></i>
                </a>
              </div>
            </div>
            <div className="pt-20">
              <Row className="mb-2 align-item-center">
                <Col md="2">
                  <select
                    className="form-select"
                    value={pageSize}
                    onChange={onChangeInSelect}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </Row>
            </div>
          </div>
        )}
      </div>

      <div className="table-responsive react-table">
        <Table {...getTableProps()} className="table-striped">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    {...column.getSortByToggleProps()}
                    className={column.className}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                      data-column={cell.column.Header}
                      className={cell.column.className}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      <div>
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto  d-md-block">
            <span className="d-mobile-none"> Page </span>
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {/* Add Brands Modal */}
      <Modal isOpen={modal_center} size="sm" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Product</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={onAddBrands}>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">Product Category</Label>
              <Select
                onChange={e => setProductTypeId(e.value)}
                options={product_option}
                className="select2-selection"
              />
            </div>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">Product Name</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Product Name"
                onChange={e => setBrands(e.target.value)}
                required
              />
            </div>
            <div>
              <Button className="btn btn-primary">Submit</Button>
            </div>
          </form>
        </div>
      </Modal>

      {/* Edit Brands Modal */}
      <Modal isOpen={editBrands} size="sm" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Product</h5>
          <button
            type="button"
            onClick={() => {
              setEditBrands(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onEditBrands}>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">Product Category</Label>
              <Select
                onChange={e => setProductTypeId(e.value)}
                options={product_option}
                defaultValue={{ label: product_type }}
                className="select2-selection"
              />
            </div>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">Product Name</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Product Name"
                defaultValue={brand_name}
                onChange={e => setBrands(e.target.value)}
                required
              />
            </div>
            <div>
              <Button className="btn btn-primary">Submit</Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default BrandsTable
