import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"
import axios from "axios"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import Loader from "pages/seperate/Loader"

const UserProfile = () => {
  //meta title
  document.title = "Profile | Biorad Medisys - React Admin & Dashboard Template"


  const nameLogo = localStorage.getItem("authName").split(" ")

  const firstLetter = nameLogo.toString().slice(0, 1)

  let secondLetter
  if (nameLogo.length > 1) {
    secondLetter = nameLogo.slice(-1).toString().slice(0, 1)
  }
  const [City, setCityName] = useState(false)
  const [state, setstate] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [role_name, setRole] = useState(false)
  const [emply_id , setEmplId] = useState('')
  const [mobile , setMobile] = useState('')
  const [email , setEmail] = useState('')
  const [manager , setManager] = useState('')

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `https://api.orthotrack.in/api/staff/${localStorage.getItem(
            "authid"
          )}`
        )

        const allData = data.data.map((item, index) => {
          setCityName(item.city)
          setstate(item.state_name)
          setRole(item.role_name)
          setEmplId(item.emp_id)
          setMobile(item.mobile)
          setEmail(item.email)
          setManager(item.manager)
        })

        setLoading(false)
      } catch (err) {
        console.error(err)
      }
    }
    fetch()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Biorad Medisys" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">

              <Card>
                <CardBody>
                  {/* <div className="mb-20 ">
                    <div className="ms-3 text-center mr-10">
                      <span className="bd-text-logo-profile">
                        {firstLetter}
                        {secondLetter}
                      </span>
                    </div>
                  </div> */}
                  <div className="d-flex mt-20">
                  <div className="ms-3 text-center mr-10">
                      <span className="bd-text-logo-profile">
                        {firstLetter}
                        {secondLetter}
                      </span>
                    </div>
                    <div className="ml-20  pr-65" style={{borderRight:"1px solid grey"}} >
                      <div className="text-muted">
                        <p className="font-15">
                          <span className="bd-label-profile">
                            Employee ID :{" "}
                          </span>
                          {emply_id}
                        </p>
                        <p className="font-15">
                          <span className="bd-label-profile">Name : </span>
                          {localStorage.getItem("authName")}
                        </p>
                        <p className="font-15">
                          <span className="bd-label-profile">
                            Contact No. :{" "}
                          </span>
                          {mobile}
                        </p>
                        <p className="font-15">
                          <span className="bd-label-profile">Email : </span>{" "}
                          {email}
                        </p>
                      </div>
                    </div>

                    <div className=" ml-65">
                      <div className="text-muted">
                        <p className="font-15">
                          <span className="bd-label-profile">
                            Designation :{" "}
                          </span>{" "}
                          {role_name}
                        </p>
                        <p className="font-15">
                          <span className="bd-label-profile">
                            Reporting Manager :{" "}
                          </span>{" "}
                          {manager}
                        </p>
                        <p className="font-15">
                          <span className="bd-label-profile">State : </span>{" "}
                          {state}
                        </p>
                        <p className="font-15">
                          <span className="bd-label-profile">City : </span>
                          {City}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
