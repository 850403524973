import React, { useMemo, useState, useEffect, useRef } from "react"
import axios from "axios"
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import Select from "react-select"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Form,
  Label,
  CardTitle,
} from "reactstrap"
import GlobalFilter from "pages/seperate/GlobalFilter"
import Loader from "pages/seperate/Loader"
import ExportExcel from "pages/Reports/Export"

const CityName = ({ edit_role, add_role, delete_role }) => {
  const [data, setdatas] = useState([])
  const [option1, setOption1] = useState([])
  const [option2, setOption2] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [state_id, setStateId] = useState("")
  const [state_name, setStateName] = useState(null)
  const [zone_id, setZoneId] = useState("")
  const [district_name, setDistrict] = useState("")
  const [zone_name, setZoneName] = useState("")
  const [district_id, setDistrictId] = useState("")

  const [exportData, setExportData] = useState([])

  const selectInputRef = useRef()

  // get Exporting Data
  useEffect(() => {
    try {
      const response = axios({
        method: "get",
        url: "https://api.orthotrack.in/api/city_export",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        if (response.data.length != 0) {
          setExportData(response.data)
        }
      })
    } catch (error) { console.log(error) }
  }, [])

  useEffect(() => {
    const url1 = "https://api.orthotrack.in/api/zones"
    const url2 = "https://api.orthotrack.in/api/districts"
    setLoading(true)
    try {
      const response = axios({
        method: "get",
        url: url1,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.zone_name,
        }))
        setOption1(options)
      })

      const response1 = axios({
        method: "get",
        url: url2,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response1 => {
        setdatas(response1.data.data)
      })

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }, [])

  // Dependently State

  const onSelectState = async e => {
    selectInputRef.current.select.clearValue()
    setLoading(true)
    if (e != null) {
      setZoneId(e.value)
    }

    const formData = new FormData()
    formData.append("zone_id", e.value)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/zone_state",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.state_name,
        }))
        setOption2(options)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const onEditDistrict = async e => {
    e.preventDefault()
    if (state_id?.length === 0 || zone_id?.length === 0) {
      swal('', 'All Fields Required!', 'warning')
      return;
    }
    setLoading(true)
    const formData = new FormData()
    formData.append("state_id", state_id)
    formData.append("zone_id", zone_id)
    formData.append("district_name", district_name)
    formData.append("district_id", district_id)
    formData.append("user_id", localStorage.getItem("authid"))

    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/updateDistricts",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "City Edited Successfully",
        }).then(data => {
          setEditState(false)
        })
        setdatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const onAddDistrict = async e => {
    e.preventDefault()
    if (state_id?.length === 0 || zone_id?.length === 0) {
      swal('', 'All Fields Required!', 'warning')
      return;
    }
    setLoading(true)
    const formData = new FormData()
    formData.append("state_id", state_id)
    formData.append("zone_id", zone_id)
    formData.append("district_name", district_name)
    formData.append("user_id", localStorage.getItem("authid"))

    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/storeDistricts",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "City Added Successfully",
        }).then(data => {
          setmodal_center(false)
        })
        setdatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        className: "id-width",
      },
      {
        Header: "Zone",
        accessor: "zone_name",
        filterable: true,
      },
      {
        Header: "State",
        accessor: "state_name",
        filterable: true,
      },
      {
        Header: "City",
        accessor: "district_name",
        filterable: true,
      },
      {
        Header: "Action",
        filterable: true,
        className: "action-width border-botttom-black",
        Cell: cellProps => {
          let Edit
          let Delete
          if (edit_role == "1") {
            Edit = (
              <div className="bio-edit-btn">
                <a
                  className="btn btn-outline-secondary btn-sm edit "
                  title="Edit"
                  onClick={async e => {
                    setEditState(true)
                    setDistrictId(cellProps.row.values.id)
                    setStateId(cellProps.row.original.state_id)
                    setZoneName(cellProps.row.values.zone_name)
                    setZoneId(cellProps.row.original.zone_id)
                    setStateName(cellProps.row.values.state_name)
                    setDistrict(cellProps.row.values.district_name)
                    setLoading(true)
                    const formData = new FormData()
                    formData.append("zone_id", cellProps.row.original.zone_id)


                    try {
                      const response = await axios({
                        method: "post",
                        url: "https://api.orthotrack.in/api/zone_state",
                        data: formData,
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "multipart/form-data",
                        },
                      }).then(response => {
                        const options = response.data.data.map(item => ({
                          value: item.id,
                          label: item.state_name,
                        }))
                        setOption2(options)
                        setLoading(false)
                      })
                    } catch (err) {
                      swal({
                        icon: "error",
                        text: "SomeThing Went Wrong",
                      })
                      setLoading(false)
                    }
                  }}
                >
                  <i className="fas fa-pencil-alt"></i>
                </a>
              </div>
            )
          }

          if (delete_role == "1") {
            Delete = (
              <div className="bio-edit-btn">
                <a
                  className="btn btn-danger btn-sm edit"
                  onClick={async e => {
                    swal({
                      title: "Are you sure?",
                      text: "You want to Delete this City!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async willDelete => {
                      if (willDelete) {
                        e.preventDefault()
                        setLoading(true)
                        const formData = new FormData()
                        formData.append("district_id", cellProps.row.values.id)
                        formData.append("user_id", localStorage.getItem("authid"))

                        try {
                          const response = await axios({
                            method: "post",
                            url: "https://api.orthotrack.in/api/deleteDistricts",
                            data: formData,
                            headers: {
                              Accept: "application/json",
                              "Content-Type": "multipart/form-data",
                            },
                          }).then(response => {
                            swal({
                              icon: "success",
                              text: "City Deleted Successfully",
                            }).then(data => {
                              setmodal_center(false)
                              setdatas(response.data.data)
                            })
                            setLoading(false)
                          })
                        } catch (err) {
                          swal({
                            icon: "error",
                            text: "SomeThing Went Wrong",
                          })
                          setLoading(false)
                        }
                      }
                    })
                  }}
                >
                  <i className="fas fa-trash-alt"></i>
                </a>
              </div>
            )
          }

          return (
            <>
              <div className="row ">
                {Edit}
                {Delete}
              </div>
            </>
          )
        },
      },
    ],
    [edit_role, delete_role]
  )

  const [modal_center, setmodal_center] = useState(false)
  const [editState, setEditState] = useState(false)

  function tog_center() {
    setmodal_center(!modal_center)
  }

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px", color: "#00000040" }}
          ></i>
        </>
      )
    ) : (
      <>
        <i
          className="fas fa-long-arrow-alt-down"
          style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
        ></i>
        <i
          className="fas fa-long-arrow-alt-up "
          style={{ fontSize: "11px", color: "#00000040" }}
        ></i>
      </>
    )
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [open, setOpen] = useState(false)

  const toggleLeftCanvas = () => {
    setOpen(!open)
  }

  // Logic for exporting table data
  const transformedArray = []
  exportData.forEach((item, i) => {
    transformedArray.push({
      ["ID"]: item.id,
      ["Zone Name"]: item.zone_name,
      ["State"]: item.state_name,
      ["City"]: item.district_name
    })
  })

  let Add1
  let Add2
  if (add_role == "1") {
    Add1 = (
      <Col md="3" className="w-50 d-flex justify-content-end bd-responsive-btn text-right">
        <Button
          className="btn-theme-color"
          onClick={() => {
            tog_center()
          }}
        >
          + Add City
        </Button>
        <ExportExcel
          data={transformedArray}
          marginTop={"mt-08"}
          name={"City.xlsx"}
        />
      </Col>
    )
    Add2 = (
      <div className="col-10">
        <Button
          className="btn-theme-color"
          onClick={() => {
            tog_center()
          }}
        >
          + Add City
        </Button>
      </div>
    )
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="bd-side-header">
        <CardTitle className="h4 text-black">City</CardTitle>
        <p className="card-title-desc">Listing View of City</p>
      </div>

      <div className="bd-responsive-filter">
        <Row className="mb-2 align-item-center">
          <Col md="2">
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {Add1}
        </Row>
      </div>

      {/* Responsive Top section */}

      <div className="bd-responsive-filter2 pb-20">
        <div>
          <div className="display-flex align-item-center">
            {Add2}
            <div className="col-2 text-right">
              <a
                className="btn btn-outline-secondary bd-custom-sidebar"
                onClick={toggleLeftCanvas}
              >
                <i className="fas fa-filter "></i>
              </a>
            </div>
          </div>
        </div>
        {open && (
          <div className="filter-sidebar" id="mySidenav">
            <div className=" display-flex">
              <div className="col-9">Apply Filter</div>
              <div className="col-3 text-right">
                <a className="" onClick={toggleLeftCanvas}>
                  <i className="fas fa-times"></i>
                </a>
              </div>
            </div>
            <div className="pt-20">
              <Row className="mb-2 align-item-center">
                <Col md="2">
                  <select
                    className="form-select"
                    value={pageSize}
                    onChange={onChangeInSelect}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </Row>
            </div>
          </div>
        )}
      </div>

      <div className="table-responsive react-table">
        <Table {...getTableProps()} className="table-striped">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.className}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                      data-column={cell.column.Header}
                      className={cell.column.className}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      <div>
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto  d-md-block">
            <span className="d-mobile-none"> Page </span>
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {/* Add State Modal */}
      <Modal isOpen={modal_center} size="sm" className="bd-custom-md" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add City</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onAddDistrict}>
            <div className="mb-3">
              <Label htmlFor="formrow-firstname-Input">Select Zone</Label>
              <Select
                options={option1}
                onChange={onSelectState}
                className="select2-selection"
              />
            </div>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">Select State</Label>
              <Select
                options={option2}
                onChange={e => {
                  if (e != null) {
                    setStateId(e.value)
                  }
                }}
                ref={selectInputRef}
                className="select2-selection"
              />
            </div>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">City</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter City"
                onChange={e => setDistrict(e.target.value)}
                required
              />
            </div>
            <div>
              <Button className="btn btn-primary">Submit</Button>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Edit State Modal */}

      <Modal isOpen={editState} size="sm" className="bd-custom-md" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit City</h5>
          <button
            type="button"
            onClick={() => {
              setEditState(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onEditDistrict}>
            <div className="mb-3">
              <Label htmlFor="formrow-firstname-Input">Select Zone</Label>
              <Select
                options={option1}
                className="select2-selection"
                onChange={onSelectState}
                defaultValue={{ label: zone_name }}
              />
            </div>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">Select State</Label>
              <Select
                options={option2}
                className="select2-selection"
                onChange={e => {
                  if (e != null) {
                    setStateId(e.value)
                  }
                }}
                ref={selectInputRef}
                defaultValue={{ label: state_name }}
              />
            </div>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">City</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter City"
                defaultValue={district_name}
                onChange={e => setDistrict(e.target.value)}
                required
              />
            </div>
            <div>
              <Button className="btn btn-primary">Submit</Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default CityName
