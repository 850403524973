import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import logo from "../../assets/images/logo.svg"
import profile2 from "assets/images/small/login-doctor.png"
import mainLogo from "../../assets/images/company-logo-new (2).png"
import axios from "axios"
import Loader from "pages/seperate/Loader"
import swal from "sweetalert"
import $ from "jquery"

const PasswordOtp = () => {


  if (typeof window != "undefined") {
    $(document).ready(function () {
      $("input").keyup(function () {
        if (this.value.length == $(this).attr("maxLength")) {

          $(this).next().focus()
        }
      })
    })
  }

  const [error2, setError2] = useState(false)
  const [pass1, setPass1] = useState("")
  const [pass2, setPass2] = useState("")
  const [pass3, setPass3] = useState("")
  const [pass4, setPass4] = useState("")
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()

  const onHandleOTP = async e => {
    e.preventDefault()
    if (pass1 + pass2 + pass3 + pass4 == localStorage.getItem("otp")) {
      swal({
        icon: "success",
        text: "OTP Verified Successfully",
      }).then(data => {
        navigate("/reset-password")
      })
    }else{
      setError2(true)
    }
  }

  return (
    <div>
      <div className="bd-overlay-img">
        <div className="account-pages pt-50 pt-sm-5 main-login-page">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row className="justify-space-between">
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Reset Password</p>
                        </div>
                      </Col>
                      <Col className="col-3 align-self-end mb-10 text-right mr-30">
                        <img
                          src={profile2}
                          alt=""
                          className="img-fluid"
                          style={{ width: "90%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="col-md-2">
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-3">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={mainLogo}
                              alt=""
                              className="rounded-circle"
                              height="68"
                              width="68"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form className="form-horizontal" onSubmit={onHandleOTP}>
                        {error2 ? (
                          <Alert color="danger">
                            OTP is invalid. Please enter correct OTP
                          </Alert>
                        ) : null}
                        <Row>
                          <Label className="form-label">Enter OTP</Label>
                          <div className="mb-3 col-md-3">
                            <input
                              className="form-control text-center"
                              type="text"
                              onChange={e => {
                                setPass1(e.target.value)
                                setError2(false)
                              }}
                              maxLength="1"
                            />
                          </div>
                          <div className="mb-3 col-md-3">
                            <input
                              className="form-control text-center"
                              type="text2"
                              onChange={e => {
                                setPass2(e.target.value)
                                setError2(false)
                              }}
                              maxLength="1"
                            />
                          </div>
                          <div className="mb-3 col-md-3">
                            <input
                              className="form-control text-center"
                              type="text"
                              onChange={e => {
                                setPass3(e.target.value)
                                setError2(false)
                              }}
                              maxLength="1"
                            />
                          </div>
                          <div className="mb-3 col-md-3">
                            <input
                              className="form-control text-center"
                              type="text"
                              onChange={e => {
                                setPass4(e.target.value)
                                setError2(false)
                              }}
                              maxLength="1"
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default PasswordOtp
