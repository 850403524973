import Breadcrumb from "components/Common/Breadcrumb"
import React, { useMemo, useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import GlobalFilter from "pages/seperate/GlobalFilter"
import * as XLSX from "xlsx"
import { saveAs } from "file-saver"
import TargetFile from "../../assets/images/Target-Sample-format.xlsx"
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import Loader from "pages/seperate/Loader"
import ExportExcel from "pages/Reports/Export"
import swal from "sweetalert"

const Index = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setdatas] = useState([])

  const [onShowProduct, setProductShow] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    async function getTargets() {
      try {
        const formData = new FormData()
        formData.append("zone_id", localStorage.getItem("auth_z"))
        formData.append("record", localStorage.getItem("record"))

        const response = await axios.post("https://api.orthotrack.in/api/targets", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          }
        })
        setdatas(response.data.data)
        // console.log(response.data.data)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    getTargets()
  }, [])

  const [traget_data, setTragetData] = useState([])

  const [staff_name, setStaffName] = useState("")
  const [staff_id, setStaffId] = useState("")
  const [month, setMonth] = useState("")
  const [year, setYear] = useState(2023)
  const [main_data, setMainData] = useState([])
  const [user_id, setUserId] = useState("")
  const [traget_id, setTragetId] = useState("")
  const [from_month, setFromMonth] = useState("0")
  const [to_month, setToMonth] = useState("11")

  const columns = useMemo(
    () => [
      {
        Header: "Employee Id",
        accessor: "emp_id",
        className: "",
        data_column: "ID",
        filterable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        data_column: "Name",
      },
      {
        Header: "Designation",
        accessor: "designation",
        filterable: true,
      },
      {
        Header: "Zone",
        accessor: "zone_name",
        filterable: true,
      },
      {
        Header: "State",
        accessor: "state_name",
        filterable: true,
      },
      // {
      //   Header: "Total Targets",
      //   accessor: "total_targets",
      //   filterable: true,
      // },
      {
        Header: "Action",
        filterable: true,
        className: "staff-action-width border-botttom-black",
        Cell: cellProps => {
          const onGetData = async () => {
            setLoading(true)
            setStaffName(cellProps.row.original.name)
            setStaffId(cellProps.row.original.emp_id)
            const mainData =
              from_month == "" && to_month != ""
                ? datas.filter(
                  item => item.id >= 1 && item.id <= parseFloat(to_month) + 1
                )
                : to_month == "" && from_month != ""
                  ? datas.filter(
                    item =>
                      item.id >= parseFloat(from_month) + 1 && item.id <= 12
                  )
                  : from_month != "" && to_month != ""
                    ? datas.filter(
                      item =>
                        item.id >= parseFloat(from_month) + 1 &&
                        item.id <= parseFloat(to_month) + 1
                    )
                    : from_month == "" && to_month == ""
                      ? datas
                      : datas
            setMainData(mainData)
            setTragetId(cellProps.row.original.id)
            setUserId(cellProps.row.original.user_id)

            const formData = new FormData()
            formData.append("user_id", cellProps.row.original.user_id)
            formData.append("target_id", cellProps.row.original.id)
            formData.append("year", year)
            try {
              const response = axios({
                method: "post",
                url: "https://api.orthotrack.in/api/target_data",
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(response => {
                setProductShow(true)
                setTragetData(response.data.data)
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          return (
            <>
              <div className="col-4">
                <a
                  to="/edit-staff"
                  className="btn btn-outline-secondary btn-sm edit"
                  title="Edit"
                  onClick={onGetData}
                >
                  <i className="fas fa-eye"></i>
                </a>
              </div>
            </>
          )
        },
      },
    ],
    [from_month, to_month]
  )

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px", color: "#00000040" }}
          ></i>
        </>
      )
    ) : (
      <>
        <i
          className="fas fa-long-arrow-alt-down"
          style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
        ></i>
        <i
          className="fas fa-long-arrow-alt-up "
          style={{ fontSize: "11px", color: "#00000040" }}
        ></i>
      </>
    )
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const [open, setOpen] = useState(false)
  const [onTargetEdit, setTargetEdit] = useState(false)
  const [onUploadTarget, setUploadTarget] = useState(false)

  const toggleLeftCanvas = () => {
    setOpen(!open)
  }

  const datas = [
    {
      id: "1",
      month: "January",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
    {
      id: "2",
      month: "February",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
    {
      id: "3",
      month: "March",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
    {
      id: "4",
      month: "April",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
    {
      id: "5",
      month: "May",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
    {
      id: "6",
      month: "June",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
    {
      id: "7",
      month: "July",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
    {
      id: "8",
      month: "August",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
    {
      id: "9",
      month: "September",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
    {
      id: "10",
      month: "October",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
    {
      id: "11",
      month: "November",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
    {
      id: "12",
      month: "December",
      bipolar: "17 / 17",
      knee: "42 / 44",
      hip: "05 / 10",
    },
  ]

  const all_years = [
    2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014,
    2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
    2027, 2028, 2029, 2030, 2031, 2032, 2034, 2035, 2036, 2037, 2038, 2039,
    2040, 2041, 2042, 2043,
  ]

  const [bipolar_count, setBipolarCount] = useState("")
  const [hip_count, setHipCount] = useState("")
  const [knee_count, setKneeCount] = useState("")
  const [arthroscopy_count, setArthroscopyCount] = useState("")
  const [edit_month, setEditMonth] = useState("")

  const onEditTarget = async e => {
    e.preventDefault()
    const category = knee_count + "," + hip_count + "," + arthroscopy_count + "," + bipolar_count;
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", user_id)
    formData.append("target_id", traget_id)
    formData.append("month", edit_month)
    formData.append("year", year)
    formData.append("category", category)
    try {
      const response = axios({
        method: "post",
        url: "https://api.orthotrack.in/api/edit_target_data",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Traget Edited Successfully",
        }).then(data => {
          setTargetEdit(false)
          setTragetData(response.data.data)
        })
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const transformedArray = []

  main_data.forEach(item => {
    const transformedItem = { Month: item.month }

    traget_data.forEach(obj => {
      if (obj.month == item.id) {
        if (obj.category == 9) {
          transformedItem.Bipolar = obj.surgery_count + "/" + obj.count
        } else if (obj.category == 4) {
          transformedItem["Knee Arthroplasty"] =
            obj.surgery_count + "/" + obj.count
        } else if (obj.category == 5) {
          transformedItem["Hip Arthroplasty"] =
            obj.surgery_count + "/" + obj.count
        }
      }
    })
    transformedArray.push(transformedItem)
  })

  const onFilterData = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("from", parseFloat(from_month) + 1)
    formData.append("to", parseFloat(to_month) + 1)
    formData.append("year", year)
    formData.append("record", localStorage.getItem("record"))
    try {
      const response = axios({
        method: "post",
        url: "https://api.orthotrack.in/api/filtered_dashboard_data",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {

        // console.log('target data ', response)

        const flattenedArray = Object.values(response.data).reduce(
          (accumulator, currentValue) => accumulator.concat(currentValue),
          []
        )

        const groupedArray = Object.values(
          flattenedArray.reduce((acc, obj) => {
            const { month, name, ...rest } = obj
            const key = `${month}-${name}`

            if (!acc[key]) {
              acc[key] = { month, name, ...rest }
            } else {
              Object.assign(acc[key], rest)
            }

            return acc
          }, {})
        )

        const transformedArray = []

        groupedArray.forEach(item => {
          transformedArray.push({
            ["Employee ID"]: item.emp_id,
            Name: item.name,
            Designation: item.designation,
            Zone: item.zone,
            State: item.state,
            Month: item.month_name,
            Bipolar: item.Bipolar,
            Hip: item["Hip Arthroplasty"],
            Knee: item["Knee Arthroplasty"],
            Arthroscopy: item["Arthroscopy"],
          })
        })

        const worksheet = XLSX.utils.json_to_sheet(transformedArray)

        const headerCellStyle = {
          fill: {
            patternType: "none",
            fgColor: { rgb: "FF000000" },
            bgColor: { rgb: "FF000000" },
          },
        } // Yellow background
        Object.keys(worksheet).forEach(cell => {
          if (cell.endsWith("1")) {
            worksheet[cell].s = headerCellStyle
          }
        })

        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        })

        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        saveAs(blob, "Target-Report")

        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const [file, setFiles] = useState("")
  const [upload_year, setUploadYear] = useState("2023")

  const onImportTarget = async e => {
    setLoading(true)
    e.preventDefault()
    const formData = new FormData()
    formData.append("file", file)
    formData.append("year", upload_year)
    try {
      const response = axios({
        method: "post",
        url: "https://api.orthotrack.in/api/target_import",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Traget Imported Successfully",
        }).then(data => {
          window.location.reload()
        })

        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid className="custom-container">
          <Breadcrumb title={"Dashboard"} breadcrumbItem={"Targets"} />
          <div>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div>
                      <div>
                        <div className="bd-responsive-filter">
                          <div>
                            <CardTitle className="h4 text-black ">
                              Staff Targets
                            </CardTitle>
                            <p className="card-title-desc">
                              Listing View of Staff Targets
                            </p>
                          </div>

                          <div
                            className="mb-3 d-flex align-item-center"
                            style={{
                              justifyContent: "space-around",
                              alignItems: "end",
                            }}
                          >
                            <GlobalFilter
                              filter={globalFilter}
                              setFilter={setGlobalFilter}
                            />
                            <Col md=" mr-10">
                              <label>From</label>
                              <select
                                className="form-select"
                                onChange={e => setFromMonth(e.target.value)}
                              >
                                <option value="">All Months</option>
                                <option value="0">January</option>
                                <option value="1">February</option>
                                <option value="2">March</option>
                                <option value="3">April</option>
                                <option value="4">May</option>
                                <option value="5">June</option>
                                <option value="6">July</option>
                                <option value="7">August</option>
                                <option value="8">September</option>
                                <option value="9">October</option>
                                <option value="10">November</option>
                                <option value="11">December</option>
                              </select>
                            </Col>
                            <Col md=" mr-10">
                              <label>To</label>
                              <select
                                className="form-select"
                                onChange={e => setToMonth(e.target.value)}
                              >
                                <option value="">All Months</option>
                                <option value="0">January</option>
                                <option value="1">February</option>
                                <option value="2">March</option>
                                <option value="3">April</option>
                                <option value="4">May</option>
                                <option value="5">June</option>
                                <option value="6">July</option>
                                <option value="7">August</option>
                                <option value="8">September</option>
                                <option value="9">October</option>
                                <option value="10">November</option>
                                <option value="11">December</option>
                              </select>
                            </Col>
                            <Col md="1">
                              <select
                                className="form-select"
                                onChange={e => setYear(e.target.value)}
                              >
                                {all_years.map((obj, i) => {
                                  return (
                                    <option
                                      key={i}
                                      selected={obj == year}
                                      value={obj}
                                    >
                                      {obj}
                                    </option>
                                  )
                                })}
                              </select>
                            </Col>
                            <div className=" text-right  full-width d-flex justify-content-end">
                              <a
                                className="btn-theme-color ml-10 mr-15"
                                style={{ borderRadius: "4px" }}
                                onClick={onFilterData}
                              >
                                <i
                                  className="fas fa-download pr-10"
                                  style={{ fontSize: "10px" }}
                                ></i>
                                Export
                              </a>
                              <Button
                                className="btn-theme-color"
                                onClick={() => setUploadTarget(true)}
                              >
                                <i className="fas fa-file-import pr-5"></i>{" "}
                                Import Targets
                              </Button>
                            </div>
                          </div>
                        </div>

                        {/* Responsive Top section */}

                        <div className="bd-responsive-filter2 pb-20">
                          <div>
                            <div className="display-flex align-item-center">
                              <div className="col-10">
                                <Button className="btn-theme-color">
                                  + Add Staff
                                </Button>
                              </div>
                              <div className="col-2 text-right">
                                <a
                                  className="btn btn-outline-secondary bd-custom-sidebar"
                                  onClick={toggleLeftCanvas}
                                >
                                  <i className="fas fa-filter "></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          {open && (
                            <div className="filter-sidebar" id="mySidenav">
                              <div className=" display-flex">
                                <div className="col-9">Apply Filter</div>
                                <div className="col-3 text-right">
                                  <a className="" onClick={toggleLeftCanvas}>
                                    <i className="fas fa-times"></i>
                                  </a>
                                </div>
                              </div>
                              <div className="pt-20">
                                <Row className="mb-2 align-item-center">
                                  <Col md="2">
                                    <select
                                      className="form-select"
                                      value={pageSize}
                                      onChange={onChangeInSelect}
                                    >
                                      {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                          Show {pageSize}
                                        </option>
                                      ))}
                                    </select>
                                  </Col>
                                  <GlobalFilter
                                    filter={globalFilter}
                                    setFilter={setGlobalFilter}
                                  />
                                </Row>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="table-responsive react-table">
                          <Table {...getTableProps()} className="table-striped">
                            <thead className="">
                              {headerGroups.map(headerGroup => (
                                <tr
                                  key={headerGroup.id}
                                  {...headerGroup.getHeaderGroupProps()}
                                >
                                  {headerGroup.headers.map(column => (
                                    <th
                                      key={column.id}
                                      className={column.className}
                                    >
                                      <div {...column.getSortByToggleProps()}>
                                        {column.render("Header")}
                                        {generateSortingIndicator(column)}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                              {page.map(row => {
                                prepareRow(row)
                                return (
                                  <tr key={row.id} {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                      return (
                                        <td
                                          key={cell.id}
                                          {...cell.getCellProps()}
                                          data-column={cell.column.Header}
                                          className={cell.column.className}
                                        >
                                          {cell.render("Cell")}
                                        </td>
                                      )
                                    })}
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div>
                        <Row className="justify-content-md-end justify-content-center align-items-center">
                          <Col className="col-md-auto">
                            <div className="d-flex gap-1">
                              <Button
                                color="primary"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                              >
                                {"<<"}
                              </Button>
                              <Button
                                color="primary"
                                onClick={previousPage}
                                disabled={!canPreviousPage}
                              >
                                {"<"}
                              </Button>
                            </div>
                          </Col>
                          <Col className="col-md-auto d-md-block">
                            <span className="d-mobile-none"> Page </span>
                            <strong>
                              {pageIndex + 1} of {pageOptions.length}
                            </strong>
                          </Col>

                          <Col className="col-md-auto">
                            <div className="d-flex gap-1">
                              <Button
                                color="primary"
                                onClick={nextPage}
                                disabled={!canNextPage}
                              >
                                {">"}
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                              >
                                {">>"}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Modal
        isOpen={onShowProduct}
        size="md"
        className="bd-target-show"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Targets Details</h5>
          <button
            type="button"
            onClick={() => {
              setProductShow(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="align-items-e">
            <div className="mb-3 col-md-4">
              <Label htmlFor="formrow-firstname-Input">Employee ID</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Name"
                value={staff_id}
                disabled
              />
            </div>
            <div className="mb-3 col-md-4">
              <Label htmlFor="formrow-firstname-Input">Staff Name</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Name"
                value={staff_name}
                disabled
              />
            </div>
            <div className="mb-3 col-md-4">
              <ExportExcel
                data={transformedArray}
                marginTop={"mt-35"}
                name={staff_name + "-" + staff_id + " Target Details"}
              />
            </div>
          </Row>
          <div className="table-responsive">
            <table className="table table-bordered table-striped mb-0 table padding-table">
              <thead>
                {/* <tr>
                  <th colSpan='2.5'>{staff_id}</th>
                  <th colSpan='2.5'>{staff_name}</th>
                </tr> */}
                <tr>
                  <th>Month</th>
                  <th className="text-center">Bipolar</th>
                  <th className="text-center">Knee </th>
                  <th className="text-center">Hip </th>
                  <th className="text-center">Arthroscopy </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {main_data.map((items, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{items.month}</th>
                      {traget_data.map((obj, index) => {
                        if (obj.month == items.id && obj.category == 9) {
                          return (
                            <td key={index}>
                              {obj.surgery_count}/{obj.count}
                            </td>
                          )
                        }
                      })}

                      {traget_data.map((obj, index) => {
                        if (obj.month == items.id && obj.category == 4) {
                          return (
                            <td key={index}>
                              {obj.surgery_count}/{obj.count}
                            </td>
                          )
                        }
                      })}

                      {traget_data.map((obj, index) => {
                        if (obj.month == items.id && obj.category == 5) {
                          return (
                            <td key={index}>
                              {obj.surgery_count}/{obj.count}
                            </td>
                          )
                        }
                      })}

                      {traget_data.map((obj, index) => {
                        if (obj.month == items.id && obj.category == 8) {
                          return (
                            <td key={index}>
                              {obj.surgery_count}/{obj.count}
                            </td>
                          )
                        }
                      })}
                      <td>
                        <a
                          className="btn btn-outline-secondary btn-sm edit"
                          title="Edit"
                          onClick={() => {
                            traget_data.map(obj => {

                              if (obj.month == items.id && obj.category == 4) {
                                setKneeCount(obj.count)
                              }
                              if (obj.month == items.id && obj.category == 5) {
                                setHipCount(obj.count)
                              }
                              if (obj.month == items.id && obj.category == 9) {
                                setBipolarCount(obj.count)
                              }
                              if (obj.month == items.id && obj.category == 8) {
                                setArthroscopyCount(obj.count)
                              }
                            })
                            setEditMonth(items.id)
                            setTargetEdit(true)
                          }}
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>

      <Modal isOpen={onTargetEdit} size="md" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Targets Details</h5>
          <button
            type="button"
            onClick={() => {
              setTargetEdit(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={onEditTarget}>
            <Row>
              <div className="mb-4 col-md-6">
                <Label htmlFor="formrow-firstname-Input">Bipolar</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Bipolar Target"
                  value={bipolar_count}
                  onChange={e => setBipolarCount(e.target.value)}
                />
              </div>
              <div className="mb-4 col-md-6">
                <Label htmlFor="formrow-firstname-Input">
                  Knee Arthroplasty
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Knee Arthroplasty Target"
                  value={knee_count}
                  onChange={e => setKneeCount(e.target.value)}
                />
              </div>
              <div className="mb-4 col-md-6">
                <Label htmlFor="formrow-firstname-Input">
                  Hip Arthroplasty
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Hip Arthroplasty Target"
                  value={hip_count}
                  onChange={e => setHipCount(e.target.value)}
                />
              </div>
              <div className="mb-4 col-md-6">
                <Label htmlFor="formrow-firstname-Input">
                  Arthroscopy
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Arthroscopy Target"
                  value={arthroscopy_count}
                  onChange={e => setArthroscopyCount(e.target.value)}
                />
              </div>
            </Row>
            <div>
              <Button className="btn btn-primary">Submit</Button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={onUploadTarget}
        size="sm"
        className="bd-import-target"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Import Targets</h5>
          <button
            type="button"
            onClick={() => {
              setUploadTarget(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={onImportTarget}>
            <Row>
              <div className="mb-4 col-md-12">
                <Label htmlFor="">
                  Download Excel Utility For Mass Import Targets{" "}
                </Label>
                <div>
                  <a className="btn btn-primary" href={TargetFile} download>
                    <i className="fas fa-file-alt pr-5"></i>Sample File
                  </a>
                </div>
              </div>
              <div className="mb-3 col-md-12 mt-3">
                <Label htmlFor="fo">
                  Select Year <span className="text-danger">*</span>
                </Label>
                <select
                  className="form-select"
                  onChange={e => setUploadYear(e.target.value)}
                >
                  {all_years.map((obj, i) => {
                    return (
                      <option key={i} selected={obj == year} value={obj}>
                        {obj}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="mb-4 col-md-12">
                <Label htmlFor="formrow-firstname-Input">
                  Upload File <span className="text-danger">*</span>
                </Label>
                <Input
                  type="file"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Bipolar Target"
                  required
                  onChange={e => setFiles(e.target.files[0])}
                />
              </div>
            </Row>
            <div>
              <Button className="btn btn-primary">Submit</Button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  )
}

export default Index
