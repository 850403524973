import React, { useMemo, useState, useEffect } from "react"
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import axios from "axios"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Form,
  Label,
  CardTitle,
} from "reactstrap"
import GlobalFilter from "pages/seperate/GlobalFilter"
import Loader from "pages/seperate/Loader"

const NeeSizeTable = () => {
  const [data, setdatas] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [kneesize_id, setKneeSizeId] = useState("")

  useEffect(() => {
    setLoading(true)
    try {
      const response = axios({
        method: "get",
        url: "https://api.orthotrack.in/api/knee_size",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        if (response.data.data.length != 0) {
          setdatas(response.data.data)
        }
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
    }
  }, [])

  const [knee_size, setKneeSize] = useState("")

  const onAddKneeSize = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("knee_size", knee_size)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/storeKneeSize",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "KneeSize Added Successfully",
        }).then(data => {
          setmodal_center(false)
          setdatas(response.data.data)
        })

        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const onEditKneeSize = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("kneesize_id", kneesize_id)
    formData.append("knee_size", knee_size)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/updateKneeSize",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "KneeSize Edited Successfully",
        }).then(data => {
          setEditBrands(false)
          setdatas(response.data.data)
        })
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        className: "id-width",
      },
      {
        Header: "Size",
        accessor: "knee_size",
        filterable: true,
      },
      {
        Header: "Action",
        filterable: true,
        className: "action-width border-botttom-black",
        Cell: cellProps => {
          return (
            <>
              <div className="row ">
                <div className="bio-edit-btn">
                  <a
                    className="btn btn-outline-secondary btn-sm edit "
                    title="Edit"
                    onClick={() => {
                      setEditBrands(true)
                      setKneeSizeId(cellProps.row.values.id)
                      setKneeSize(cellProps.row.values.knee_size)
                    }}
                  >
                    <i className="fas fa-pencil-alt"></i>
                  </a>
                </div>
                <div className="bio-edit-btn">
                  <a
                    className="btn btn-danger btn-sm edit"
                    onClick={async e => {
                      e.preventDefault()
                      setLoading(true)
                      const formData = new FormData()
                      formData.append("kneesize_id", cellProps.row.values.id)
                      try {
                        const response = await axios({
                          method: "post",
                          url: "https://api.orthotrack.in/api/deleteKneeSize",
                          data: formData,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        }).then(response => {
                          swal({
                            icon: "success",
                            text: "KneeSize Deleted Successfully",
                          }).then(data => {
                            setmodal_center(false)
                            setdatas(response.data.data)
                          })
                          setLoading(false)
                        })
                      } catch (err) {
                        swal({
                          icon: "error",
                          text: "SomeThing Went Wrong",
                        })
                        setLoading(false)
                      }
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const [modal_center, setmodal_center] = useState(false)
  const [editBrands, setEditBrands] = useState(false)
  function tog_center() {
    setmodal_center(!modal_center)
  }

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 4,
        pageSize: 4,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state
  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px", color: "#00000040" }}
          ></i>
        </>
      )
    ) : (
      <>
        <i
          className="fas fa-long-arrow-alt-down"
          style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
        ></i>
        <i
          className="fas fa-long-arrow-alt-up "
          style={{ fontSize: "11px", color: "#00000040" }}
        ></i>
      </>
    )
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [open, setOpen] = useState(false)

  const toggleLeftCanvas = () => {
    setOpen(!open)
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="bd-side-header">
        <CardTitle className="h4 text-black">KNEE Size</CardTitle>
        <p className="card-title-desc">Listing View of KNEE Size</p>
      </div>

      <div className="bd-responsive-filter">
        <Row className="mb-2 align-item-center">
          <Col md="2">
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          <Col md="2" className="w-50 bd-responsive-btn text-right">
            <Button
              className="btn-theme-color"
              onClick={() => {
                tog_center()
              }}
            >
              + Add KNEE Size
            </Button>
          </Col>
        </Row>
      </div>

      {/* Responsive Top section */}

      <div className="bd-responsive-filter2 pb-20">
        <div>
          <div className="display-flex align-item-center">
            <div className="col-10">
              <Button
                className="btn-theme-color"
                onClick={() => {
                  tog_center()
                }}
              >
                + Add KNEE Size
              </Button>
            </div>
            <div className="col-2 text-right">
              <a
                className="btn btn-outline-secondary bd-custom-sidebar"
                onClick={toggleLeftCanvas}
              >
                <i className="fas fa-filter "></i>
              </a>
            </div>
          </div>
        </div>
        {open && (
          <div className="filter-sidebar" id="mySidenav">
            <div className=" display-flex">
              <div className="col-9">Apply Filter</div>
              <div className="col-3 text-right">
                <a className="" onClick={toggleLeftCanvas}>
                  <i className="fas fa-times"></i>
                </a>
              </div>
            </div>
            <div className="pt-20">
              <Row className="mb-2 align-item-center">
                <Col md="2">
                  <select
                    className="form-select"
                    value={pageSize}
                    onChange={onChangeInSelect}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </Row>
            </div>
          </div>
        )}
      </div>

      <div className="table-responsive react-table">
        <Table {...getTableProps()} className="table-striped">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    {...column.getSortByToggleProps()}
                    className={column.className}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                      data-column={cell.column.Header}
                      className={cell.column.className}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      <div>
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto  d-md-block">
          <span className="d-mobile-none"> Page </span>
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {/* Add Brands Modal */}
      <Modal isOpen={modal_center} size="sm" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Knee Size</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onAddKneeSize}>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">Knee Size </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Knee Size "
                onChange={e => setKneeSize(e.target.value)}
              />
            </div>
            <div>
              <Button className="btn btn-primary">Submit</Button>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Edit Brands Modal */}
      <Modal isOpen={editBrands} size="sm" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit KneeSize</h5>
          <button
            type="button"
            onClick={() => {
              setEditBrands(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onEditKneeSize}>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">KneeSize </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter KNeeSize "
                defaultValue={knee_size}
                onChange={e => setKneeSize(e.target.value)}
              />
            </div>
            <div>
              <Button className="btn btn-primary">Submit</Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default NeeSizeTable
