import Breadcrumb from "components/Common/Breadcrumb"
import React, { useMemo, useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
  Table,
  Label,
  Input,
} from "reactstrap"
import GlobalFilter from "pages/seperate/GlobalFilter"
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import axios from "axios"
import { Link, useNavigate, Router } from "react-router-dom"
import Loader from "pages/seperate/Loader"

const ViewStaffTable = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setdatas] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    try {
      const response = axios({
        method: "get",
        url: "https://api.orthotrack.in/api/staff",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setdatas(response.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Employee Id",
        accessor: "emp_id",
        className: "staff-id-width",
        data_column: "ID ",
        filterable: true,
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        data_column: "Name",
      },
      // {
      //   Header: "Contact No.",
      //   accessor: "mobile",
      //   filterable: true,
      // },
      {
        Header: "Designation",
        accessor: "designation",
        filterable: true,
      },
      {
        Header: "Zone",
        accessor: "zone_name",
        filterable: true,
      },
      {
        Header: "State",
        accessor: "state_name",
        filterable: true,
      },
      {
        Header: "City",
        accessor: "city",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "user_status",
        filterable: true,
        Cell: cellProps => {
          const onStatusChange = async e => {
            let status
            if (e.target.checked == true) {
              status = 1
            } else {
              status = 0
            }
            swal({
              title: "Are you sure?",
              text: "You want to Change the Status",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                e.preventDefault()
                setLoading(true)
                const formData = new FormData()
                formData.append("user_id", cellProps.row.original.id)
                formData.append("status", status)
                formData.append("auth_id", localStorage.getItem("authid"))

                try {
                  const response = await axios({
                    method: "post",
                    url: "https://api.orthotrack.in/api/staff_status",
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Status Changed Successfully",
                    }).then(data => {
                      setdatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              } else {
                const checkbox = document.getElementById(
                  "staff" + cellProps.row.original.id
                )
                if (checkbox.checked == false) {
                  checkbox.checked = true
                } else if (checkbox.checked == true) {
                  checkbox.checked = false
                }
              }
            })
          }
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                id={"staff" + cellProps.row.original.id}
                defaultChecked={cellProps.row.original.user_status == 1}
                onChange={onStatusChange}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        filterable: true,
        className: "staff-action-width border-botttom-black",
        Cell: cellProps => {
          const clickCallback = id => {
            navigate(`/edit-staff/${cellProps.row.original.id}`, {
              state: {
                role: cellProps.row.original.role_name,
                zone: cellProps.row.original.zone_name,
                state: cellProps.row.original.state_name,
                city: cellProps.row.original.city,
                city_id: cellProps.row.original.city_id,
                zone_id: cellProps.row.original.zone,
                state_id: cellProps.row.original.state,
                staff_name: cellProps.row.original.manager_name,
                staff_id: cellProps.row.original.id
              },

            })
          }

          const [roles, setRoles] = useState([])
          useEffect(() => {
            const id = localStorage.getItem("authrole_id")
            const fetch = async () => {
              setLoading(true)
              try {
                const { data } = await axios.get(
                  `https://api.orthotrack.in/api/roles/${id}`
                )
                setRoles(data.data.permission)
                setLoading(false)
              } catch (err) {
                console.error(err)
                setLoading(false)
              }
            }
            fetch()
          }, [])

          let Edit
          let Delete
          let Target
          const role_id = localStorage.getItem("authrole_id")

          const allRoles = roles.map(item => {
            if (item.module_id == 1 && item.edit == 1) {
              Edit = (
                <div className="col-4">
                  <a
                    onClick={clickCallback}
                    to="/edit-staff"
                    className="btn btn-outline-secondary btn-sm edit"
                    title="Edit"
                  >
                    <i className="fas fa-pencil-alt"></i>
                  </a>
                </div>
              )
            }

            if (item.module_id == 1 && item.destroy == 1) {
              Delete = (
                <div className="col-4">
                  <a
                    className="btn btn-danger btn-sm edit"
                    onClick={async e => {
                      swal({
                        title: "Are you sure?",
                        text: "You want to Delete this Staff !",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then(async willDelete => {
                        if (willDelete) {
                          e.preventDefault()
                          setLoading(true)
                          const formData = new FormData()
                          formData.append("user_id", cellProps.row.original.id)
                          formData.append("auth_id", localStorage.getItem("authid"))

                          try {
                            const response = await axios({
                              method: "post",
                              url: "https://api.orthotrack.in/api/deleteStaff",
                              data: formData,
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                              },
                            }).then(response => {
                              swal({
                                icon: "success",
                                text: "Staff Deleted Successfully",
                              }).then(data => {
                                setdatas(response.data.data)
                              })
                              setLoading(false)
                            })
                          } catch (err) {
                            swal({
                              icon: "error",
                              text: "SomeThing Went Wrong",
                            })
                            setLoading(false)
                          }
                        }
                      })
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </a>
                </div>
              )
            }
          })

          if (role_id == 1) {
            Target = (
              <div className="col-5">
                <a
                  to="/edit-staff"
                  className="btn btn-outline-secondary btn-sm edit"
                  title="Edit"
                >
                  <i className="fas fa-plus"></i>
                </a>
              </div>
            )
          }

          return (
            <>
              <div className="row ">
                {Edit}
                {Delete}
                {/* {Target} */}
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px", color: "#00000040" }}
          ></i>
        </>
      )
    ) : (
      <>
        <i
          className="fas fa-long-arrow-alt-down"
          style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
        ></i>
        <i
          className="fas fa-long-arrow-alt-up "
          style={{ fontSize: "11px", color: "#00000040" }}
        ></i>
      </>
    )
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const [open, setOpen] = useState(false)

  const toggleLeftCanvas = () => {
    setOpen(!open)
  }
  return (
    <div>
      {isLoading && <Loader />}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div>
                <div>
                  <div className="bd-responsive-filter">
                    <div>
                      <CardTitle className="h4 text-black ">Staff</CardTitle>
                      <p className="card-title-desc">Listing View of Staff</p>
                    </div>

                    <Row className="mb-2 align-item-center">
                      <Col md="2">
                        <select
                          className="form-select"
                          value={pageSize}
                          onChange={onChangeInSelect}
                        >
                          {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <GlobalFilter
                        filter={globalFilter}
                        setFilter={setGlobalFilter}
                      />
                      <Col md="2" className="w-50 text-right  full-width">
                        <Link to={"/add-staff"}>
                          <Button className="btn-theme-color">
                            + Add Staff
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </div>

                  {/* Responsive Top section */}

                  <div className="bd-responsive-filter2 pb-20">
                    <div>
                      <div className="display-flex align-item-center">
                        <div className="col-10">
                          <Link to={"/add-staff"}>
                            <Button className="btn-theme-color">
                              + Add Staff
                            </Button>
                          </Link>
                        </div>
                        <div className="col-2 text-right">
                          <a
                            className="btn btn-outline-secondary bd-custom-sidebar"
                            onClick={toggleLeftCanvas}
                          >
                            <i className="fas fa-filter "></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    {open && (
                      <div className="filter-sidebar" id="mySidenav">
                        <div className=" display-flex">
                          <div className="col-9">Apply Filter</div>
                          <div className="col-3 text-right">
                            <a className="" onClick={toggleLeftCanvas}>
                              <i className="fas fa-times"></i>
                            </a>
                          </div>
                        </div>
                        <div className="pt-20">
                          <Row className="mb-2 align-item-center">
                            <Col md="2">
                              <select
                                className="form-select"
                                value={pageSize}
                                onChange={onChangeInSelect}
                              >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                  <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <GlobalFilter
                              filter={globalFilter}
                              setFilter={setGlobalFilter}
                            />
                          </Row>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="table-responsive react-table">
                    <Table {...getTableProps()} className="table-striped">
                      <thead className="">
                        {headerGroups.map(headerGroup => (
                          <tr
                            key={headerGroup.id}
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map(column => (
                              <th key={column.id} className={column.className}>
                                <div {...column.getSortByToggleProps()}>
                                  {column.render("Header")}
                                  {generateSortingIndicator(column)}
                                </div>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                          prepareRow(row)
                          return (
                            <tr key={row.id} {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return (
                                  <td
                                    key={cell.id}
                                    {...cell.getCellProps()}
                                    data-column={cell.column.Header}
                                    className={cell.column.className}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div>
                  <Row className="justify-content-md-end justify-content-center align-items-center">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button
                          color="primary"
                          onClick={() => gotoPage(0)}
                          disabled={!canPreviousPage}
                        >
                          {"<<"}
                        </Button>
                        <Button
                          color="primary"
                          onClick={previousPage}
                          disabled={!canPreviousPage}
                        >
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-md-block">
                      <span className="d-mobile-none"> Page </span>
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </Col>

                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button
                          color="primary"
                          onClick={nextPage}
                          disabled={!canNextPage}
                        >
                          {">"}
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => gotoPage(pageCount - 1)}
                          disabled={!canNextPage}
                        >
                          {">>"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ViewStaffTable
