import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer text-white" style={{backgroundColor: '#744fa1'}}>
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Surgery Management.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by <a href="https://www.zithas.com" target="_blank" rel="noreferrer" className="text-decoration-none fw-bold shadow" style={{color: '#e7b413'}}>Zithas Technologies</a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
