import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import logo from "../../assets/images/logo.svg"
import profile2 from "assets/images/small/login-doctor.png"
import mainLogo from "../../assets/images/company-logo-new (2).png"
import axios from "axios"
import Loader from "pages/seperate/Loader"
import swal from "sweetalert"

const ForgetPasswordPage = props => {
  //meta title
  document.title =
    "Forget Password | Biorad Medisys - React Admin & Dashboard Template"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      dispatch(userForgetPassword(values, props.history))
    },
  })

  const [error2, setError2] = useState(false)
  const [email, setEmail] = useState("")
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()

  const onHandleForget = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("email", email)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/forget_email",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {

        if (response.data.status == 1) {

          swal(({
            icon:"success",
            text:"OTP send To your Email Address"
          }))
          localStorage.setItem("otp", response.data.data.otp)
          localStorage.setItem("user_id", response.data.data.user_id)
          navigate("/otp")
        } else {
          setError2(true)
        }
        setLoading(false)
      })
    } catch (err) {
      setError2(true)
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="bd-overlay-img">
        <div className="account-pages pt-50 pt-sm-5 main-login-page">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row className="justify-space-between">
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Reset Password</p>
                        </div>
                      </Col>
                      <Col className="col-3 align-self-end mb-10 text-right mr-30">
                        <img
                          src={profile2}
                          alt=""
                          className="img-fluid"
                          style={{ width: "90%" }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="col-md-2">
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-3">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={mainLogo}
                              alt=""
                              className="rounded-circle"
                              height="68"
                              width="68"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={onHandleForget}
                      >
                        {error2 ? (
                          <Alert color="danger">
                            Email Address is invalid. Please enter correct Email
                            Address
                          </Alert>
                        ) : null}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                            onChange={e => {
                              setEmail(e.target.value)
                              setError2(false)
                            }}
                          />
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
