import React, { useMemo, useState, useEffect } from "react"
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import axios from "axios"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Form,
  Label,
  CardTitle,
} from "reactstrap"
import GlobalFilter from "pages/seperate/GlobalFilter"
import Loader from "pages/seperate/Loader"
import ExportExcel from "pages/Reports/Export"

const BusinessTypeTable = ({ edit_role, add_role, delete_role }) => {
  const [data, setdatas] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [business_type, setBusinessType] = useState("")
  const [type_id, setBusinessTypeId] = useState("")
  const [exportData, setExportData] = useState([])

  // get Exporting Data
  useEffect(() => {
    try {
      const response = axios({
        method: "get",
        url: "https://api.orthotrack.in/api/btype_export",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        if (response.data.length != 0) {
          setExportData(response.data)
        }
      })
    } catch (error) { console.log(error) }
  }, [])

  useEffect(() => {
    fetch("https://api.orthotrack.in/api/businessType")
      .then(res => res.json())
      .then(data => {
        setdatas(data.data)
      })
      .catch(err => {
        console.log(err.message)
      })
  }, [])

  const onAddBusinessType = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("business_type", business_type)
    formData.append("user_id", localStorage.getItem("authid"))

    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/storeBusinessType",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Business Type Added Successfully",
        }).then(data => {
          setmodal_center(false)
          setdatas(response.data.data)
        })

        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const onEditBusinessType = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("type_id", type_id)
    formData.append("business_type", business_type)
    formData.append("user_id", localStorage.getItem("authid"))

    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/updateBusinessType",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Business Type Edited Successfully",
        }).then(data => {
          setEditZone(false)
          setdatas(response.data.data)
        })
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        className: "id-width",
      },
      {
        Header: "Business Type",
        accessor: "bussiness_type",
        filterable: true,
      },
      {
        Header: "Action",
        filterable: true,
        className: "action-width border-botttom-black",
        Cell: cellProps => {
          let Edit
          let Delete
          if (edit_role == "1") {
            Edit = (
              <div className="bio-edit-btn">
                <a
                  className="btn btn-outline-secondary btn-sm edit "
                  title="Edit"
                  onClick={() => {
                    setEditZone(true)
                    setBusinessTypeId(cellProps.row.values.id)
                    setBusinessType(cellProps.row.values.bussiness_type)
                  }}
                >
                  <i className="fas fa-pencil-alt"></i>
                </a>
              </div>
            )
          }

          if (delete_role == "1") {
            Delete = (
              <div className="bio-edit-btn">
                <a
                  className="btn btn-danger btn-sm edit"
                  onClick={async e => {
                    swal({
                      title: "Are you sure?",
                      text: "You want to Delete this Business Type!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async willDelete => {
                      if (willDelete) {
                        e.preventDefault()
                        setLoading(true)
                        const formData = new FormData()
                        formData.append("type_id", cellProps.row.values.id)
                        formData.append("user_id", localStorage.getItem("authid"))
                        
                        try {
                          const response = await axios({
                            method: "post",
                            url: "https://api.orthotrack.in/api/deleteBusinessType",
                            data: formData,
                            headers: {
                              Accept: "application/json",
                              "Content-Type": "multipart/form-data",
                            },
                          }).then(response => {
                            swal({
                              icon: "success",
                              text: "Business Type Deleted Successfully",
                            }).then(data => {
                              setdatas(response.data.data)
                            })
                            setLoading(false)
                          })
                        } catch (err) {
                          swal({
                            icon: "error",
                            text: "SomeThing Went Wrong",
                          })
                          setLoading(false)
                        }
                      }
                    })
                  }}
                >
                  <i className="fas fa-trash-alt"></i>
                </a>
              </div>
            )
          }
          return (
            <>
              <div className="row ">
                {Edit}
                {Delete}
              </div>
            </>
          )
        },
      },
    ],
    [edit_role, delete_role]
  )

  const [modal_center, setmodal_center] = useState(false)
  const [editzone, setEditZone] = useState(false)
  function tog_center() {
    setmodal_center(!modal_center)
  }

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px", color: "#00000040" }}
          ></i>
        </>
      )
    ) : (
      <>
        <i
          className="fas fa-long-arrow-alt-down"
          style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
        ></i>
        <i
          className="fas fa-long-arrow-alt-up "
          style={{ fontSize: "11px", color: "#00000040" }}
        ></i>
      </>
    )
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [open, setOpen] = useState(false)

  const toggleLeftCanvas = () => {
    setOpen(!open)
  }

  // Logic for exporting table data
  const transformedArray = []
  exportData.forEach((item, i) => {
    transformedArray.push({
      ["ID"]: item.id,
      ["Business Type"]: item.bussiness_type
    })
  })

  let Add1
  let Add2
  if (add_role == "1") {
    Add1 = (
      <Col md="2" className="w-50 d-flex justify-content-end bd-responsive-btn text-right">
        <Button
          className="btn-theme-color"
          onClick={() => {
            tog_center()
          }}
        >
          + Add Business Type
        </Button>
        <ExportExcel
          data={transformedArray}
          marginTop={"mt-08"}
          name={"Business_Type.xlsx"}
        />
      </Col>
    )
    Add2 = (
      <div className="col-10">
        <Button
          className="btn-theme-color"
          onClick={() => {
            tog_center()
          }}
        >
          + Add Business Type
        </Button>
      </div>
    )
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className="bd-side-header">
        <CardTitle className="h4 text-black">Business Type</CardTitle>
        <p className="card-title-desc">Listing View of Business Type</p>
      </div>

      <div className="bd-responsive-filter">
        <Row className="mb-2 align-item-center">
          <Col md="2">
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {Add1}
        </Row>
      </div>

      {/* Responsive Top section */}

      <div className="bd-responsive-filter2 pb-20">
        <div>
          <div className="display-flex align-item-center">
            {Add2}
            <div className="col-2 text-right">
              <a
                className="btn btn-outline-secondary bd-custom-sidebar"
                onClick={toggleLeftCanvas}
              >
                <i className="fas fa-filter "></i>
              </a>
            </div>
          </div>
        </div>
        {open && (
          <div className="filter-sidebar" id="mySidenav">
            <div className=" display-flex">
              <div className="col-9">Apply Filter</div>
              <div className="col-3 text-right">
                <a className="" onClick={toggleLeftCanvas}>
                  <i className="fas fa-times"></i>
                </a>
              </div>
            </div>
            <div className="pt-20">
              <Row className="mb-2 align-item-center">
                <Col md="2">
                  <select
                    className="form-select"
                    value={pageSize}
                    onChange={onChangeInSelect}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </Row>
            </div>
          </div>
        )}
      </div>

      <div className="table-responsive react-table">
        <Table {...getTableProps()} className="table-striped">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    {...column.getSortByToggleProps()}
                    className={column.className}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                      data-column={cell.column.Header}
                      className={cell.column.className}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      <div>
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto  d-md-block">
            <span className="d-mobile-none"> Page </span>
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <Modal isOpen={modal_center} size="sm" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Business Type</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onAddBusinessType}>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">Business Type</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Business Type"
                onChange={e => setBusinessType(e.target.value)}
                required
              />
            </div>
            <div>
              <Button className="btn btn-primary">Submit</Button>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Edit Zone */}
      <Modal isOpen={editzone} size="sm" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Business Type</h5>
          <button
            type="button"
            onClick={() => {
              setEditZone(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onEditBusinessType}>
            <div className="mb-3 ">
              <Label htmlFor="formrow-firstname-Input">Business Type</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Business Type"
                defaultValue={business_type}
                onChange={e => setBusinessType(e.target.value)}
                required
              />
            </div>
            <div>
              <Button className="btn btn-primary">Submit</Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default BusinessTypeTable
