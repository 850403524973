import Breadcrumb from "components/Common/Breadcrumb"
import React from "react"
import { Container } from "reactstrap"

const Sidebar = () => {
  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title={"SideBar"} breadcrumbItem={"SideBar"} />
            <div>
              <p>ADfsdfsffsd</p>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default Sidebar
