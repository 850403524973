import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import axios from "axios"
import Loader from "pages/seperate/Loader"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import swal from "sweetalert"
import moment from "moment"

const SurgeryTrackForm = () => {
  const [selectedGroup, setselectedGroup] = useState()
  const [product_option, setOptionProduct] = useState()

  const [inputFields, setInputFields] = useState([{}])
  const [inputFields2, setInputFields2] = useState([{}])
  const [inputFields3, setInputFields3] = useState([{}])

  const handleAdd = () => {
    setInputFields([...inputFields, {}])
    setInputFields2([...inputFields2, {}])
    setInputFields3([...inputFields3, {}])
  }

  const handleRemove = (index, e) => {
    if (inputFields.length !== 1) {
      const values = [...inputFields]
      values.splice(index.target.id, 1)
      setInputFields(values)
    }
  }

  const location = useLocation()
  // const zone_names = location.state.zone
  const [csr_option, setCsrOption] = useState([])
  const [csr_asc, setCsrAsc] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const formData = new FormData()
      formData.append("zone_id", localStorage.getItem("auth_z"))
      try {
        const response = await axios({
          method: "post",
          url: "https://api.orthotrack.in/api/zone_state_staff",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {

          const options = response.data.data.states.map(item => ({
            value: item.id,
            label: item.state_name,
          }))

          setOptionState(options)
          setLoading(false)
        })

        // ===============================================
        // getting CSR field options
        const formData2 = new FormData()
        formData2.append("id", localStorage.getItem("authid"))
        formData2.append("record", localStorage.getItem("record"))

        const res = await axios.post("https://api.orthotrack.in/api/csr_data", formData2, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          }
        })

        const options2 = res.data.data.map(items => ({
          value: items.id,
          label: items.name,
        }))

        setCsrAsc(
          options2.find(item => item.id == localStorage.getItem("auth_id"))
        )

        setCsrOption(options2)

      } catch (err) {
        console.log(err)
        swal({
          icon: "error",
          text: "SomeThing Went Wrong",
        })
        setLoading(false)
      }
    }
    fetch()
  }, [])

  const [optionZone, setOptionZone] = useState([])
  const [optionState, setOptionState] = useState([])
  const [optionCity, setOptionCity] = useState([])
  const [optionBusiness, setOptionBusiness] = useState([])
  const [optionChannel, setOptionChannel] = useState([])
  const [optionDoctor, setOptionDoctor] = useState([])
  const [optionHospital, setOptionHospital] = useState([])
  const [optionType, setOptionType] = useState([])
  const [optionBrand, setOptionBrand] = useState([])
  const [optionComponent, setOptionComponent] = useState([])
  const [optionSize, setOptionSize] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [city_id, setCityId] = useState("")
  const [state_id, setStateId] = useState("")
  const [product_type, setProductType] = useState("")
  const [brand_id, setBrandId] = useState("")
  const [zone_id, setZone] = useState([])
  const [business_type, setBusinessType] = useState("")

  useEffect(() => {
    const fetch = async () => {
      try {
        const response1 = axios({
          method: "get",
          url: "https://api.orthotrack.in/api/zones",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const options = response.data.data.map(item => ({
            value: item.id,
            label: item.zone_name,
          }))
          setOptionZone(options)
          setZone(
            options.filter(
              obj => obj.value == localStorage.getItem("auth_z")
            )[0]
          )
        })
      } catch (err) {
        console.error(err)
      }
    }
    fetch()
  }, [])

  useEffect(() => {
    fetch("https://api.orthotrack.in/api/productType")
      .then(res => res.json())
      .then(data => {
        const options = data.data.map(item => ({
          value: item.id,
          label: item.product_type,
        }))
        setOptionProduct(options)
      })
      .catch(err => {
        console.log(err.message)
      })
  }, [])

  useEffect(() => {
    fetch("https://api.orthotrack.in/api/businessType")
      .then(res => res.json())
      .then(data => {
        const options = data.data.map(item => ({
          value: item.id,
          label: item.bussiness_type,
        }))
        setOptionBusiness(options)
      })
      .catch(err => {
        console.log(err.message)
      })
  }, [])

  useEffect(() => {
    fetch("https://api.orthotrack.in/api/productType")
      .then(res => res.json())
      .then(data => {
        const options = data.data.map(item => ({
          value: item.id,
          label: item.product_type,
        }))
        setOptionType(options)
      })
      .catch(err => {
        console.log(err.message)
      })
  }, [])

  const onChangeState = async e => {
    selectInputRef.current.select.clearValue()
    setZone(e)
    setLoading(true)
    const formData = new FormData()
    formData.append("zone_id", e.value)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/zone_state",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.state_name,
        }))
        setOptionState(options)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }
  const selectInputRef = useRef()
  const selectInputRef1 = useRef()
  const selectInputRef2 = useRef()
  const selectInputRef3 = useRef()
  const selectInputRef4 = useRef()
  const selectInputRef5 = useRef()
  const selectInputRef6 = useRef()
  const selectInputRef7 = useRef()

  const [partner, setChannelPartner] = useState("")

  const onChangeCity = async e => {
    selectInputRef1.current.select.clearValue()
    if (e != null) {
      setStateId(e.value)
      setLoading(true)
      const formData = new FormData()
      formData.append("state", e.value)
      try {
        const response = await axios({
          method: "post",
          url: "https://api.orthotrack.in/api/state_city_surgeon",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const options = response.data.data.city.map(item => ({
            value: item.id,
            label: item.district_name,
          }))
          const options2 = response.data.data.channel_partner.map(item => ({
            value: item.id,
            label: item.channel_name,
          }))
          const options3 = response.data.data.doctor.map(item => ({
            value: item.id,
            label: item.doctor_name,
          }))
          setOptionDoctor(options3)
          setOptionChannel(options2)
          setOptionCity(options)
          setLoading(false)
        })
      } catch (err) {
        swal({
          icon: "error",
          text: "SomeThing Went Wrong",
        })
        setLoading(false)
      }
    }
  }

  const onChangeCityHospital = async e => {
    selectInputRef2.current.select.clearValue()
    selectInputRef3.current.select.clearValue()
    selectInputRef4.current.select.clearValue()
    setChannelPartner([])
    if (e != null) {
      setCityId(e.value)
      setLoading(true)
      const formData = new FormData()
      formData.append("city", e.value)
      formData.append("state", state_id)
      try {
        const response = await axios({
          method: "post",
          url: "https://api.orthotrack.in/api/city_hospital_partner",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const options3 = response.data.data.hospital.map(item => ({
            value: item.id,
            label: item.hospital_name,
          }))
          setOptionHospital(options3)
          setLoading(false)
        })
      } catch (err) {
        swal({
          icon: "error",
          text: "SomeThing Went Wrong",
        })
        setLoading(false)
      }
    }
  }

  const onChangeBusiness = async e => {
    selectInputRef2.current.select.clearValue()
    selectInputRef3.current.select.clearValue()
    selectInputRef4.current.select.clearValue()
    setChannelPartner([])
    if (e != null) {
      setBusinessType(e.value)
    }
    setLoading(true)
    const formData = new FormData()
    formData.append("bussiness_type", e.value)
    formData.append("state", state_id)
    formData.append("city", city_id)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/state_city_type",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options2 = response.data.data.doctor.map(item => ({
          value: item.id,
          label: item.doctor_name,
        }))
        const options3 = response.data.data.channel_partner.map(item => ({
          value: item.id,
          label: item.channel_name,
        }))
        setOptionChannel(options3)
        setOptionDoctor(options2)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const onChangeType = async e => {
    selectInputRefs.forEach(ref => {
      if (ref.current) {
        ref.current.select.clearValue()
      }
    })
    selectInputRefs2.forEach(ref => {
      if (ref.current) {
        ref.current.select.clearValue()
      }
    })
    selectInputRefs0.forEach(ref => {
      if (ref.current) {
        ref.current.select.clearValue()
      }
    })

    if (e != null) {
      setProductType(e.value)
    }
    setLoading(true)
    const formData = new FormData()
    formData.append("product_type", e.value)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/type_brand",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.brand_name,
          name: "brand",
        }))
        setOptionBrand(options)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }
  const [brands, setBrands] = useState("")
  const [components, setComponents] = useState("")
  const [sizes, setSizes] = useState("")

  const [arraysOfObjects, setArraysOfObjects] = useState([])

  const selectInputRefs0 = Array.from({ length: 25 }, () => useRef(null))
  const selectInputRefs = Array.from({ length: 25 }, () => useRef(null))
  const selectInputRefs2 = Array.from({ length: 25 }, () => useRef(null))

  const onChangeBrand = async (index, e) => {
    if (selectInputRefs[index].current) {
      selectInputRefs[index].current.select.clearValue()
    } else {
      selectInputRefs[index].current.select.clearValue()
    }

    const values = [...inputFields]
    if (e != null) {
      values[index][e.name] = e.value
      setBrandId(e.value)
    }
    let first_item
    setBrands(
      values.map(item => {
        first_item = item.brand
        return first_item
      })
    )
    setLoading(true)
    const formData = new FormData()
    formData.append("product_type", product_type)
    if (e != null) {
      formData.append("brand_id", e.value)
    }
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/type_brand_component",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.component_name,
          name: "component",
        }))
        setOptionComponent(options)

        setArraysOfObjects(prevArrays => {
          const newArrays = [...prevArrays]
          newArrays[index] = options
          return newArrays
        })

        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const [arraysOfSize, setArraysOfSize] = useState([])

  const onChangeComponent = async (index, e) => {
    if (selectInputRefs2[index].current) {
      selectInputRefs2[index].current.select.clearValue()
    }
    const values = [...inputFields]
    if (e != null) {
      values[index][e.name] = e.value
    }
    let first_item
    setComponents(
      values.map(item => {
        first_item = item.component
        return first_item
      })
    )

    setLoading(true)
    const formData = new FormData()
    formData.append("product_type", product_type)
    formData.append("brand_id", inputFields[index].brand)
    if (e != null) {
      formData.append("component_id", e.value)
    }
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/type_brand_component_size",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.size,
          name: "size",
        }))
        setOptionSize(options)

        setArraysOfSize(prevArrays => {
          const newArrays = [...prevArrays]
          newArrays[index] = options
          return newArrays
        })

        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }
  const onSizeChange = (index, e) => {
    const values = [...inputFields]
    if (e != null) {
      values[index][e.name] = e.value
    }
    let first_item
    setSizes(
      values.map(item => {
        first_item = item.size
        return first_item
      })
    )
  }

  const navigate = useNavigate()

  const [surgery_date, setSurgeryDate] = useState("")

  const [doctor, setDoctor] = useState("")
  const [hospital, setHospital] = useState("")

  const [patient, setPatient] = useState("")

  const [error, setError] = useState(null)

  const onAddSurgery = async e => {
    if (
      surgery_date.length != 0 &&
      zone_id.length != 0 &&
      state_id.length != 0 &&
      city_id.length != 0 &&
      business_type.length != 0 &&
      partner.length != 0 &&
      doctor.length != 0 &&
      hospital.length != 0 &&
      csr_asc.length != 0 && 
      product_type.length != 0 &&
      brands[0] != undefined &&
      brands.length != 0 &&
      components[0] != undefined &&
      components.length != 0 &&
      sizes[0] != undefined &&
      sizes.length != 0
    ) {
      e.preventDefault()
      swal({
        title: "Are you sure?",
        text: "You want to Add this Surgery !",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async willDelete => {
        if (willDelete) {
          setLoading(true)
          const formData = new FormData()
          formData.append("surgery_date", surgery_date)
          formData.append("user_id", localStorage.getItem("authid"))
          formData.append("role_id", localStorage.getItem("authrole_id"))
          formData.append("zone", zone_id?.value)
          formData.append("state", state_id)
          formData.append("city", city_id)
          formData.append("bussiness_type", business_type)
          formData.append("partner", partner?.value)
          formData.append("doctor", doctor)
          formData.append("hospital", hospital)
          formData.append("csr_asc", csr_asc.value)
          formData.append("product_type", product_type)
          formData.append("patient", patient)
          formData.append("brand", brands)
          formData.append("component", components)
          formData.append("size", sizes)
          try {
            const response = await axios({
              method: "post",
              url: "https://api.orthotrack.in/api/storeSurgery",
              data: formData,
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }).then(response => {
              setLoading(false)
              swal({
                icon: "success",
                text: "Surgery Track Added Successfully",
              }).then(data => {
                navigate("/surgery-track")
              })
            })
          } catch (err) {
            swal({
              icon: "error",
              text: "SomeThing Went Wrong",
            })
            setLoading(false)
          }
        }
        // alert('test')
      })
    } else {
      e.preventDefault()
      setError(true)
    }
  }

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1

  const formattedMonth = month < 10 ? "0" + month : month
  const minDate = `${year}-${formattedMonth}-01`
  const maxDate = `${year}-${formattedMonth}-31`
  const today = new Date()
  function subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months)
    return date
  }
  let Mindates
  if (today.getDate() <= 5) {
    let minDayess = new Date(minDate)
    Mindates = subtractMonths(minDayess, 1)
  } else {
    Mindates = minDate
  }
  const SurgeryMin = moment(new Date(Mindates)).format("yyyy-MM-DD")

  // console.log(SurgeryMin)

  let surgeryMinDate
  let surgeryMaxDate

  if (localStorage.getItem("authrole_id") != 1) {
    // surgeryMinDate = SurgeryMin
    surgeryMinDate = "2024-01-01"
    surgeryMaxDate = maxDate
  } else {
    surgeryMinDate = ""
    surgeryMaxDate = ""
  }

  return (
    <div>
      {isLoading && <Loader />}
      <React.Fragment>
        <div className="page-content">
          <Container fluid className="custom-container">
            <Breadcrumb
              title={"Dashboard"}
              breadcrumbItem={"Surgery Track Form"}
            />
            <div>
              <div>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Surgery Details</CardTitle>
                        <form onSubmit={onAddSurgery}>
                          <Row>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputCity">
                                  Surgery date
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  id="form-Input-date"
                                  placeholder="Enter Your Living City"
                                  min={surgeryMinDate}
                                  max={surgeryMaxDate}
                                  onChange={e => setSurgeryDate(e.target.value)}
                                />
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {(surgery_date.length == 0) == error
                                    ? "Please select an option"
                                    : null}
                                </span>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputState">
                                  Zone <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  options={optionZone}
                                  className="select2-selection"
                                  onChange={onChangeState}
                                  value={zone_id}
                                />
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {(zone_id?.length == 0) == error
                                    ? "Please select an option"
                                    : null}
                                </span>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  States <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  options={optionState}
                                  ref={selectInputRef}
                                  onChange={onChangeCity}
                                  className="select2-selection"
                                />
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {(state_id.length == 0) == error
                                    ? "Please select an option"
                                    : null}
                                </span>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  City <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  options={optionCity}
                                  ref={selectInputRef1}
                                  onChange={onChangeCityHospital}
                                  className="select2-selection"
                                />
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {(city_id.length == 0) == error
                                    ? "Please select an option"
                                    : null}
                                </span>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Bussiness Type
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  value={selectedGroup}
                                  options={optionBusiness}
                                  onChange={onChangeBusiness}
                                  className="select2-selection"
                                />
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {(business_type.length == 0) == error
                                    ? "Please select an option"
                                    : null}
                                </span>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Channel Partner{" "}
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  options={optionChannel}
                                  ref={selectInputRef2}
                                  value={partner}
                                  onChange={e => {
                                    setChannelPartner(e)
                                  }}
                                  className="select2-selection"
                                />
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {(partner.length == 0) == error
                                    ? "Please select an option"
                                    : null}
                                </span>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Surgeon Name{" "}
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  options={optionDoctor}
                                  ref={selectInputRef3}
                                  onChange={e => {
                                    if (e != null) {
                                      setDoctor(e.value)
                                    }
                                  }}
                                  className="select2-selection"
                                />
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {(doctor.length == 0) == error
                                    ? "Please select an option"
                                    : null}
                                </span>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Hospital Name{" "}
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  options={optionHospital}
                                  ref={selectInputRef4}
                                  onChange={e => {
                                    if (e != null) {
                                      setHospital(e.value)
                                    }
                                  }}
                                  className="select2-selection"
                                />
                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {(hospital.length == 0) == error
                                    ? "Please select an option"
                                    : null}
                                </span>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Patient Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-InputCity"
                                  placeholder="Enter  Patient Name"
                                  onChange={e => setPatient(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  CSR/ASC/TL{" "}
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  options={csr_option}
                                  value={csr_asc}
                                  onChange={e => {
                                    if (e != null) {
                                      setCsrAsc(e)
                                    }
                                  }}
                                  className="select2-selection"
                                />

                                <span
                                  className="text-danger"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {(csr_asc?.length == 0) == error
                                    ? "Please select an option"
                                    : null}
                                </span>
                              </div>
                            </Col>
                            <Col lg={4} className="mb-3">
                              <label htmlFor="name">
                                Product Type{" "}
                                <span className="text-danger"> *</span>
                              </label>
                              <Select
                                value={selectedGroup}
                                options={optionType}
                                onChange={onChangeType}
                                className="select2-selection"
                              />
                              <span
                                className="text-danger"
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                              >
                                {(product_type.length == 0) == error
                                  ? "Please select an option"
                                  : null}
                              </span>
                            </Col>

                            <div className="pt-15">
                              <CardTitle className="mb-4">
                                Product Details
                              </CardTitle>
                            </div>
                            <div>
                              {inputFields.map((item, index) => {
                                const ComponentOption = arraysOfObjects[index]
                                const SizeOption = arraysOfSize[index]

                                return (
                                  <Row key={index} className="pt-10">
                                    <Col
                                      lg={3}
                                      className="mb-3 bd-incremental-input"
                                    >
                                      <label htmlFor="email">
                                        Product Name{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <Select
                                        value={selectedGroup}
                                        options={optionBrand}
                                        ref={selectInputRefs0[index]}
                                        onChange={e => {
                                          onChangeBrand(index, e)
                                        }}
                                        name="brand"
                                        className="select2-selection"
                                      />
                                      <span
                                        className="text-danger"
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {(brands[0] == undefined ||
                                          brands.length == 0) == error
                                          ? "Please select an option"
                                          : null}
                                      </span>
                                    </Col>
                                    <Col
                                      lg={3}
                                      className="mb-3 bd-incremental-input"
                                    >
                                      <label htmlFor="subject">
                                        Component{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <Select
                                        value={selectedGroup}
                                        options={ComponentOption}
                                        // options={ComponentOption}
                                        ref={selectInputRefs[index]}
                                        onChange={e => {
                                          onChangeComponent(index, e)
                                        }}
                                        name="component"
                                        className="select2-selection"
                                      />
                                      <span
                                        className="text-danger"
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {(components[0] == undefined ||
                                          components.length == 0) == error
                                          ? "Please select an option"
                                          : null}
                                      </span>
                                    </Col>

                                    <Col
                                      lg={3}
                                      className="mb-3 bd-incremental-input"
                                    >
                                      <label htmlFor="resume">
                                        Size{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <Select
                                        value={selectedGroup}
                                        options={SizeOption}
                                        // options={optionSize}
                                        ref={selectInputRefs2[index]}
                                        onChange={e => onSizeChange(index, e)}
                                        name="size"
                                        className="select2-selection"
                                      />
                                      <span
                                        className="text-danger"
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {(sizes[0] == undefined ||
                                          sizes.length == 0) == error
                                          ? "Please select an option"
                                          : null}
                                      </span>
                                    </Col>
                                    <Col
                                      lg={1}
                                      className="align-self-center bd-incremental-input-delete mt-10"
                                    >
                                      <div className="d-grid">
                                        {/* <input
                                      type="button"
                                      className="btn btn-primary"
                                      value="-"
                                      onClick={() =>
                                        onDeleteFormRow(formRow.id)
                                      }
                                    /> */}
                                        <a
                                          className="btn btn-danger"
                                          id={index}
                                          onClick={handleRemove}
                                        >
                                          <i className="bx bxs-minus-square"></i>
                                        </a>
                                      </div>
                                    </Col>
                                  </Row>
                                )
                              })}
                              <div className="mt-30 text-right">
                                <input
                                  type="button"
                                  className="btn btn-success btn-theme-color mt-3 mt-lg-0"
                                  value="Add New Row"
                                  onClick={handleAdd}
                                />
                              </div>
                            </div>
                          </Row>
                          <div className="mt-50">
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default SurgeryTrackForm
