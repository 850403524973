import Breadcrumb from "components/Common/Breadcrumb"
import React, { useState, useEffect } from "react"
import {
    Card,
    CardBody,
    Col,
    Container,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap"
import axios from "axios"
import classnames from "classnames"

import ActivityTable from "./ActivityTable"
import Loader from "pages/seperate/Loader"


const ActivityLog = () => {
    const [verticalActiveTab, setverticalActiveTab] = useState("1")

    const [surgeryActivity, setSurgeryActivity] = useState([])
    const [masterActivity, setMasterActivity] = useState([])
    const [staffActivity, setStaffActivity] = useState([])
    const [rolesActivity, setRolesActivity] = useState([])
    const [targetActivity, setTargetActivity] = useState([])

    const [from_date, setFromDate] = useState("")
    const [to_date, setToDate] = useState("")

    const [loading, setLoading] = useState("1")

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab)
        }
        document.getElementById("mySidenav-master").style.width = "0"
    }

    const toggleLeftCanvas = () => {
        document.getElementById("mySidenav-master").style.width = "215px"
    }
    const toggleLeftCanvasClose = () => {
        document.getElementById("mySidenav-master").style.width = "0"
    }


    useEffect(() => {
        const id = localStorage.getItem("authrole_id")
        const fetch = async () => {
            setLoading(true)
            try {
                const { data } = await axios.get(
                    `https://api.orthotrack.in/api/allLogs`
                )

                setSurgeryActivity(
                    data.data.filter((item) => item.module_id == 1)
                )

                setMasterActivity(
                    data.data.filter((item) => item.module_id == 2)
                )

                setStaffActivity(
                    data.data.filter((item) => item.module_id == 3)
                )

                setRolesActivity(
                    data.data.filter((item) => item.module_id == 5)
                )

                setTargetActivity(
                    data.data.filter((item) => item.module_id == 6)
                )

                setLoading(false)
            } catch (err) {
                console.error(err)
                setLoading(false)
            }
        }
        fetch()
    }, [])

    const filterData = async () => {
        if (from_date != "" && to_date != "") {
            setLoading(true)
            try {
                const formData = new FormData()
                formData.append("from", from_date)
                formData.append("to", to_date)

                const response = await axios.post("https://api.orthotrack.in/api/allLogsFilter", formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                })

                // console.log(response.data)

                setSurgeryActivity(
                    response.data.data.filter((item) => item.module_id == 1)
                )

                setMasterActivity(
                    response.data.data.filter((item) => item.module_id == 2)
                )

                setStaffActivity(
                    response.data.data.filter((item) => item.module_id == 3)
                )

                setRolesActivity(
                    response.data.data.filter((item) => item.module_id == 5)
                )

                setTargetActivity(
                    response.data.data.filter((item) => item.module_id == 6)
                )


                setLoading(false)

            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        } else {
            swal("", "Form_date and To_date are mandatory Fields!", "warning")
        }
    }

    return (
        <div>
            <React.Fragment>
                {
                    loading && <Loader />
                }
                <div className="page-content">
                    <Container fluid className="custom-container">
                        <Breadcrumb title={"Dashboard"} breadcrumbItem={"Activity Logs"} />
                        <div>
                            <div>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody style={{ minHeight: "480px" }}>

                                                <Row className="mb-4 align-items-end">
                                                    <Col lg={3}>
                                                        <Label htmlFor="formrow-firstname-Input">
                                                            From
                                                        </Label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Enter Zone Name"
                                                            required
                                                            onChange={e => setFromDate(e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Label htmlFor="formrow-firstname-Input">
                                                            To
                                                        </Label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Enter Zone Name"
                                                            onChange={e => setToDate(e.target.value)}
                                                        />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <button
                                                            onClick={filterData}
                                                            className="btn  btn-theme-color">Search Logs</button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12" className="mb-4">
                                                        <Nav pills className="flex-row nav-responsive border-bottom">
                                                            <NavItem>
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        active: verticalActiveTab === "1",
                                                                    })}
                                                                    onClick={() => {
                                                                        toggleVertical("1")
                                                                    }}
                                                                >
                                                                    Surgery Track
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        active: verticalActiveTab === "2",
                                                                    })}
                                                                    onClick={() => {
                                                                        toggleVertical("2")
                                                                    }}
                                                                >
                                                                    Masters
                                                                </NavLink>
                                                            </NavItem>

                                                            <NavItem>
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        active: verticalActiveTab === "3",
                                                                    })}
                                                                    onClick={() => {
                                                                        toggleVertical("3")
                                                                    }}
                                                                >
                                                                    Staff
                                                                </NavLink>
                                                            </NavItem>

                                                            <NavItem >
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        active: verticalActiveTab === "4",
                                                                    })}
                                                                    onClick={() => {
                                                                        toggleVertical("4")
                                                                    }}
                                                                >
                                                                    Roles & Permissions
                                                                </NavLink>
                                                            </NavItem>

                                                            <NavItem>
                                                                <NavLink
                                                                    style={{ cursor: "pointer" }}
                                                                    className={classnames({
                                                                        active: verticalActiveTab === "5",
                                                                    })}
                                                                    onClick={() => {
                                                                        toggleVertical("5")
                                                                    }}
                                                                >
                                                                    Targets
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>

                                                        {/* Mobile Sidebar */}
                                                        <div className="Nav-dropdown-resp">
                                                            <div className="text-right bd-side-nav-master">
                                                                <a onClick={toggleLeftCanvas}>
                                                                    <i className="fas fa-bars "></i>
                                                                </a>
                                                            </div>
                                                            <div
                                                                className="sidebar-nav-master"
                                                                id="mySidenav-master"
                                                            >
                                                                <div className="bd-whole-navber">
                                                                    <div className="col-12 mb-10 text-right ">
                                                                        <a onClick={toggleLeftCanvasClose}>
                                                                            <i className="fas fa-times"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div>
                                                                        <Nav pills className="flex-column ">
                                                                            <NavItem>
                                                                                <NavLink
                                                                                    style={{ cursor: "pointer" }}
                                                                                    className={classnames({
                                                                                        active: verticalActiveTab === "1",
                                                                                    })}
                                                                                    onClick={() => {
                                                                                        toggleVertical("1")
                                                                                    }}
                                                                                >
                                                                                    Surgery Track
                                                                                </NavLink>
                                                                            </NavItem>
                                                                            <NavItem>
                                                                                <NavLink
                                                                                    style={{ cursor: "pointer" }}
                                                                                    className={classnames({
                                                                                        active: verticalActiveTab === "2",
                                                                                    })}
                                                                                    onClick={() => {
                                                                                        toggleVertical("2")
                                                                                    }}
                                                                                >
                                                                                    Masters
                                                                                </NavLink>
                                                                            </NavItem>

                                                                            <NavItem>
                                                                                <NavLink
                                                                                    style={{ cursor: "pointer" }}
                                                                                    className={classnames({
                                                                                        active: verticalActiveTab === "3",
                                                                                    })}
                                                                                    onClick={() => {
                                                                                        toggleVertical("3")
                                                                                    }}
                                                                                >
                                                                                    Staff
                                                                                </NavLink>
                                                                            </NavItem>

                                                                            <NavItem>
                                                                                <NavLink
                                                                                    style={{ cursor: "pointer" }}
                                                                                    className={classnames({
                                                                                        active: verticalActiveTab === "4",
                                                                                    })}
                                                                                    onClick={() => {
                                                                                        toggleVertical("4")
                                                                                    }}
                                                                                >
                                                                                    Roles & Permissions
                                                                                </NavLink>
                                                                            </NavItem>

                                                                            <NavItem>
                                                                                <NavLink
                                                                                    style={{ cursor: "pointer" }}
                                                                                    className={classnames({
                                                                                        active: verticalActiveTab === "5",
                                                                                    })}
                                                                                    onClick={() => {
                                                                                        toggleVertical("5")
                                                                                    }}
                                                                                >
                                                                                    Targets
                                                                                </NavLink>
                                                                            </NavItem>
                                                                        </Nav>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col md="12" className="">
                                                        <TabContent
                                                            activeTab={verticalActiveTab}
                                                            className="text-muted mt-4 mt-md-0"
                                                        >
                                                            <TabPane tabId="1">
                                                                <ActivityTable heading="Surgery Track" activities={surgeryActivity} />
                                                            </TabPane>
                                                            <TabPane tabId="2">
                                                                <ActivityTable heading="Masters" activities={masterActivity} />
                                                            </TabPane>
                                                            <TabPane tabId="3">
                                                                <ActivityTable heading="Staff" activities={staffActivity} />
                                                            </TabPane>

                                                            <TabPane tabId="4">
                                                                <ActivityTable heading="Roles & Permissions"
                                                                    activities={rolesActivity} />
                                                            </TabPane>

                                                            <TabPane tabId="5">
                                                                <ActivityTable heading="Surgery" activities={targetActivity} />
                                                            </TabPane>

                                                        </TabContent>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        </div>
    )
}

export default ActivityLog
