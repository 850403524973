import axios from "axios"
import { isNumber } from "lodash"
import Loader from "pages/seperate/Loader"
import React, { useEffect, useMemo, useState } from "react"
import ReactApexChart from "react-apexcharts"
import Select from "react-select"
import { Button, Card, CardBody } from "reactstrap"
import swal from "sweetalert"

const ProductMix = ({ zone_options }) => {
    const [isLoading, setLoading] = useState(false)

    const [optionState, setOptionState] = useState([])

    const [start_date, setStartDate] = useState("")
    const [end_date, setEndDate] = useState("")
    const [zones, setZones] = useState([])
    const [states, setStates] = useState([])

    const [topTenSurgeons, setTopTenSurgeons] = useState([])

    const [product_option, setOptionProduct] = useState([])
    const [productType, setProductType] = useState([])

    const [optionBrand, setOptionBrand] = useState([])
    const [brands, setBrands] = useState([])


    useEffect(() => {
        fetch("https://api.orthotrack.in/api/productType")
            .then(res => res.json())
            .then(data => {
                const options = data.data.map(item => ({
                    value: item.id,
                    label: item.product_type,
                }))
                // { label: 'All Product Types', value: 0 },
                // console.log(options)
                setOptionProduct(options)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const onZoneChange = async e => {
        if (e === null) {
            setOptionState([])
            setZones([])
            return
        } else {
            setZones(e)
        }

        const formData = new FormData()

        let zones_ids;
        if (e?.some(z => z.value == 0))
            zones_ids = zone_options?.map(z => z.value).toString()
        else
            zones_ids = e?.slice(-1)[0].value

        // if (e?.length != 0) {
        formData.append("zone_id", zones_ids)
        // }
        try {
            const response = await axios.post(
                "https://api.orthotrack.in/api/zone_state",
                formData,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            const options = response.data.data.map(item => ({
                value: item.id,
                label: item.state_name,
            }))
            if (e.length != 0) {
                setOptionState([{ label: 'All States', value: 0 }, ...optionState.slice(1), ...options])
            }
        } catch (err) {
            swal({
                icon: "error",
                text: "SomeThing Went Wrong",
            })
        }
    }

    const onChangeType = async e => {

        if (e != null) {
            setProductType(e)
        }
        setBrands([])
        setLoading(true)
        const formData = new FormData()
        formData.append("product_type", e.value)
        try {
            const response = await axios({
                method: "post",
                url: "https://api.orthotrack.in/api/type_brand",
                data: formData,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {
                const options = response.data.data.map(item => ({
                    value: item.id,
                    label: item.brand_name,
                    name: "brand",
                }))
                setOptionBrand([{ value: 0, label: 'All Brands' }, ...options])
                setLoading(false)
            })
        } catch (err) {
            swal({
                icon: "error",
                text: "SomeThing Went Wrong",
            })
            setLoading(false)
        }
    }

    const getChartData = async e => {
        e.preventDefault()

        if (!zones || zones?.length === 0) {
            swal("", "Zones Field is Required!", "warning")
            return
        }
        if (!states || states?.length === 0) {
            swal("", "States Field is Required!", "warning")
            return
        }
        if (!productType || productType?.length === 0) {
            swal("", "Product Type Field is Required!", "warning")
            return
        }

        if (!brands || brands?.length === 0) {
            swal("", "Brands Field is Required!", "warning")
            return
        }

        try {
            setLoading(true)
            const formData = new FormData()


            let zones_ids;
            if (zones?.some(z => z.value == 0))
                zones_ids = zone_options?.map(z => z.value).toString()
            else
                zones_ids = zones?.map(z => z.value).toString()


            let all_states_id;
            if (states?.some(z => z.value == 0)) {
                all_states_id = optionState.map(s => s.value).slice(1).toString()
            } else {
                all_states_id = states.map(s => s.value).toString()
            }

            formData.append("start_date", start_date)
            formData.append("end_date", end_date)
            formData.append("zone", zones_ids)
            formData.append("state", all_states_id)
            formData.append("product_type", productType?.value)

            const response = await axios.post(
                "https://api.orthotrack.in/api/productMixSurgeon", formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                },
            }
            )

            const final_data = response.data?.map((item, index) => {

                const datas = item?.surgery?.map((surg => {

                    const countsdata = surg?.brands?.reduce((acc, curr) => {
                        acc[curr.brand_name] = (acc[curr.brand_name] || 0) + 1;
                        return acc;
                    }, {});

                    return countsdata
                }))

                const counts = {
                    // total_surgery: item?.surgery?.length
                };

                datas?.forEach(obj => {
                    Object.keys(obj).forEach(key => {
                        counts[key] = (counts[key] || 0) + obj[key];
                    });
                });

                // console.log('surgeon : ' + index , counts)

                const brand_count = brands?.map(b => {
                    let tempp = Object.keys(counts).find(c => c == b.label)
                    if (tempp) {
                        return counts[tempp];
                    }
                    return 0;
                })

                return ({
                    name: item?.doctor_name,
                    data: brand_count
                })
            })

            // console.log("final_data",final_data);
            setTopTenSurgeons(final_data)

            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log(err)
            swal({
                icon: "error",
                text: "SomeThing Went Wrong",
            })
        }
    }


    const final__data = brands?.map((brand, i) => ({
        name: brand.label,
        data: topTenSurgeons.map(ten => ten.data[i])
    }))


    // console.log('first', final__data)
    // [
    //     "1,0,1,1,1",
    //     "2,0,1,1,1"
    // ]


    const productmix_data = useMemo(
        () => ({
            series: final__data,
            options: {
                chart: {
                    type: "bar",
                    height: 350,
                    stacked: true,
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "20%",
                        endingShape: "rounded",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    categories: topTenSurgeons.map(item => item.name),
                },
                yaxis: {
                    title: {
                        text: 'Product Mix'
                    },
                },
                fill: {
                    opacity: 1,
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + ""
                        },
                    },
                },
                colors: ['#ffe119', '#bfef45', '#3cb44b', '#42d4f4', '#911eb4', ' #f032e6', '#800000', '#9A6324', '#808000', '#469990', '#000075', '#000000', '#e6194B', ' #a04000', '#f58231'
                ]
            },
        }),
        [topTenSurgeons]
    )

    return (
        <div>
            {isLoading && <Loader />}
            <Card>
                <CardBody>
                    <div>
                        <form onSubmit={getChartData}>
                            <div className="d-flex justify-space-between">
                                <h4 className="card-title mb-5">
                                    Product Mix-Surgeons
                                </h4>
                                <div className="text-end mb-3">
                                    <Button type="submit" className="btn-theme-color">
                                        <i
                                            className="fas fa-filter pr-10"
                                            style={{ fontSize: "10px" }}
                                        ></i>
                                        Filter Now
                                    </Button>

                                    <Button
                                        className="btn-theme-color bg-danger ms-3 text-white"
                                        type="button"
                                        onClick={() => {
                                            setTopTenSurgeons([])
                                            setStartDate("")
                                            setEndDate("")
                                            setStates([])
                                            setZones([])
                                            setOptionState([])
                                            setProductType([])
                                            setBrands([])
                                        }}
                                    >
                                        <i
                                            className="fas fa-times pr-10"
                                            style={{ fontSize: "10px" }}
                                        ></i>
                                        Clear
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <div className="row mb-4">
                                    <div className="col-md-2">
                                        <label htmlFor="">From Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={start_date}
                                            onChange={e => setStartDate(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="">To Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={end_date}
                                            onChange={e => setEndDate(e.target.value)}
                                            required
                                        />
                                    </div>


                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="">Zone</label>
                                        <Select
                                            placeholder="Select Zone"
                                            isMulti
                                            options={[{ label: 'All Zones', value: 0 }, ...zone_options]}
                                            value={zones}
                                            menuPlacement="auto"
                                            // minMenuHeight={200}
                                            onChange={onZoneChange}
                                        />
                                    </div>

                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="">State</label>
                                        <Select
                                            placeholder="Select State"
                                            isMulti
                                            options={optionState}
                                            value={states}
                                            onChange={e => setStates(e)}
                                            menuPlacement="auto"
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label htmlFor="">Product Type</label>
                                        <Select
                                            placeholder="Select Product"
                                            // isMulti
                                            options={product_option}
                                            value={productType}
                                            onChange={onChangeType}
                                            menuPlacement="top"

                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label htmlFor="">Brand Name</label>
                                        <Select
                                            placeholder="Select Product"
                                            isMulti
                                            options={optionBrand}
                                            value={brands}
                                            onChange={(e) => {
                                                if (e?.some(b => b.value == 0))
                                                    setBrands(optionBrand.slice(1))
                                                else
                                                    setBrands(e)

                                            }}
                                            menuPlacement="auto"

                                        />
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>
            {topTenSurgeons.length > 0 && (
                <>
                    <Card>
                        <CardBody>
                            <div className=" border-secondary">
                                <h5 className="fw-bold text-center">Product Mix-Surgeons</h5>
                                <ReactApexChart
                                    options={productmix_data?.options}
                                    series={productmix_data?.series}
                                    type="bar"
                                    height={350}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </>
            )}
        </div>
    )
}

export default ProductMix
