import React, { useMemo, useState } from "react"
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table"
import Select from "react-select"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Form,
  Label,
  CardTitle,
} from "reactstrap"
import GlobalFilter from "pages/seperate/GlobalFilter"
import { Link } from "react-router-dom"

const ProductTable = () => {
  const data2 = [
    {
      id: "01",
      product: "Product1",
      brand: "Ouro",
      component: "Femur",
      variant: "PV001",
      size: "PD15MM",
    },
    {
      id: "02",
      product: "Product2",
      brand: "Ouro Cr",
      component: "Tibia",
      variant: "PV002",
      size: "PD16MM",
    },
    {
      id: "03",
      product: "Product3",
      brand: "Genius",
      component: "Insert",
      variant: "PV003",
      size: "PD17MM",
    },
    {
      id: "04",
      product: "Product4",
      brand: "Indus",
      component: "Patella",
      variant: "PV004",
      size: "PD15.5MM",
    },
    {
      id: "05",
      product: "Product5",
      brand: "Evocon",
      component: "Ext.ROD",
      variant: "PV005",
      size: "PD19MM",
    },
    {
      id: "06",
      product: "Product6",
      brand: "Rotocon",
      component: "IM Plug",
      variant: "PV006",
      size: "PD19MM",
    },
    {
      id: "07",
      product: "Product7",
      brand: "Avatar",
      component: "Metal Head",
      variant: "PV007",
      size: "PD19MM",
    },
    {
      id: "08",
      product: "Product8",
      brand: "Avatar NX ",
      component: "Bipolar",
      variant: "PV008",
      size: "PD19MM",
    },
  ]

  const data = useMemo(() => data2, [])
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        className: "id-width",
      },
      {
        Header: "Product Name",
        accessor: "product",
        filterable: true,
      },
      {
        Header: "Brand",
        accessor: "brand",
        filterable: true,
      },
      {
        Header: "Component ",
        accessor: "component",
        filterable: true,
      },
      {
        Header: "Variant ",
        accessor: "variant",
        filterable: true,
      },
      {
        Header: "Size",
        accessor: "size",
        filterable: true,
      },
      {
        Header: "Action",
        filterable: true,
        className: "product-width border-botttom-black",
        Cell: cellProps => {
          return (
            <>
              <div className="row ">
                <div className="bio-edit-btn">
                  <Link
                    to="/edit-product"
                    className="btn btn-outline-secondary btn-sm edit "
                    title="Edit"
                  >
                    <i className="fas fa-pencil-alt"></i>
                  </Link>
                </div>
                <div className="bio-edit-btn">
                  <a className="btn btn-danger btn-sm edit">
                    <i className="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const [modal_center, setmodal_center] = useState(false)
  const [editState, setEditState] = useState(false)

  function tog_center() {
    setmodal_center(!modal_center)
  }

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    gotoPage,
    pageCount,
    canNextPage,
    setPageSize,
    previousPage,
    pageOptions,
    state,
    setGlobalFilter,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        pageIndex: 0,
        pageSize: 4,
        pageSize: 4,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px", color: "#00000040" }}
          ></i>
        </>
      )
    ) : (
      <>
        <i
          className="fas fa-long-arrow-alt-down"
          style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
        ></i>
        <i
          className="fas fa-long-arrow-alt-up "
          style={{ fontSize: "11px", color: "#00000040" }}
        ></i>
      </>
    )
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [open, setOpen] = useState(false)

  const toggleLeftCanvas = () => {
    setOpen(!open)
  }

  return (
    <div>
      <div className="bd-responsive-filter">
        <div>
          <CardTitle className="h4 text-black">Products</CardTitle>
          <p className="card-title-desc">Listing View of Products</p>
        </div>
        <Row className="mb-2 align-item-center">
          <Col md="2">
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          <Col md="2" className="w-50 bd-responsive-btn text-right">
            <Link to="/add-product">
              <Button className="btn-theme-color">+ Add Product</Button>
            </Link>
          </Col>
        </Row>
      </div>

      {/* Responsive Top section */}

      <div className="bd-responsive-filter2 pb-20">
        <div>
          <div className="display-flex align-item-center">
            <div className="col-10">
              <Link to={"/add-surgery-track"}>
                <Button className="btn-theme-color">+ Add Product</Button>
              </Link>
            </div>
            <div className="col-2 text-right">
              <a
                className="btn btn-outline-secondary bd-custom-sidebar"
                onClick={toggleLeftCanvas}
              >
                <i className="fas fa-filter "></i>
              </a>
            </div>
          </div>
        </div>
        {open && (
          <div className="filter-sidebar" id="mySidenav">
            <div className=" display-flex">
              <div className="col-9">Apply Filter</div>
              <div className="col-3 text-right">
                <a className="" onClick={toggleLeftCanvas}>
                  <i className="fas fa-times"></i>
                </a>
              </div>
            </div>
            <div className="pt-20">
              <Row className="mb-2 align-item-center">
                <Col md="2">
                  <select
                    className="form-select"
                    value={pageSize}
                    onChange={onChangeInSelect}
                  >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </Row>
            </div>
          </div>
        )}
      </div>

      <div className="table-responsive react-table">
        <Table {...getTableProps()} className="table-striped">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.className}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                      data-column={cell.column.Header}
                      className={cell.column.className}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      <div>
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-md-block">
            <span className="d-mobile-none"> Page </span>
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ProductTable
