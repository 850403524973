import Breadcrumb from "components/Common/Breadcrumb"
import React, { useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"

const EditProduct = () => {
  const optionGroup = [
    {
      options: [
        { label: "GSM", value: "GSM" },
        { label: "RSM", value: "RSM" },
        { label: "ASM", value: "ASM" },
        { label: "Scrub boy", value: "Scrub boy" },
      ],
    },
  ]

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid className="custom-container">
            <Breadcrumb
              title={"Dashboard"}
              breadcrumbItem={"Product Form"}
            />
            <div>
              <div>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Edit Product</CardTitle>

                        <form>
                          <Row>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Product Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Enter Product Name"
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Select Brand
                                </Label>
                                <Select
                                  options={optionGroup}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Select Component
                                </Label>
                                <Select
                                  options={optionGroup}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Select Varient
                                </Label>
                                <Select
                                  options={optionGroup}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3 ">
                                <Label htmlFor="formrow-firstname-Input">
                                  Select Size
                                </Label>
                                <Select
                                  options={optionGroup}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-50">
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default EditProduct
