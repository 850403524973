import React from "react"
import * as XLSX from "xlsx"
import { saveAs } from "file-saver"

const ExportExcel = ({ data, marginTop , name }) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data)

    const headerCellStyle = {
      fill: {
        patternType: "none",
        fgColor: { rgb: "FF000000" },
        bgColor: { rgb: "FF000000" },
      },
    } 
    
    // Yellow background
    Object.keys(worksheet).forEach(cell => {
      if (cell.endsWith("1")) {
        worksheet[cell].s = headerCellStyle
      }
    })

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    })

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
    saveAs(blob, name)
  }

  return (
    <div className={marginTop} >
      <a
        className={"btn-theme-color ml-10 " }
        style={{ borderRadius: "4px" }}
        onClick={exportToExcel}
      >
        <i className="fas fa-download pr-10" style={{ fontSize: "10px" }}></i>
        Export
      </a>
    </div>
  )
}

export default ExportExcel
