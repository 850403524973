import React from "react"
import { Col } from "reactstrap"

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label" style={{marginBottom:"0px"}}>
              <span id="search-bar-0-label" className="sr-only" >
                Search this table
              </span>
              <input
                onChange={e => {
                  setFilter(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder="Search ..."
                value={filter || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default GlobalFilter
