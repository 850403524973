import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import OTP from "../pages/Authentication/PasswordOtp"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Master from "../pages/Master/index"
import SurgeryTrack from "../pages/SurgeryTrack/index"
import Staff from "../pages/Staff/index"
import Product from "../pages/Product/index"
import Roles from "../pages/Roles/index"
import Sidebar from "pages/Sidebar"
import SurgeryTrackForm from "pages/SurgeryTrack/SurgeryTrackForm"
import StaffRegistration from "pages/Staff/StaffRegistration"
import ProductForm from "pages/Product/productForm"
import EditSurgeyFrom from "pages/SurgeryTrack/EditSurgeyFrom"
import EditStaff from "pages/Staff/EditStaff"
import EditProduct from "pages/Product/EditProduct"
import AddRoles from "pages/Roles/AddRoles"
import EditRoles from "pages/Roles/EditRole"
import Surgery from "pages/Reports/Surgery"
import SurgeonReport from "pages/Reports/SurgeonReport"
import UserReport from "pages/Reports/UserReport"
import Reset from "pages/Authentication/Reset"
import Index from "pages/Tragets/Index"
import ActivityLog from "pages/ActivityLogs/ActivityLog"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //profile
  { path: "/targets", component: <Index /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/sidebar", component: <Sidebar /> },
  { path: "/master", component: <Master /> },
  { path: "/surgery-track", component: <SurgeryTrack /> },
  { path: "/product", component: <Product /> },
  { path: "/add-surgery-track", component: <SurgeryTrackForm /> },
  { path: "/edit-surgery-track/:id", component: <EditSurgeyFrom /> },
  { path: "/add-staff", component: <StaffRegistration /> },
  { path: "/edit-staff/:id", component: <EditStaff /> },
  { path: "/add-product", component: <ProductForm /> },
  { path: "/edit-product", component: <EditProduct /> },
  { path: "/staff", component: <Staff /> },
  { path: "/roles-and-permissions", component: <Roles /> },
  { path: "/add-roles", component: <AddRoles /> },
  { path: "/edit-roles/:id", component: <EditRoles /> },
  { path: "/report", component: <Surgery /> },
  { path: "/surgeon-report", component: <SurgeonReport /> },
  { path: "/user-report", component: <UserReport /> },
  { path: "/activity-logs", component: <ActivityLog /> },

  { path: "*", component: <Dashboard /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/reset-password", component: <Reset /> },
  { path: "/otp", component: <OTP /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }


// https://api.orthotrack.in/aapi/
// https:/ztpl.net/admin/aapi/
