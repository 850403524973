import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import Loader from "pages/seperate/Loader"

const SidebarContent = props => {
  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  function sideToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    }
  }

  const [isLoading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])

  useEffect(() => {
    const id = localStorage.getItem("authrole_id")
    const fetch = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `https://api.orthotrack.in/api/roles/${id}`
        )
        setRoles(data.data.permission)
        setLoading(false)
      } catch (err) {
        console.error(err)
        setLoading(false)
      }
    }
    fetch()
  }, [])

  const [isShown, setShown] = useState(false)

  // getting permitted modules
  const allRoles = roles.map((item, i) => {
    if (item.module_id == 1 && item.view == 1) {
      return (
        <li key={i}>
          <Link
            to="/surgery-track"
            className="has-arrow1"
            onClick={() => sideToggle()}
          >
            <i className="fas fa-syringe"></i>
            <span>{props.t("Surgery Track")}</span>
          </Link>
        </li>
      )
    }

    if (item.module_id == 2 && item.view == 1) {
      return (
        <li key={i}>
          <Link
            to="/master"
            className="has-arrow1"
            onClick={() => sideToggle()}
          >
            <i className="bx bx-customize"></i>
            <span>{props.t("Masters")}</span>
          </Link>
        </li>
      )
    }

    if (item.module_id == 3 && item.view == 1) {
      return (
        <li key={i}>
          <Link to="/staff" className="has-arrow1" onClick={() => sideToggle()}>
            <i className="fas fa-user-friends"></i>
            <span>{props.t("Staff")}</span>
          </Link>
        </li>
      )
    }

    if (item.module_id == 4 && item.view == 1) {
      return (
        // <ul className="metismenu list-unstyled" id="side-menu">
        <li key={i} className={isShown ? "mm-active" : ""}>
          <Link
            to=""
            className={isShown ? "has-arrow" : "has-arrow  mm-collapsed"}
            onClick={() => {
              setShown(current => !current)
            }}
          >
            <i className="bx bx-file"></i>
            <span>{props.t("Reports")}</span>
          </Link>

          <ul
            className={isShown ? "sub-menu " : "sub-menu mm-collapse"}
            aria-expanded="true"
          >
            <li>
              <Link onClick={() => sideToggle()} to="/report">{props.t("Surgery Track Report")}</Link>
            </li>
            {
              localStorage.getItem("record") == 1 &&
              <>
                <li>
                  <Link onClick={() => sideToggle()} to="/surgeon-report">{props.t("Surgeon Wise Report")}</Link>
                </li>
                <li>
                  <Link onClick={() => sideToggle()} to="/user-report">{props.t("User Wise Report")}</Link>
                </li>
              </>
            }
          </ul>
        </li>
        // </ul>/
      )
    }

    if (item.module_id == 5 && item.view == 1) {
      return (
        <li key={i}>
          <Link to="/roles-and-permissions" className="has-arrow1" onClick={() => sideToggle()}>
            <i className="fas fa-user-lock"></i>
            <span>{props.t("Roles & Permissions")}</span>
          </Link>
        </li>
      )
    }

    if (item.module_id == 6 && item.view == 1) {
      return (
        <li key={i}>
          <Link
            to="/targets"
            className="has-arrow1"
            onClick={() => sideToggle()}
          >
            <i className="fas fa-bullseye"></i>
            <span>{props.t("Targets")}</span>
          </Link>
        </li>
      )
    }

    if (item.module_id == 7 && item.view == 1) {
      return (
        <li key={i}>
          <Link
            to="/activity-logs"
            className="has-arrow1"
            onClick={() => sideToggle()}
          >
            <i className="fas fa-align-center "></i>
            <span>{props.t("Activity Logs")}</span>
          </Link>
        </li>
      )
    }
  })

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <div>
            <div
              className={
                window.screen.width >= 998 ? "display-none" : "text-right pr-20"
              }
            >
              <a
                onClick={() => {
                  tToggle()
                }}
                style={{ padding: "10px" }}
              >
                <i className="fas fa-times"></i>
              </a>
            </div>
          </div>
          <ul className="metismenu list-unstyled mt-25" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/" className="has-arrow1" onClick={() => sideToggle()}>
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
            </li>

            {
              allRoles.map((item) => item)
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
