import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Modal,
  Form,
  Button,
} from "reactstrap"
import Select from "react-select"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import Loader from "pages/seperate/Loader"
import moment from "moment"

const EditSurgeyFrom = () => {
  const [selectedGroup, setselectedGroup] = useState({
    label: "South Zone",
    value: "South Zone",
  })
  const optionGroup = [
    {
      options: [
        { label: "South Zone", value: "South Zone" },
        { label: "North Zone", value: "North Zone" },
        { label: "East Zone", value: "East Zone" },
        { label: "West Zone", value: "West Zone" },
      ],
    },
  ]

  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const [Addproduct, setAddProduct] = useState(false)

  const [city_id, setCityId] = useState("")
  const [state_id, setStateId] = useState("")
  const [product_type, setProductType] = useState("")
  const [brand_id, setBrandId] = useState("")
  const [zone_id, setZone] = useState("")
  const [business_type, setBusinessType] = useState("")
  const [surgery_date, setSurgeryDate] = useState("")
  const [doctor, setDoctor] = useState("")
  const [csr_asc, setCsrAsc] = useState("")
  const [patient, setPatient] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [optionZone, setOptionZone] = useState([])
  const [optionState, setOptionState] = useState([])
  const [optionCity, setOptionCity] = useState([])
  const [optionBusiness, setOptionBusiness] = useState([])
  const [optionChannel, setOptionChannel] = useState([])
  const [optionDoctor, setOptionDoctor] = useState([])
  const [optionComponent, setOptionComponent] = useState([])
  const [optionSize, setOptionSize] = useState([])
  const [optionHospital, setOptionHospital] = useState([])
  const [optionType, setOptionType] = useState([])
  const [optionBrand, setOptionBrand] = useState([])
  const [partner, setChannelPartner] = useState(0)
  const [hospital, setHospital] = useState("")
  let zone_name = location.state.zone
  let zone_ids = location.state.zone_ids
  let state_Name = location.state.state
  let state_ids = location.state.state_ids
  let city_name = location.state.city
  let city_ids = location.state.city_ids
  let btype = location.state.type
  let partner_name = location.state.partner
  let surgery = location.state.surgery
  let hospital_name = location.state.hospital
  let ptype = location.state.ptype
  let csr_name = location.state.csr_name

  const selectInputRef = useRef()
  const selectInputRef1 = useRef()
  const selectInputRef2 = useRef()
  const selectInputRef3 = useRef()
  const selectInputRef4 = useRef()
  const selectInputRef5 = useRef()
  const selectInputRef6 = useRef()
  const selectInputRef7 = useRef()

  useEffect(() => {
    const fetch = async () => {
      const url1 = ""
      try {
        const response1 = axios({
          method: "get",
          url: "https://api.orthotrack.in/api/zones",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const options = response.data.data.map(item => ({
            value: item.id,
            label: item.zone_name,
          }))
          setOptionZone(options)
        })
      } catch (err) {
        console.error(err)
      }
    }
    fetch()
  }, [])

  useEffect(() => {
    fetch("https://api.orthotrack.in/api/businessType")
      .then(res => res.json())
      .then(data => {
        const options = data.data.map(item => ({
          value: item.id,
          label: item.bussiness_type,
        }))
        setOptionBusiness(options)
      })
      .catch(err => {
        console.log(err.message)
      })
  }, [])

  useEffect(() => {
    fetch("https://api.orthotrack.in/api/productType")
      .then(res => res.json())
      .then(data => {
        const options = data.data.map(item => ({
          value: item.id,
          label: item.product_type,
        }))
        setOptionType(options)
      })
      .catch(err => {
        console.log(err.message)
      })
  }, [])

  const onChangeState = async e => {
    selectInputRef.current.select.clearValue()
    setZone(e.value)
    setLoading(true)
    const formData = new FormData()
    formData.append("zone_id", e.value)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/zone_state",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.state_name,
        }))
        setOptionState(options)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }
  const onChangeCity = async e => {
    selectInputRef1.current.select.clearValue()
    if (e != null) {
      setStateId(e.value)
      setLoading(true)
      const formData = new FormData()
      formData.append("state", e.value)
      try {
        const response = await axios({
          method: "post",
          url: "https://api.orthotrack.in/api/state_city_surgeon",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const options = response.data.data.city.map(item => ({
            value: item.id,
            label: item.district_name,
          }))
          const options3 = response.data.data.doctor.map(item => ({
            value: item.id,
            label: item.doctor_name,
          }))
          const options2 = response.data.data.channel_partner.map(item => ({
            value: item.id,
            label: item.channel_name,
          }))
          setOptionChannel(options2)
          setOptionDoctor(options3)
          setOptionCity(options)
          setLoading(false)
        })
      } catch (err) {
        swal({
          icon: "error",
          text: "SomeThing Went Wrong",
        })
        setLoading(false)
      }
    }
  }
  const onChangeBusiness = async e => {
    selectInputRef2.current.select.clearValue()
    selectInputRef3.current.select.clearValue()
    selectInputRef4.current.select.clearValue()
    if (e != null) {
      setBusinessType(e.value)
    }
    setLoading(true)
    const formData = new FormData()
    formData.append("bussiness_type", e.value)
    formData.append("state", state_id)
    formData.append("city", city_id)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/state_city_type",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options3 = response.data.data.hospital.map(item => ({
          value: item.id,
          label: item.hospital_name,
        }))
        const options2 = response.data.data.channel_partner.map(item => ({
          value: item.id,
          label: item.channel_name,
        }))
        setOptionChannel(options2)
        setOptionHospital(options3)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }
  const [product_detail, setProductDetail] = useState([])
  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      try {
        const { data } = await axios.get(
          `https://api.orthotrack.in/api/surgery/${params.id}`
        )
        setLoading(false)
        const allData = data.data.surgery_details.map(item => {
          setSurgeryDate(item.surgery_date)
          setPatient(item.patient)
          setCsrAsc(item.csr_asc)
          setZone(item.zone)
          setStateId(item.state)
          setCityId(item.city)
          setBusinessType(item.bussiness_type)
          setHospital(item.hospital)
          setProductType(item.product_type)
          setChannelPartner(item.partner)
          setDoctor(item.doctor)
        })
        setProductDetail(data.data.product_details)
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    }
    fetch()
  }, [])

  useEffect(() => {
    const formData = new FormData()
    formData.append("product_type", location.state.ptypeId)
    try {
      const response = axios({
        method: "post",
        url: "https://api.orthotrack.in/api/type_brand",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.brand_name,
        }))
        setOptionBrand(options)
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  const [zoneValues, setZoneValue] = useState(true)
  const [brands, setBrands] = useState("")
  const [components, setComponents] = useState("")
  const [component_name, setComponentName] = useState("")
  const [sizes, setSizes] = useState("")
  const [size_name, setSizeName] = useState("")
  const [product_id, setProductId] = useState("")
  const [error, setError] = useState(null)

  const onEditSurgery = async e => {
    if (
      surgery_date.length != 0 &&
      zone_id.length != 0 &&
      state_id.length != 0 &&
      city_id.length != 0 &&
      business_type.length != 0 &&
      partner.length != 0 &&
      doctor.length != 0 &&
      hospital.length != 0 &&
      csr_asc.length != 0 &&
      product_type.length != 0
    ) {
      e.preventDefault()
      swal({
        title: "Are you sure?",
        text: "You want to Edit this Surgery !",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async willDelete => {
        if (willDelete) {
          setLoading(true)
          const formData = new FormData()
          formData.append("surgery_id", params.id)
          formData.append("user_id", localStorage.getItem("authid"))
          formData.append("role_id", localStorage.getItem("authrole_id"))
          formData.append("surgery_date", surgery_date)
          formData.append("zone", zone_id)
          formData.append("state", state_id)
          formData.append("city", city_id)
          formData.append("bussiness_type", business_type)
          formData.append("partner", partner)
          formData.append("doctor", doctor)
          formData.append("hospital", hospital)
          formData.append("csr_asc", csr_asc)
          formData.append("product_type", product_type)
          formData.append("patient", patient)
          try {
            const response = await axios({
              method: "post",
              url: "https://api.orthotrack.in/api/updateSurgery",
              data: formData,
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }).then(response => {
              setLoading(false)
              swal({
                icon: "success",
                text: "Surgery Track Edited Successfully",
              }).then(data => {
                navigate("/surgery-track")
              })
            })
          } catch (err) {
            swal({
              icon: "error",
              text: "SomeThing Went Wrong",
            })
            setLoading(false)
          }
        }
      })
    } else {
      e.preventDefault()
      setError(true)
    }
  }

  const onChangeBrand = async e => {
    selectInputRef6.current.select.clearValue()
    selectInputRef7.current.select.clearValue()
    if (e != null) {
      setBrandId(e.value)
    }
    setLoading(true)
    const formData = new FormData()
    formData.append("product_type", product_type)
    formData.append("brand_id", e.value)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/type_brand_component",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.component_name,
          name: "component",
        }))
        setOptionComponent(options)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const onChangeComponent = async e => {
    selectInputRef7.current.select.clearValue()
    setLoading(true)
    if (e != null) {
      setComponents(e.value)
    }
    const formData = new FormData()
    formData.append("product_type", product_type)
    formData.append("brand_id", brand_id)
    formData.append("component_id", e.value)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/type_brand_component_size",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data.map(item => ({
          value: item.id,
          label: item.size,
          name: "size",
        }))
        setOptionSize(options)
        setLoading(false)
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const tableview = product_detail.map((item, index) => {
    return (
      <tr key={index}>
        <td style={{ textAlign: "center" }}>{params.id}</td>
        <td>{item.brand_name}</td>
        <td>{item.component_name}</td>
        <td>{item.size_name}</td>
        <td>
          <div className="row ">
            <div className="" style={{ width: "26%" }}>
              <a
                className="btn btn-outline-secondary btn-sm edit"
                title="Edit"
                onClick={e => {
                  setmodal_center(true)
                  setBrands(item.brand_name)
                  setComponentName(item.component_name)
                  setSizeName(item.size_name)
                  setProductId(item.id)
                }}
              >
                <i className="fas fa-pencil-alt"></i>
              </a>
            </div>
            <div className="col-2">
              <a
                className="btn btn-danger btn-sm edit"
                onClick={async e => {
                  swal({
                    title: "Are you sure?",
                    text: "You want to Delete this Product !",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then(async willDelete => {
                    if (willDelete) {
                      e.preventDefault()
                      setLoading(true)
                      const formData = new FormData()
                      formData.append("product_id", item.id)
                      formData.append("surgery_id", params.id)
                      try {
                        const response = await axios({
                          method: "post",
                          url: "https://api.orthotrack.in/api/destroySurgery",
                          data: formData,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        }).then(response => {
                          swal({
                            icon: "success",
                            text: "Product Deleted Successfully",
                          }).then(data => {
                            setmodal_center(false)
                            setProductDetail(response.data.data.product_details)
                          })
                          setLoading(false)
                        })
                      } catch (err) {
                        swal({
                          icon: "error",
                          text: "SomeThing Went Wrong",
                        })
                        setLoading(false)
                      }
                    }
                  })
                }}
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          </div>
        </td>
      </tr>
    )
  })

  const onEditProductDetail = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("surgery_id", params.id)
    formData.append("product_type", product_type)
    formData.append("brand", brand_id)
    formData.append("component", components)
    formData.append("size", sizes)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/editSurgery",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Surgery Track Edited Successfully",
        }).then(data => {
          setProductDetail(response.data.data.product_details)
          setmodal_center(false)
        })
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  let MainTableView
  if (product_detail.length != 0) {
    MainTableView = tableview
  } else {
    MainTableView = (
      <td colSpan="6" className="text-center pt-20">
        No Product Detail is Added
      </td>
    )
  }

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1

  const formattedMonth = month < 10 ? "0" + month : month
  const minDate = `${year}-${formattedMonth}-01`
  const maxDate = `${year}-${formattedMonth}-31`
  const today = new Date()
  function subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months)
    return date
  }
  let Mindates
  if (today.getDate() <= 5) {
    let minDayess = new Date(minDate)
    Mindates = subtractMonths(minDayess, 1)
  } else {
    Mindates = minDate
  }
  const SurgeryMin = moment(new Date(Mindates)).format("yyyy-MM-DD")

  const onAddProduct = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("surgery_id", params.id)
    formData.append("product_type", product_type)
    formData.append("brand", brand_id)
    formData.append("component", components)
    formData.append("size", sizes)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.orthotrack.in/api/addSurgeryData",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: " Product Added Successfully",
        }).then(data => {
          setProductDetail(response.data.data)
          setAddProduct(false)
        })
      })
    } catch (err) {
      swal({
        icon: "error",
        text: "SomeThing Went Wrong",
      })
      setLoading(false)
    }
  }

  const [csr_option, setCsrOption] = useState([])

  useEffect(() => {
    const fetch = async e => {
      const formData = new FormData()
      formData.append("state", state_ids)
      try {
        const response = await axios({
          method: "post",
          url: "https://api.orthotrack.in/api/state_city_surgeon",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const options = response.data.data.city.map(item => ({
            value: item.id,
            label: item.district_name,
          }))
          const options3 = response.data.data.doctor.map(item => ({
            value: item.id,
            label: item.doctor_name,
          }))
          // const options4 = response.data.data.users.map(item => ({
          //   value: item.id,
          //   label: item.name,
          // }))
          // setCsrOption(options4)
          setOptionDoctor(options3)
          setOptionCity(options)
          setLoading(false)
        })

        // ===============================================
        // getting CSR field options
        const formData2 = new FormData()
        formData2.append("id", localStorage.getItem("authid"))
        formData2.append("record", localStorage.getItem("record"))

        const res = await axios.post("https://api.orthotrack.in/api/csr_data", formData2, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          }
        })

        const options2 = res.data.data.map(items => ({
          value: items.id,
          label: items.name,
        }))
        setCsrOption(options2)

      } catch (err) {
        swal({
          icon: "error",
          text: "SomeThing Went Wrong",
        })
        setLoading(false)
      }
    }

    const fetch2 = async e => {
      const formData = new FormData()
      // formData.append("bussiness_type", e.value)
      formData.append("state", state_ids)
      formData.append("city", city_ids)
      try {
        const response = await axios({
          method: "post",
          url: "https://api.orthotrack.in/api/state_city_type",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const options5 = response.data.data.hospital.map(item => ({
            value: item.id,
            label: item.hospital_name,
          }))
          setOptionHospital(options5)
          setLoading(false)
        })
      } catch (err) {
        swal({
          icon: "error",
          text: "SomeThing Went Wrong",
        })
        setLoading(false)
      }
    }

    const fetch3 = async e => {
      const formData = new FormData()
      formData.append("zone_id", zone_ids)
      try {
        const response = await axios({
          method: "post",
          url: "https://api.orthotrack.in/api/zone_state",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          const options = response.data.data.map(item => ({
            value: item.id,
            label: item.state_name,
          }))
          setOptionState(options)
          setLoading(false)
        })
      } catch (err) {
        swal({
          icon: "error",
          text: "SomeThing Went Wrong",
        })
        setLoading(false)
      }
    }

    fetch()
    fetch2()
    fetch3()
  }, [])

  let surgeryMinDate
  let surgeryMaxDate

  // if (localStorage.getItem("authrole_id") != 1) {
  //   surgeryMinDate = SurgeryMin
  //   surgeryMaxDate = maxDate
  // } else {
  //   surgeryMinDate = ""
  //   surgeryMaxDate = ""
  // }

  if (localStorage.getItem("authrole_id") != 1) {
    // surgeryMinDate = SurgeryMin
    surgeryMinDate = "2024-02-01"
    surgeryMaxDate = maxDate
  } else {
    surgeryMinDate = ""
    surgeryMaxDate = ""
  }

  return (
    <div>
      <React.Fragment>
        {isLoading && <Loader />}
        <div className="page-content">
          <Container fluid className="custom-container">
            <Breadcrumb
              title={"Dashboard"}
              breadcrumbItem={"Edit Surgery Track Form"}
            />
            <div>
              <div>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Edit Surgery Details
                        </CardTitle>

                        <form onSubmit={onEditSurgery}>
                          <Row>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputCity">
                                  Surgery date{" "}
                                  <span className="text-danger"> *</span>
                                </Label>
                                <input
                                  type="date"
                                  min={surgeryMinDate}
                                  max={surgeryMaxDate}
                                  defaultValue={surgery_date}
                                  onChange={e => setSurgeryDate(e.target.value)}
                                  className="form-control"
                                  placeholder="Enter Your Living City"
                                  required
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputState">
                                  Zone <span className="text-danger"> *</span>
                                </Label>

                                <Select
                                  options={optionZone}
                                  defaultValue={{ label: zone_name }}
                                  onChange={onChangeState}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  States <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  defaultValue={{ label: state_Name }}
                                  options={optionState}
                                  className="select2-selection"
                                  onChange={onChangeCity}
                                  ref={selectInputRef}
                                />
                                {/* )} */}
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  City <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  defaultValue={{ label: city_name }}
                                  options={optionCity}
                                  ref={selectInputRef1}
                                  onChange={e => {
                                    selectInputRef2.current.select.clearValue()
                                    selectInputRef3.current.select.clearValue()
                                    selectInputRef4.current.select.clearValue()
                                    if (e != null) {
                                      setCityId(e.value)
                                    }
                                  }}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Bussiness Type{" "}
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  defaultValue={{ label: btype }}
                                  options={optionBusiness}
                                  onChange={onChangeBusiness}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Channel Partner
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  defaultValue={{ label: partner_name }}
                                  options={optionChannel}
                                  ref={selectInputRef2}
                                  onChange={e => {
                                    if (e != null) {
                                      setChannelPartner(e.value)
                                    }
                                  }}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Surgeon Name{" "}
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  defaultValue={{ label: surgery }}
                                  options={optionDoctor}
                                  ref={selectInputRef3}
                                  onChange={e => {
                                    if (e != null) {
                                      setDoctor(e.value)
                                    }
                                  }}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Hospital Name{" "}
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  defaultValue={{ label: hospital_name }}
                                  options={optionHospital}
                                  onChange={e => {
                                    if (e != null) {
                                      setHospital(e.value)
                                    }
                                  }}
                                  ref={selectInputRef4}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  Patient Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-InputCity"
                                  placeholder="Enter  Patient Name"
                                  defaultValue={patient}
                                  onChange={e => setPatient(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">
                                  CSR/ASC/TL
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Select
                                  options={csr_option}
                                  defaultValue={{ label: csr_name }}
                                  onChange={e => setCsrAsc(e)}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col lg={4} className="mb-3 ">
                              <label htmlFor="name">
                                Product Type{" "}
                                <span className="text-danger"> *</span>
                              </label>
                              <Select
                                defaultValue={{ label: ptype }}
                                options={optionType}
                                onChange={e => setProductType(e.value)}
                                className="select2-selection"
                              />
                            </Col>
                            <div className="mt-25 mb-25">
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                              >
                                Submit
                              </button>
                            </div>
                            <div className="pt-15">
                              <CardTitle className="mb-4">
                                <div className="d-flex justify-space-between ">
                                  <div>Edit Product Details</div>
                                  <div>
                                    <a
                                      onClick={() => setAddProduct(true)}
                                      className="btn btn-success btn-theme-color mt-3 mt-lg-0"
                                    >
                                      Add Product
                                    </a>
                                  </div>
                                </div>
                              </CardTitle>
                            </div>
                            <div>
                              <Table className="table-striped responsive-main-table">
                                <thead className="">
                                  <tr>
                                    <th>Surgery Id</th>
                                    <th>Product Name</th>
                                    <th>Component</th>
                                    <th>Size</th>
                                    <th className="action-width ">Action</th>
                                  </tr>
                                </thead>
                                <tbody>{MainTableView}</tbody>
                              </Table>
                            </div>
                          </Row>
                        </form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
        <Modal isOpen={modal_center} size="sm" centered>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Edit Product Detail</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={onEditProductDetail}>
              <div className="mb-3 ">
                <Label htmlFor="formrow-firstname-Input">Product Name</Label>
                <Select
                  options={optionBrand}
                  onChange={onChangeBrand}
                  defaultValue={{ label: brands }}
                  className="select2-selection"
                />
              </div>
              <div className="mb-3 ">
                <Label htmlFor="formrow-firstname-Input">Component Name</Label>
                <Select
                  onChange={onChangeComponent}
                  options={optionComponent}
                  defaultValue={{ label: component_name }}
                  ref={selectInputRef6}
                  className="select2-selection"
                />
              </div>
              <div className="mb-3 ">
                <Label htmlFor="formrow-firstname-Input">Size</Label>
                <Select
                  onChange={e => {
                    if (e != null) {
                      setSizes(e.value)
                    }
                  }}
                  defaultValue={{ label: size_name }}
                  options={optionSize}
                  ref={selectInputRef7}
                  className="select2-selection"
                />
              </div>
              <div>
                <Button className="btn btn-primary">Submit</Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          isOpen={Addproduct}
          size="sm"
          className="bd-add-product"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Add Product Detail</h5>
            <button
              type="button"
              onClick={() => {
                setAddProduct(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col lg={6} className="mb-3 ">
                <label htmlFor="name">Surgery Id </label>
                <Input className="form-control" value={params.id} disabled />
              </Col>
              <Col lg={6} className="mb-3 ">
                <label htmlFor="name">Product Type </label>
                <Input className="form-control" value={ptype} disabled />
              </Col>
            </Row>
            <Form onSubmit={onAddProduct}>
              <div className="mb-3 ">
                <Label htmlFor="formrow-firstname-Input">Product Name</Label>
                <Select
                  options={optionBrand}
                  onChange={onChangeBrand}
                  placeholder="Select Product"
                  className="select2-selection"
                />
              </div>
              <div className="mb-3 ">
                <Label htmlFor="formrow-firstname-Input">Component Name</Label>
                <Select
                  onChange={onChangeComponent}
                  options={optionComponent}
                  placeholder="Select Component"
                  ref={selectInputRef6}
                  className="select2-selection"
                />
              </div>
              <div className="mb-3 ">
                <Label htmlFor="formrow-firstname-Input">Size</Label>
                <Select
                  onChange={e => {
                    if (e != null) {
                      setSizes(e.value)
                    }
                  }}
                  placeholder="Select Size"
                  options={optionSize}
                  ref={selectInputRef7}
                  className="select2-selection"
                />
              </div>
              <div>
                <Button className="btn btn-primary">Submit</Button>
              </div>
            </Form>
          </div>
        </Modal>
      </React.Fragment>
    </div>
  )
}

export default EditSurgeyFrom
